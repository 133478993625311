import React from 'react';
import isURL from 'validator/lib/isURL';

import classy from '@core/utils/classy';

import Avatar from '@ui/Avatar';

import classes from './style.module.scss';

interface ProfileProps {
  avatar?: string;
  children?: React.ReactNode;
  className?: string;
  subtitle?: React.ReactElement | string;
  textClassName?: string;
  title: string;
}

const Profile = ({ avatar, children, className, textClassName, subtitle, title }: ProfileProps) => {
  return (
    <div className={classy(classes.Profile, className)}>
      {avatar ? (
        isURL(avatar, { disallow_auth: true }) ? (
          <Avatar circular className={classes['Profile-avatar']} imgSrc={avatar} size="sm" />
        ) : (
          <Avatar circular className={classes['Profile-avatar']} size="sm">
            {avatar}
          </Avatar>
        )
      ) : null}
      <div className={classy('Profile-content', textClassName)}>
        <div className={classes['Profile-title']}>
          {title}
          {!!children && <div className={classes['Profile-title-supplement']}>{children}</div>}
        </div>
        {!!subtitle && <div className={classes['Profile-small']}>{subtitle}</div>}
      </div>
    </div>
  );
};

export default Profile;
