import type { SuperHubHashRouteParams } from '../types';

import React, { useEffect, useState } from 'react';
import { matchPath, Route, Switch, useLocation } from 'react-router-dom';

import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import MyDevelopers from '@routes/MyDevelopers';
import BottomBar from '@routes/MyDevelopers/BottomBar';
import TopBar from '@routes/MyDevelopers/TopBar';
import BasePanel from '@routes/SuperHub/Layout/BasePanel';
import { SuperHubHashRoutePaths } from '@routes/SuperHub/types';

import styles from './index.module.scss';
import SetupApiLogs from './Setup/APILogs';

const PANEL_MIN_COLLAPSE_HEIGHT = 450;

/**
 * Displays My Developers panel in the SuperHub layout
 */
function MyDevelopersPanel() {
  const bem = useClassy(styles, 'MyDevelopers');
  const [isCollapseBtnDisabled, setIsCollapseBtnDisabled] = useState(false);
  const [isMyDevPanelExpanded, updateIsMyDevPanelExpanded] = useSuperHubStore(s => [
    s.isMyDevPanelExpanded,
    s.updateIsMyDevPanelExpanded,
  ]);

  const { pathname } = useLocation();
  const match = matchPath<SuperHubHashRouteParams>(pathname, Object.values(SuperHubHashRoutePaths));
  const type = match?.params.type;
  const isSetupRoute = type?.includes(MyDevelopersSubrouteType.Setup);

  // On viewport resizing, collapse the panel when viewport height is <= 450px
  // Note: we do this w/ JS so the state is updated and kept-in-sync in the store
  useEffect(() => {
    function handleResize() {
      const isSmallViewport = window.innerHeight <= PANEL_MIN_COLLAPSE_HEIGHT;

      if (!isMyDevPanelExpanded && isSmallViewport) {
        updateIsMyDevPanelExpanded();
      }

      setIsCollapseBtnDisabled(isSmallViewport);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [isMyDevPanelExpanded, updateIsMyDevPanelExpanded]);

  // Show bottom bar for all non-setup routes
  const showBottomBar = !isSetupRoute;

  return (
    <BasePanel
      className={bem(
        '&',
        '-layout',
        isMyDevPanelExpanded && '-layout_expanded',
        showBottomBar && '-layout_with-bottom-bar',
        isSetupRoute && '-layout_setup',
      )}
      data-testid="my-developers-panel"
      label="My Developers"
      noJS={false}
      rootClassName={bem('-panel-root')}
    >
      <div className={bem('-container')}>
        <Switch>
          <Route path="/:action(my-developers)/:type(setup)">
            <SetupApiLogs />
          </Route>
          <Route path="/:action(my-developers)/:type(demo|key|segment|user)?/:identifier?">
            <TopBar disableCollapseButton={isCollapseBtnDisabled} />
            <MyDevelopers isHub />
            <BottomBar />
          </Route>
        </Switch>
      </div>
    </BasePanel>
  );
}

export default MyDevelopersPanel;
