import React, { useEffect, useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';

import { SidebarNavContextProvider, useSidebarNavContext, useSidebarNavGroupPositionContext } from './Context';
import styles from './index.module.scss';

export interface SidebarNavProps {
  /**
   * Controls which nav group is currently active and navigated to. Used to
   * initialize the nav to this group and navigate groups dynamically if needed
   * making the `SidebarNav` both an uncontrolled and controlled component.
   */
  activeGroup?: string;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

function SidebarNavContent({ children, className, style }: SidebarNavProps) {
  const bem = useClassy(styles, 'SidebarNav');
  const { activeGroup } = useSidebarNavContext();
  const groupPositionMap = useSidebarNavGroupPositionContext();

  const contentRef = useRef<HTMLElement>(null);
  const contentRect = useRef<DOMRect>();

  useEffect(() => {
    contentRect.current = contentRef.current?.getBoundingClientRect();
  }, []);

  /**
   * Responsible for translating our content pane when an active group is set.
   */
  const contentStyle = useMemo(() => {
    if (!contentRect.current || !activeGroup) return undefined;

    const activeGroupPosition = groupPositionMap.get(activeGroup);
    if (!activeGroupPosition) return undefined;

    // To navigate to the panel where our active group exists, we must find how
    // many number of "spaces" away it is where a single "space" is defined by
    // the total width of this content container. So, find how far away the
    // active group is in pixels, divide by our content width and round up/down
    // to nearest integer. This gives us the space index, which we can then
    // easily translate to via percentage.
    const activeLevel = Math.round((activeGroupPosition.x - contentRect.current.x) / contentRect.current.width) || 0;

    return {
      translate: `-${activeLevel * 100}%`,
    };
  }, [activeGroup, groupPositionMap]);

  return (
    <nav className={bem('&', className)} style={style}>
      <div className={bem('-content-wrapper')} style={contentStyle}>
        <Flex
          ref={contentRef}
          align="stretch"
          className={bem('-content')}
          data-testid="SidebarNav-content"
          gap="sm"
          hidden={!!activeGroup}
          justify="start"
          layout="col"
        >
          {children}
        </Flex>
      </div>
    </nav>
  );
}

/**
 * Renders the top-level navigation container that contains nav sections, groups
 * and links. Groups create sub-levels of navigation panels that can contain their own sections and links.
 *
 * Use the `activeGroup` prop to start out navigating to this group instead of
 * the top-level group.
 */
export function SidebarNav({ activeGroup, ...props }: SidebarNavProps) {
  return (
    <SidebarNavContextProvider activeGroup={activeGroup}>
      <SidebarNavContent {...props} />
    </SidebarNavContextProvider>
  );
}

export * from './Section';
export * from './Group';
export * from './Link';
