import type { ColorModePreviewProps } from './ColorModePreviewProps';

import React from 'react';

import ColorModeHeader from './ColorModeHeader.svg';
import styles from './index.module.scss';

const ColorModeLayout = ({ children, isLineHeader = false, style, theme = 'light' }: ColorModePreviewProps) => (
  <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#color-mode-layout-a)">
      <path d="M0 0h92v62H0z" fill="url(#color-mode-layout-b)" />
      <g clipPath="url(#color-mode-layout-c)">
        <rect fill="#fff" height="61" rx="4" width="84" x="10" y="15" />
        <ColorModeHeader isLineHeader={isLineHeader} theme={theme} />
        {children}
      </g>
    </g>
    <defs>
      <clipPath id="color-mode-layout-a">
        <path d="M0 0h92v62H0z" fill="#fff" />
      </clipPath>
      <clipPath id="color-mode-layout-c">
        <rect fill="#fff" height="61" rx="4" width="84" x="10" y="15" />
      </clipPath>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="color-mode-layout-b"
        x1="75.414"
        x2="95.644"
        y1="14.208"
        y2="14.293"
      >
        <stop stopColor="#AE9ED4" />
        <stop offset="1" stopColor="#8AB0FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default ColorModeLayout;
