import type { StatusCodes } from 'http-status-codes';

import React, { useCallback, useEffect, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';
import classy from '@core/utils/classy';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Menu, { MenuItem, MenuDivider } from '@ui/Menu';
import { statusCategories } from '@ui/Metrics/Graph';

import styles from './style.module.scss';

const showAllFilter = {
  label: 'Show All',
  values: null,
};

const filterItems = [
  ...Object.keys(statusCategories).map(key => {
    return {
      label: key,
      values: statusCategories[key],
    };
  }),
  showAllFilter,
];

interface ActiveFilter {
  label: string;
  values: StatusCodes[] | null;
}

interface Props {
  /** Whether filter is being used in Superhub (so dropdown can have dark-on-black styling) */
  isSuperhub?: boolean;
  onChange: () => void;
}

const FilterStatus = ({ isSuperhub, onChange }: Props) => {
  const bem = useClassy(styles, 'MetricsFilter-Status');

  const [activeFilter, setActiveFilter] = useState<ActiveFilter>(showAllFilter);
  const [updateQuery, query] = useMetricsStore(s => [s.updateQuery, s.query]);

  useEffect(() => {
    const status = query.status;
    const statusQuery = (Array.isArray(status) ? status : [status]) as unknown as StatusCodes[];

    let filter;

    if (statusQuery.length) {
      // Find the category whose values contain all of the current status query params
      filter = filterItems.find(cat => cat.values?.some(value => statusQuery.includes(value)));
    } else {
      // Find the category with null values aka 'Show All'
      filter = showAllFilter;
    }

    setActiveFilter(filter || showAllFilter);
  }, [query]);

  const handleChange = useCallback(
    filter => {
      updateQuery('query', {
        status: filter.values,
      });
      onChange();
    },
    [onChange, updateQuery],
  );

  const isActive = Boolean(activeFilter.values);
  const status = activeFilter?.values ? activeFilter.values[0] : '';

  return (
    <Dropdown align="bottom" className={bem('&')} justify="start" sticky>
      <Button
        className={bem(isSuperhub && '-btn-dark-on-black')}
        dropdown
        kind={isActive ? 'primary' : 'secondary'}
        outline={!isActive}
        size="sm"
      >
        <span className="Filter-label">
          {!isActive
            ? 'Status'
            : [<HTTPStatus key="StatusFilter-activeIcon" iconOnly status={status} />, activeFilter.label]}
        </span>
      </Button>
      <Menu>
        {filterItems.map((category, i) => [
          i === filterItems.length - 1 ? <MenuDivider key={'divider'} /> : null,
          <MenuItem
            key={i + category.label}
            className={classy(activeFilter.label === category.label && 'active')}
            onClick={() => handleChange(category)}
          >
            <Flex align="center" gap="sm" justify="start">
              {!!category.values && <HTTPStatus iconOnly status={category.values[0]} />}
              <span>{category.label}</span>
            </Flex>
          </MenuItem>,
        ])}
      </Menu>
    </Dropdown>
  );
};

export default FilterStatus;
