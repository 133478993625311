import type { $TSFixMe } from '@readme/iso';

import React, { useContext } from 'react';

import Link from '@Dash/Link';

import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Box from '@ui/Box';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import { LanguageIcon } from '@ui/LanguagePicker';

import styles from './style.module.scss';

interface Props {
  showBanner: boolean;
}

const SetupBanner = ({ showBanner }: Props) => {
  const bem = useClassy(styles, 'SetupBanner');
  const { project } = useContext(ProjectContext) as { project: $TSFixMe };
  const { version } = useContext(VersionContext);
  const { subdomain } = project;

  if (!showBanner) {
    return null;
  }

  return (
    <div className={bem('&')}>
      <Box className={bem('-card')} kind="card">
        <Flex justify="start" layout="col">
          <div className={bem('-content-title')}>
            <h1 className={bem('-title')}>Get to Know Your Users</h1>
            <p className={bem('-description')}>
              See real-time insights into your API, increasing adoption by enabling you to understand your users and
              where they encounter errors.
            </p>
            <div className={bem('-grid')}>
              <Link className={bem('-grid-item-title')} to={`/project/${subdomain}/v${version}/metrics/setup-api-logs`}>
                <span className={bem('-grid-item-title-container')}>
                  <Icon className={bem('-grid-item-title-icon')} name="box" />
                  Setup API Logs
                  <span className={bem('-grid-item-title-time')}>~10 min</span>
                </span>
                <Flex align="center">
                  <div className={bem('-grid-item-title-languages')}>
                    <LanguageIcon language="python" />
                    <LanguageIcon language="php" />
                    <LanguageIcon language="node" />
                    <LanguageIcon language="ruby" />
                    <LanguageIcon language="dotnet" />
                  </div>
                  <Icon name="arrow-right" />
                </Flex>
              </Link>
            </div>
          </div>
        </Flex>
      </Box>
    </div>
  );
};

export default SetupBanner;
