import type { GitSyncProviderUnion } from '@readme/api/src/routes/gitSync/types';
import type { ProjectDocument } from '@readme/backend/models/project/types';

import { Provider } from '@readme/api/src/routes/gitSync/types';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { ProjectContext, type ProjectContextValue } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Alert from '@routes/SuperHub/Alert/Alert';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Segment, { SegmentItem } from '@ui/Segment';
import SmartLink from '@ui/SmartLink';

import { Fieldset } from '../shared';

import classes from './index.module.scss';

const InstallationRequestBanner = ({
  provider,
  installationRequest,
}: {
  installationRequest: ProjectDocument['git']['sync']['installationRequest'];
  provider: GitSyncProviderUnion;
}) => {
  const bem = useClassy(classes, 'GitConnectionSetup');

  if (!installationRequest || installationRequest.provider !== provider) {
    return null;
  }

  const subtitle = (
    <>
      A request has been submitted to the owner of your org,{' '}
      <SmartLink className={bem('-link')} href={installationRequest.accountUrl} rel="noreferrer" target="_blank">
        {installationRequest.accountLogin || installationRequest.accountUrl}
      </SmartLink>
      , for approval.
    </>
  );

  return (
    <Flex className={bem('-alert')} gap="s">
      <Alert subtitle={subtitle} title="Access Requested" type="info" />
    </Flex>
  );
};

export default function GitConnectionSetup({
  installationRequest,
}: {
  installationRequest?: ProjectDocument['git']['sync']['installationRequest'];
}) {
  const bem = useClassy(classes, 'GitConnectionSetup');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { subdomain } = project;

  // TODO https://linear.app/readme-io/issue/RM-10188/gitlab-install-endpoint
  // const gitlabParams = new URLSearchParams({
  //   client_id: 'client_id',
  //   redirect_uri: 'http://readme.local:3000/api-next/v2/git_sync/gitlab/callback',
  //   response_type: 'code',
  //   scope: 'write_repository read_api',
  //   state: project._id,
  // });

  let { path } = useRouteMatch();
  path = path.replace(/\/$/, '');

  return (
    <Box className={bem('&')} kind="rule" theme="dark">
      <Segment size="sm" underline>
        <SegmentItem to={`${path}/github`}>
          <Icon name="github-filled" size="md" />
          Github
        </SegmentItem>
        {/* <SegmentItem to={`${path}/gitlab`}>
          <Graphic name="gitlab" size="md" /> Gitlab
        </SegmentItem> */}
      </Segment>

      <Switch>
        <Route path={`${path}/github`}>
          <ol className={bem('-list')}>
            <li className={bem('-listItem')}>
              <h3 className={bem('-listItem-heading')}>Create Repository</h3>
              <div className={bem('-listItem-content')}>
                <p>
                  <SmartLink href="https://github.com/new" rel="noreferrer" target="_blank">
                    Create a new repository
                  </SmartLink>{' '}
                  for your documentation.
                </p>
              </div>
            </li>
            <li className={bem('-listItem')}>
              <h3 className={bem('-listItem-heading')}>Grant Access</h3>
              <div className={bem('-listItem-content')}>
                <p>
                  Select the organization you created your new repository in and grant ReadMe access to your new
                  repository.
                </p>
                <Button href={`/${subdomain}/api-next/v2/git_sync/github/init`} kind="secondary" size="sm">
                  <Icon name="github-filled" />
                  Sync with GitHub
                </Button>
              </div>
            </li>
          </ol>
          <InstallationRequestBanner installationRequest={installationRequest} provider={Provider.github} />
        </Route>
        <Route path={`${path}/gitlab`}>
          <ol className={bem('-list')}>
            <li className={bem('-listItem')}>
              <h3 className={bem('-listItem-heading')}>Create Repository</h3>
              <div className={bem('-listItem-content')}>
                <p>
                  <SmartLink href="https://gitlab.com/projects/new" rel="noreferrer" target="_blank">
                    Create a new repository
                  </SmartLink>{' '}
                  for your documentation.
                </p>
              </div>
            </li>
            <li className={bem('-listItem')}>
              <h3 className={bem('-listItem-heading')}>Sync with Gitlab</h3>
              <div className={bem('-listItem-content')}>
                <p>
                  Select the organization you created your new repository in and grant ReadMe access to your new
                  repository.
                </p>
                <Fieldset className={bem('-listItem-fieldset')} size="sm">
                  <FormGroup htmlFor="clientId" label="Client ID" size="sm">
                    <Input id="clientId" size="sm" />
                  </FormGroup>
                  <FormGroup htmlFor="clientSecret" label="Client Secret" size="sm">
                    <Input id="clientSecret" size="sm" />
                  </FormGroup>
                </Fieldset>
                {/* <Button
                  href={`https://gitlab.com/oauth/authorize?${gitlabParams.toString()}`}
                  kind="secondary"
                  rel="noreferrer"
                  size="sm"
                >
                  <Graphic name="gitlab" />
                  Sync with Gitlab
                </Button> */}
              </div>
            </li>
          </ol>

          <br />
        </Route>
        <Route exact path={path}>
          <Redirect to={`${path}/github`} />
        </Route>
      </Switch>
    </Box>
  );
}
