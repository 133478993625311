import type { BestPracticesContentType } from './getContent';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Box from '@ui/Box';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import { getContent } from './getContent';
import classes from './style.module.scss';

interface BestPracticesProps {
  type: BestPracticesContentType;
}

function BestPractices({ type }: BestPracticesProps) {
  const bem = useClassy(classes, 'BestPractices');
  const { tips, avoid, globalVars } = getContent(type);

  return (
    <section className={bem('&')}>
      <div>
        <h2 className={bem('-heading')}>Best Practices</h2>
        <p className={bem('-description')}>
          We&apos;re continuously improving the ReadMe experience! While we do our best to avoid breaking changes,
          markup and selectors may change from time to time.
        </p>
      </div>
      <Box className={bem('-card')} kind="card">
        {!!tips && (
          <div className={bem('-card-column')}>
            <Flex align="center" className={bem('-card-heading')} gap="sm" justify="start" tag="h3">
              <Icon color="green" name="check-circle" size={16} strokeWeight={3} />
              Tips
            </Flex>
            {tips}
          </div>
        )}
        {(!!avoid || !!globalVars) && (
          <div className={bem('-card-column')}>
            {!!avoid && (
              <>
                <Flex align="center" className={bem('-card-heading')} gap="sm" justify="start" tag="h3">
                  <Icon color="red" name="x-circle" size={16} strokeWeight={3} />
                  Avoid
                </Flex>
                {avoid}
              </>
            )}
            {!!globalVars && (
              <>
                <Flex align="center" className={bem('-card-heading')} gap="sm" justify="start" tag="h3">
                  <Icon color="blue" name="globe" size={16} strokeWeight={3} />
                  Global Variables
                </Flex>
                {globalVars}
              </>
            )}
          </div>
        )}
      </Box>
    </section>
  );
}

export default BestPractices;
