import { useContext, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import type { ProjectContextValue, VersionContextValue } from '@core/context';
import { LocalizationContext, ProjectContext, VersionContext } from '@core/context';

import { SuperHubRoutePaths, type SuperHubRouteParams } from '@routes/SuperHub/types';

/**
 * Returns the current active version and a list of all versions for the current project.
 * This is used to render the version dropdown in the SuperHub project navigation.
 */
export default function useVersions() {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version: activeVersion } = useContext(VersionContext) as VersionContextValue;
  const language = useContext(LocalizationContext);
  const { pathname } = useLocation();
  const match = matchPath<SuperHubRouteParams>(pathname, Object.values(SuperHubRoutePaths));
  const { action: actionSegment, section: sectionSegment } = match?.params ?? {};

  const versions = useMemo(() => {
    // Using the project context version list for now, eventually this data may come from APIv2.
    return project.versions.map(v => {
      const isActive = activeVersion === v.version;

      /**
       * Path construction logic here mirrors the Hub header's version dropdown
       * @see /react/src/Hub/Header/utils/hooks.js
       */
      const subdomainSegment = project.parent && project.subdomain;
      const languageSegment = language !== 'en' && `lang-${language}`;
      const versionSegment = project.stable.version !== v.version && `v${v.version}`;

      // When switching versions, we return the user to the root of the section they are in.
      // E.g. if a user is at /docs/test-page, switching to v2 will take them to /v2/docs.
      const path = `/${[subdomainSegment, languageSegment, versionSegment, actionSegment, sectionSegment]
        .filter(Boolean)
        .join('/')}`;

      return { label: v.version, isActive, path, isStable: v.is_stable, isHidden: v.is_hidden };
    });
  }, [
    actionSegment,
    activeVersion,
    language,
    project.parent,
    project.stable.version,
    project.subdomain,
    project.versions,
    sectionSegment,
  ]);

  return { activeVersion, versions };
}
