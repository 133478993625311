import React from 'react';

import { InitializeMetricsStore } from '@core/store';
import { ConnectMetricsStoreToRouter } from '@core/store/Metrics/ConnectMetricsStoreToRouter';

export interface MetricsProps {
  children: React.ReactNode;
}

/**
 * Top-level wrapper that provides all the necessary context and state for the Metrics pages and subcomponents
 * via middleware HOC components that connect to the Metrics store and router.
 */
export default function Metrics({ children }: MetricsProps) {
  return (
    <ConnectMetricsStoreToRouter>
      <InitializeMetricsStore>
        <>{children}</>
      </InitializeMetricsStore>
    </ConnectMetricsStoreToRouter>
  );
}
