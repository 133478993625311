import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useMetricsStore } from '@core/store';
import type { DevelopersTotalResponse } from '@core/types/metrics';

import Flex from '@ui/Flex';

import APIKeyInsightsTitle from './APIKeyInsightsTitle';
import styles from './style.module.scss';

const MyDevelopersTitle = () => {
  const bem = useClassy(styles, 'MyDevelopersTitle');

  const [
    hasAPIKeyInRoute,
    activeSegment,
    rangeQueryParams,
    hasActiveNonDateRangeFilters,
    minimalQueryParams,
    isReadyToFetch,
  ] = useMetricsStore(s => [
    s.myDevelopers.hasAPIKeyInRoute,
    s.myDevelopers.activeSegment,
    s.myDevelopers.getRangeQueryParams(),
    s.myDevelopers.getHasActiveNonDateRangeFilters(),
    s.myDevelopers.getMinimalQueryParams(),
    s.myDevelopers.getReadyToFetch({ waitForSegments: true }) && !s.myDevelopers.hasAPIKeyInRoute,
  ]);

  const showActiveFiltersCount = !!activeSegment || hasActiveNonDateRangeFilters;
  const isReadyToFetchActiveFiltersCount = isReadyToFetch && showActiveFiltersCount;

  // We're displaying both the active filters users count (if applicable) and the total users count
  const { data: activeFiltersTotalData } = useMetricsAPI<DevelopersTotalResponse>(
    `developers/total?${minimalQueryParams}`,
    isReadyToFetchActiveFiltersCount,
  );
  const { data } = useMetricsAPI<DevelopersTotalResponse>(`developers/total?${rangeQueryParams}`, isReadyToFetch);

  const [totalUsersCount, setTotalUsersCount] = useState<number | null>(null);
  const [activeFiltersUsersCount, setActiveFiltersUsersCount] = useState<number | null>(null);

  useEffect(() => {
    if (data) setTotalUsersCount(data?.users);
    if (activeFiltersTotalData) setActiveFiltersUsersCount(activeFiltersTotalData?.users);
  }, [data, activeFiltersTotalData]);

  const totalUsersCountText = useMemo(() => {
    if (totalUsersCount === null) return '';
    return `${totalUsersCount?.toLocaleString()} ${pluralize('API Key', totalUsersCount)}`;
  }, [totalUsersCount]);

  const activeFiltersUsersCountText = useMemo(() => {
    if (activeFiltersUsersCount === null) return '';
    return `${activeFiltersUsersCount?.toLocaleString()} in Segment`;
  }, [activeFiltersUsersCount]);

  if (hasAPIKeyInRoute) {
    return <APIKeyInsightsTitle />;
  }

  return (
    <Flex gap="0" layout="col">
      <span className={bem('-title')}>My Developers</span>
      <Flex align="center" className={bem('-user')} gap="xs" wrap="wrap">
        {!!showActiveFiltersCount && activeFiltersUsersCount !== null && <span>{activeFiltersUsersCountText} /</span>}
        {!!totalUsersCount && <span>{totalUsersCountText}</span>}
      </Flex>
    </Flex>
  );
};

export default MyDevelopersTitle;
