import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Banner from '@ui/Banner';

import classes from './style.module.scss';

interface Props {
  showBanner: boolean;
}

const DemoBanner = ({ showBanner }: Props) => {
  const bem = useClassy(classes, 'DemoBanner');

  if (!showBanner) {
    return null;
  }

  return (
    <Banner className={bem('&')} icon="icon-alert-triangle" kind="warn">
      Viewing Demo Data
    </Banner>
  );
};

export default DemoBanner;
