import React from 'react';

import { useSuperHubContext } from './Context';

interface ActionSlotProps {
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Renders the action slot section within the ProjectControls in some (but not all)
 * layouts that have a section for CTAs like Edit, Save, Reset buttons. Element
 * is stored in our context so outside components can portal render into it.
 */
export default function ActionSlot({ className, style }: ActionSlotProps) {
  const { setActionSlot } = useSuperHubContext();

  return <div ref={el => setActionSlot(el)} className={className} style={style} />;
}
