import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './index.module.scss';

interface PageContentProps {
  children?: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  isCondensed?: boolean;
}

function PageContent({ children, className, fullWidth = false, isCondensed = false }: PageContentProps) {
  const bem = useClassy(styles, 'PageContent');
  return (
    <section className={bem('&', fullWidth && '_fullWidth', isCondensed && '_condensed', className)}>
      {children}
    </section>
  );
}

export default PageContent;
