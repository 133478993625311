import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

const ChangelogLayoutCollapsed = ({ style }: SharedSvgProps) => (
  <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_665)">
      <rect fill="url(#paint0_linear_1_665)" height="62" width="92" />
      <g clipPath="url(#clip1_1_665)">
        <rect fill="white" height="61" rx="3" width="84" x="10" y="15" />
        <mask fill="white" id="path-2-inside-1_1_665">
          <path d="M10 15H94V26H10V15Z" />
        </mask>
        <path d="M10 15H94V26H10V15Z" fill="var(--Preview-primary-color)" />
        <path d="M94 25H10V27H94V25Z" fill="black" fillOpacity="0.1" mask="url(#path-2-inside-1_1_665)" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity="0.8" height="3" rx="1.5" width="10" x="15" y="19" />
        <rect fill="#ADB4C1" fillOpacity="0.5" height="2" rx="1" width="9" x="20.5" y="32" />
        <rect fill="var(--Preview-link-color)" fillOpacity="0.5" height="2" rx="1" width="20" x="31" y="32" />
        <rect fill="#ADB4C1" fillOpacity="0.5" height="2" rx="1" width="42" x="31" y="37" />
        <line stroke="black" strokeOpacity="0.07" x1="26.5" x2="77.5" y1="43.5" y2="43.5" />
        <rect fill="var(--Preview-link-color)" fillOpacity="0.5" height="2" rx="1" width="20" x="31" y="49" />
        <rect fill="#ADB4C1" fillOpacity="0.5" height="2" rx="1" width="42" x="31" y="54" />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_1_665"
        x1="75.4143"
        x2="95.6437"
        y1="14.2082"
        y2="14.293"
      >
        <stop stopColor="#AE9ED4" />
        <stop offset="1" stopColor="#8AB0FF" />
      </linearGradient>
      <clipPath id="clip0_1_665">
        <rect fill="white" height="62" width="92" />
      </clipPath>
      <clipPath id="clip1_1_665">
        <rect fill="white" height="61" rx="3" width="84" x="10" y="15" />
      </clipPath>
    </defs>
  </svg>
);

export default ChangelogLayoutCollapsed;
