import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Graphic from '@ui/Graphic';

import classes from './index.module.scss';

interface IntegrationFieldsetProps {
  align?: 'baseline' | 'start';
  children: React.ReactNode;
  legend: string;
  legendGraphic: string;
}

function IntegrationFieldset({ align = 'baseline', legend, legendGraphic, children }: IntegrationFieldsetProps) {
  const bem = useClassy(classes, 'IntegrationFieldset');
  return (
    <fieldset className={bem('&', `_${align}`)}>
      <legend className={bem('-legend')}>
        {!!legendGraphic && <Graphic className={bem('-legend-icon')} name={legendGraphic} />}
        {legend}
      </legend>
      <div className={bem('-fields')}>{children}</div>
    </fieldset>
  );
}

export default IntegrationFieldset;
