import type { ToastOptions } from 'react-hot-toast';

import React from 'react';

import Notification, { notify, ErrorNotification } from '@ui/Notification';

interface Options extends ToastOptions {
  showMsg?: boolean;
}

const handleResponse = (
  res: Response,
  toastMsg: string,
  { showMsg, ...toastOptions }: Options = { position: 'bottom-right', showMsg: false },
) => {
  if (res.status !== 200) {
    if (res.body?.constructor.name === 'ReadableStream') {
      return res.json().then(result => {
        if (result.errors?.$all) {
          notify(<ErrorNotification>{result.errors?.$all}</ErrorNotification>, toastOptions);
          throw new Error(result.errors.$all);
        }
        if (result.errors) {
          // hung requests return an empty errors object so we must provide our own error message
          const isEmptyObj = Object.keys(result.errors).length === 0 && result.errors.constructor === Object;
          const errorString = isEmptyObj ? 'Something went wrong.' : Object.values(result.errors).join(' : ');

          notify(<ErrorNotification>{errorString}</ErrorNotification>, toastOptions);
          throw new Error(errorString);
        }
        if (result.error) {
          // sometimes result.error is not informative enough for the user
          notify(<ErrorNotification>{showMsg ? result.message : result.error}</ErrorNotification>, toastOptions);
          throw new Error(result.error);
        }

        notify(<ErrorNotification>{result.errors}</ErrorNotification>, toastOptions);
        throw new Error(result);
      });
    }

    if (res.body && typeof res.body.toString === 'function') {
      throw new Error(res.body.toString());
    } else {
      throw new Error('No response body');
    }
  }

  if (toastMsg) notify(<Notification>{toastMsg}</Notification>, toastOptions);
  return res.json();
};

export default handleResponse;
