import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import classes from './index.module.scss';

interface BaseNavProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * Base SuperHub nav component used to wrap navigation elements and apply consistent
 * layout styling. Used in the `ProjectNavPanel` and `SettingsNavPanel` components.
 *
 * @example
 * ```ts
 * <BaseNav>
 *  <NavItem to="/projects" icon="projects" label="Projects" />
 *  <NavItem to="/settings" icon="settings" label="Settings" />
 *  <NavItem to="/account" icon="account" label="Account" />
 *  <Button to="/" icon="x">Back to Docs</Button>
 * </BaseNav>
 */
export default function BaseNav({ children, className }: BaseNavProps) {
  const bem = useClassy(classes, 'BaseNav');
  const layout = useSuperHubStore(s => s.layout);

  return (
    <nav className={bem('&', layout === 'aside' && '_aside', layout === 'settings-preview' && '_preview', className)}>
      {children}
    </nav>
  );
}
