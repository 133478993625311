import type { VariableDefault } from '@readme/backend/models/project/types';

import React, { useContext } from 'react';

import type { WebhooksSetupContextValue } from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhooksSetupContext';
import { WebhooksSetupContext } from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhooksSetupContext';

import VariablesTable from './VariablesTable';

interface Props {
  editable: boolean;
  isTableStriped: boolean;
  preview?: React.ReactNode;
  setHighlight?: (lineNumber?: number) => void;
  suggestions?: { name: string }[];
  type: 'custom' | 'security' | 'server';
  variables: VariableDefault[];
}

function WebhookVariablesTable({
  variables,
  editable,
  preview,
  suggestions,
  setHighlight,
  isTableStriped,
  type,
}: Props) {
  const { ranges } = useContext(WebhooksSetupContext) as WebhooksSetupContextValue;
  const rangeType = ranges?.variables[type];

  const rowHoverEnter = (e, key) => {
    const index = key.split('-')[1];
    if (setHighlight) setHighlight(rangeType[variables[index]?.name]?.line);
  };

  const rowHoverLeave = () => {
    if (setHighlight) setHighlight();
  };

  return (
    <VariablesTable
      editable={editable}
      isTableStriped={isTableStriped}
      preview={preview}
      rowHoverEnter={rowHoverEnter}
      rowHoverLeave={rowHoverLeave}
      suggestions={suggestions}
      variables={variables}
    />
  );
}

export default WebhookVariablesTable;
