import React, { useState } from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import { useProjectSettingsFormContext } from '@routes/SuperHub/Settings/Form/Project/Context';

import Button from '@ui/Button';
import Collapsible from '@ui/Collapsible';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import NavItem from '../NavItem';

import classes from './index.module.scss';

/**
 * A map of placeholder text for each section's alias input.
 */
export const placeholderMap = {
  'appearance.navigation.links.guides': 'Documentation, Tutorials, etc.',
  'appearance.navigation.links.reference': 'Reference, API Endpoints, etc.',
  'appearance.navigation.links.recipes': 'Tutorials, Samples, etc.',
  'appearance.navigation.links.changelog': 'Announcements, Blog, etc.',
  'appearance.navigation.links.discussions': 'Community, Issues, etc.',
};

interface NavSectionProps {
  name:
    | 'appearance.navigation.links.changelog'
    | 'appearance.navigation.links.discussions'
    | 'appearance.navigation.links.guides'
    | 'appearance.navigation.links.home'
    | 'appearance.navigation.links.recipes'
    | 'appearance.navigation.links.reference';
}

/**
 * Renders a toggle for enabling/disabling a Hub section in the navigation.
 * Expands to show an alias input to override the default section label.
 */
function NavSection({ name }: NavSectionProps) {
  const bem = useClassy(classes, 'NavSection');
  const uid = useUniqueId(`NavSection-${name}`);
  const { control } = useProjectSettingsFormContext();
  const [isOpen, setIsOpen] = useState(false);

  // All of the sections can be aliased except for "Home".
  const isInteractive = name !== 'appearance.navigation.links.home';

  const section = useWatch({ control, name });
  const alias = section && 'alias' in section ? section.alias : null;

  return (
    <NavItem className={bem('&')} isInteractive={isInteractive} open={isOpen}>
      {!!isInteractive && (
        <Button
          aria-label="Expand"
          className={bem('-button')}
          ghost
          kind="secondary"
          onClick={() => setIsOpen(prev => !prev)}
          size="xs"
        >
          <Icon className={bem('-icon', isOpen && '-icon_open')} name="chevron-right" />
        </Button>
      )}

      <Flex align="stretch" gap={0} layout="col">
        <RHFGroup control={control} name={`${name}.visibility`}>
          {({ field }) => (
            <Toggle
              {...field}
              checked={field.value === 'enabled'}
              className={bem('-toggle')}
              label={alias || section?.label}
              onChange={event => {
                field.onChange(event.target.checked ? 'enabled' : 'disabled');
              }}
              type="toggle"
            />
          )}
        </RHFGroup>

        {!!isInteractive && (
          <Collapsible opened={isOpen}>
            <RHFGroup
              key={uid('group')}
              className={bem('-alias')}
              control={control}
              helpMessage="Customize this section’s name across your site."
              id={uid('alias')}
              label="Display Name"
              name={`${name}.alias`}
              size="sm"
            >
              {({ field }) => (
                <Input
                  {...field}
                  placeholder={placeholderMap[name]}
                  size="sm"
                  value={typeof field.value === 'string' ? field.value : ''}
                />
              )}
            </RHFGroup>
          </Collapsible>
        )}
      </Flex>
    </NavItem>
  );
}

export default NavSection;
