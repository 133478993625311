import { FullStory, init as initFullStory } from '@fullstory/browser';
import { useContext, useEffect } from 'react';

import type { ConfigContextValue, UserContextValue } from '@core/context';
import { ConfigContext, UserContext } from '@core/context';
import { useSuperHubStore } from '@core/store';

export default function useFullStory() {
  const {
    fullstory: { enabled, orgId },
  } = useContext(ConfigContext) as ConfigContextValue;
  const isSuperHubAdmin = useSuperHubStore(s => [s.isSuperHubAdmin]);
  const { user, _id } = useContext(UserContext) as unknown as UserContextValue;
  const { is_god: isGod } = useContext(UserContext);
  // Enable fullstory only for SuperHub admins that are not god in a prod environment
  const isEnabled = isSuperHubAdmin && !isGod && enabled;

  useEffect(() => {
    if (!isEnabled) return;
    // If we are missing events, move this outside the hook so it loads faster.
    // also set startCaptureManually: true and call FullStory('start') capturing manually once we determine the user is an admin.
    initFullStory({
      orgId,
    });
    if (_id && user?.name) {
      FullStory('setIdentity', {
        uid: _id || 'unknown',
        properties: {
          displayName: user?.name || 'unknown',
          email: user?.email || 'unknown',
        },
      });
    }
  }, [_id, isEnabled, orgId, user?.email, user?.name]);

  return { FullStory };
}
