// AWS ARN format: https://docs.aws.amazon.com/IAM/latest/UserGuide/reference-arns.html
export const awsArnRegex = /^arn:aws(-[\w]+)?:[\w-]+:([\w-]*):(\d{12})?:([^:]+)(:.+)?$/;

// valid regions for AWS https://docs.aws.amazon.com/general/latest/gr/apigateway.html
export const validAwsRegions = [
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-south-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-southeast-1',
  'ap-southeast-2',
  'ca-central-1',
  'cn-north-1',
  'cn-northwest-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'eu-north-1',
  'eu-south-1',
  'me-south-1',
  'sa-east-1',
  'us-gov-east-1',
  'us-gov-west-1',
];

// This policy should give permissions to CRUD API keys and usage plans.
export const awsApiGatewayPolicyText = `{
  "Version": "2012-10-17",
  "Statement": [
      {
          "Sid": "Stmt909923626743",
          "Effect": "Allow",
          "Action": [
              "apigateway:GET",
              "apigateway:PUT",
              "apigateway:POST",
              "apigateway:DELETE"
          ],
          "Resource": [
              "arn:aws:apigateway:*::/apikeys",
              "arn:aws:apigateway:*::/apikeys/*",
              "arn:aws:apigateway:*::/usageplans/*/keys",
              "arn:aws:apigateway:*::/tags/*"
          ]
      }
  ]
}`;
