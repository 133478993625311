import type { ComponentProps } from 'react';

import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';
import { useReadmeApiWrite } from '@core/hooks/useReadmeApi';
import { getDecoratedHeaders } from '@core/utils/makeFetch';

import ErrorModal from '@routes/MyDevelopers/Setup/PersonalizedDocs/ErrorModal';
import WebhookCodeSnippet from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookCodeSnippet';
import type { WebhooksSetupContextValue } from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhooksSetupContext';
import { WebhooksSetupContext } from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhooksSetupContext';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import type Modal from '@ui/Modal';

import FormError, { FormErrorDescription, FormErrorHeading } from '../FormError';
import PanelContent from '../PanelContent';

import styles from './style.module.scss';

function getHighlight(error, ranges) {
  switch (error) {
    case 'empty-response':
      return [ranges.sections.payload.start, ranges.sections.payload.end].join('-');
    case 'insecure-endpoint':
      return [ranges.sections.verification.start, ranges.sections.verification.end].join('-');
    case 'failed-validation':
      return [ranges.sections.verification.start, ranges.sections.verification.end].join('-');
    default:
      return null;
  }
}

interface Props {
  autoFocusField?: boolean;
  initialUrl?: string;
  inputSize?: ComponentProps<typeof Input>['size'];
  isButtonOutline?: boolean;
  isQuickstart?: boolean;
  isUrlDirty?: boolean;
  onUrlChange?: (url: string) => void;
  urlPlaceholder?: string;
}

function WebhookTestForm({
  autoFocusField,
  isButtonOutline = false,
  inputSize = 'sm',
  initialUrl = '',
  isQuickstart = false,
  isUrlDirty,
  onUrlChange,
  urlPlaceholder,
}: Props) {
  const urlInputRef = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const modalRef = useRef<Modal>(null);
  const { isDev } = useEnvInfo();

  const { track } = useAmplitude();
  const bem = useClassy(styles, 'WebhookTestForm');
  const { project } = useContext(ProjectContext);
  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const { subdomain } = project;
  const [isReady, setIsReady] = useState(false);
  const [formData, setFormData] = useState({});
  const { ranges } = useContext(WebhooksSetupContext) as WebhooksSetupContextValue;
  const [url, setUrl] = useState(initialUrl);
  const [email, setEmail] = useState<string>();

  const options = useMemo(
    () => ({
      headers: getDecoratedHeaders(),
      method: 'POST',
      body: JSON.stringify(formData),
    }),
    [formData],
  );

  // Consumed in url input placeholder
  const randomSubdomain = useMemo(() => uuid().split('-')[0], []);

  const {
    data: responseData,
    error,
    isLoading,
  } = useReadmeApiWrite(`${subdomain}/api-next/v2/webhooks/validate`, options, isReady);

  useEffect(() => {
    if (isLoading === false) setIsReady(false);
  }, [isLoading]);

  useEffect(() => {
    let timeoutId;
    if (autoFocusField && urlInputRef.current) {
      // Defer until the callstack is complete before focusing to ensure input is mounted
      timeoutId = setTimeout(() => urlInputRef.current?.focus(), 0);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [autoFocusField, urlInputRef]);

  function toggleModal() {
    if (modalRef.current) modalRef.current.toggle();
  }

  const validTestUri = useMemo(() => {
    // Test Form URL should not match the following regular expression
    const localRegexp = /localhost|127.0.0.1/;
    const localUri = localRegexp.test(url);

    return isDev || !localUri;
  }, [url, isDev]);

  const { errorMessage, errorModalHeader, errorModalText } = useMemo(() => {
    if (!validTestUri)
      return {
        errorMessage: (
          <FormError>
            <FormErrorHeading>
              The URL you’ve entered is only accessible on your device. Please provide a publicly accessible URL
              instead. Try using{' '}
              <a href="https://ngrok.com/" rel="noreferrer" target="_blank">
                ngrok
              </a>{' '}
              to forward your requests.
            </FormErrorHeading>
          </FormError>
        ),
        errorModalHeader: null,
        errorModalText: null,
      };

    const errorStatus = (error && error?.status) || 0;
    if (errorStatus >= 400)
      // @ts-expect-error FIXME: Property 'type' does not exist on type '{}'
      switch (error?.info?.type) {
        case 'not-found':
          return {
            errorMessage: (
              <FormError>
                <FormErrorHeading>Endpoint not found!</FormErrorHeading>
                <FormErrorDescription>
                  Did you forget the path in your server URL (e.g., <code>/webhook</code>)?
                </FormErrorDescription>
              </FormError>
            ),
            errorModalHeader: null,
            errorModalText: null,
          };
        case 'failed-validation':
          return {
            errorMessage: (
              <Button kind="destructive" onClick={toggleModal} size="xs" text>
                Endpoint failed validation
                <Icon name="maximize-2" title="Show details" />
              </Button>
            ),
            errorModalHeader: 'Endpoint failed validation',
            errorModalText:
              'Your server returned a 401, which likely means that something went wrong while attempting to verify the signature. Ensure you are using the correct project secret and correctly calling the verify function.',
          };
        case 'empty-response':
          return {
            errorMessage: (
              <Button kind="warning" onClick={toggleModal} size="xs" text>
                Warning: No Data Received
                <Icon name="maximize-2" title="Show details" />
              </Button>
            ),
            errorModalHeader: 'No Data Received',
            errorModalText:
              'Your request was successful, but your server did not return any data. Check that you’re able to successfully fetch the data.',
          };
        case 'insecure-endpoint':
          return {
            errorMessage: (
              <Button kind="warning" onClick={toggleModal} size="xs" text>
                Warning: Unable to Verify
                <Icon name="maximize-2" title="Show details" />
              </Button>
            ),
            errorModalHeader: 'Unable to Verify Request',
            errorModalText:
              'Your server successfully returned data, but we were unable to verify the request. Check that your ReadMe secret is correct and that you’re able to verify the request.',
          };
        default:
          return {
            errorMessage: (
              <FormError>
                <FormErrorHeading>There was a problem testing your server.</FormErrorHeading>
              </FormError>
            ),
            errorModalHeader: null,
            errorModalText: null,
          };
      }

    return { errorMessage: null, errorModalHeader: null, errorModalText: null };
  }, [error, validTestUri]);

  return (
    <form
      ref={formRef}
      onSubmit={ev => {
        ev.preventDefault();
        setFormData({ url, email });
        setIsReady(validTestUri);
        // Send event to amplitude
        if (validTestUri && email)
          track(AMPLITUDE_EVENT.PD_WEBHOOK_TEST, {
            type: isQuickstart ? AMPLITUDE_EVENT_PROPERTY.QUICKSTART : AMPLITUDE_EVENT_PROPERTY.SETUP,
          });
      }}
    >
      <PanelContent>
        <div className={bem('-try-it-container')}>
          <InputGroup columnLayout="60% 40%">
            <Input
              ref={urlInputRef}
              id="url"
              name="url"
              onInput={ev => {
                const value = (ev.target as HTMLInputElement).value;
                setUrl(value);
                if (typeof onUrlChange === 'function') {
                  onUrlChange(value);
                }
              }}
              placeholder={urlPlaceholder || `https://${randomSubdomain}.ngrok.io/webhook`}
              required
              size={inputSize}
              suffix={!!initialUrl && !isUrlDirty ? <Icon name="check" /> : null}
              type="url"
              value={url}
            />
            <Input
              id="email"
              name="email"
              onInput={ev => setEmail((ev.target as HTMLInputElement).value)}
              placeholder="owlbert@readme.io"
              required
              size={inputSize}
              type="email"
              value={email}
            />
          </InputGroup>
          <Button
            disabled={!validTestUri}
            fullWidth
            kind={isHub ? 'contrast' : undefined}
            loading={isLoading}
            outline={isButtonOutline}
            size={inputSize}
            type="submit"
          >
            Try It
          </Button>
        </div>

        {!!responseData && (
          <dl>
            {Object.entries(responseData).map(([key, value], i) => (
              <React.Fragment key={`res_${i}`}>
                <dt className={bem('-definition-term')}>{key}:</dt>
                <dd>
                  {Array.isArray(value) || (typeof value === 'object' && value !== null)
                    ? JSON.stringify(value)
                    : value}
                </dd>
              </React.Fragment>
            ))}
          </dl>
        )}
        {!!errorMessage && (
          <>
            <Flex className={bem('-error-container')} justify="center">
              {errorMessage}
            </Flex>
            {!!errorModalHeader && !!errorModalText && (
              <ErrorModal
                ref={modalRef}
                errorHeader={errorModalHeader}
                errorText={errorModalText}
                rootNode={formRef.current?.getRootNode()}
                // use a superhub local DOM target vs. dash global DOM target
                target={isHub ? '#personalized-docs-modal-target' : '#modal-target'}
                toggle={toggleModal}
                webhookCodeSnippet={
                  <WebhookCodeSnippet
                    // @ts-expect-error FIXME: Property 'type' does not exist on type '{}'
                    highlight={getHighlight(error?.info?.type, ranges) || undefined}
                    isLanguagePickerMinimal={true}
                  />
                }
              />
            )}
          </>
        )}
      </PanelContent>
    </form>
  );
}

export default WebhookTestForm;
