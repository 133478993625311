import React from 'react';

import Dropdown from '@ui/Dropdown';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuItem from '@ui/Menu/Item';

import securityImg from './security.png';
import serverImg from './server.png';
import classes from './style.module.scss';

interface Props {
  definitionLink: string;
  type: 'security' | 'server';
  variableCount: number;
}

const VariableTooltip: React.FC<Props> = ({ variableCount, type, children, definitionLink }) => {
  const types = {
    server: {
      img: serverImg,
      title: 'Server Variables',
    },
    security: {
      img: securityImg,
      title: 'Security Schemes',
    },
  };
  const tooltip = (
    <Menu className={classes.VariableTooltip} style={{ position: 'absolute' }} theme="light">
      <MenuItem focusable={false} img={{ alt: 'Example OAS file with Server Variable', src: types[type].img }} />
      <MenuItem
        description={`We found ${variableCount !== 0 ? variableCount : 'no'} variable${
          variableCount !== 1 ? 's' : ''
        } in your API
          Definitions.`}
        focusable={false}
      >
        <span>{types[type].title}</span>
      </MenuItem>
      <MenuDivider />
      <MenuItem
        alignIcon="right"
        className={classes.link}
        color="blue"
        href={definitionLink}
        icon="icon-arrow-right2"
        TagName="a"
      >
        Your API Definitions
      </MenuItem>
    </Menu>
  );
  return (
    <Dropdown align="bottom" justify="center" trigger="hover">
      {children}
      {tooltip}
    </Dropdown>
  );
};

export default VariableTooltip;
