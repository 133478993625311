function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

const isEmptyObject = function (obj) {
  let name;
  // eslint-disable-next-line guard-for-in, no-unreachable-loop
  for (name in obj) {
    return false;
  }
  return true;
};

// Heavily modified!
// http://stackoverflow.com/questions/8431651/getting-a-diff-of-two-json-objects
const diff = function (obj1, obj2, notBase) {
  const result = {};
  let change;

  // eslint-disable-next-line guard-for-in
  for (const key in obj1) {
    loop(key);
  }

  for (const key in obj2) {
    // For things in obj2 but not obj1
    if (!obj1[key]) {
      loop(key);
    }
  }

  function loop(key) {
    if (key[0] === '$') return;

    if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      if (!arraysEqual(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
    } else if (isObject(obj2[key]) || isObject(obj1[key])) {
      if (!isObject(obj2[key]) || !isObject(obj1[key])) {
        // One is null or undefined
        // NOTE: If the second one is undefined (aka deleted),
        // it won't actuall override it (because it'll set the change to null,
        // which everything else thinks means it doesn't exist)...
        change = obj2[key];
      } else {
        change = diff(obj1[key], obj2[key], true);
      }

      if (isEmptyObject(change) === false) {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          // If anything in an array has changed, save the whole array
          result[key] = obj2[key];
        } else {
          result[key] = change;
        }
      }
    } else if (obj2[key] !== obj1[key]) {
      result[key] = obj2[key];
    }
  }

  if (!notBase) {
    console.log(JSON.stringify(result));
  }
  return result;
};

const arraysEqual = function (arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = arr1.length; i--; ) {
    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
      if (!arraysEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (isObject(arr1[i]) && isObject(arr2[i])) {
      if (!isEmptyObject(diff(arr1[i], arr2[i], true))) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

module.exports = diff;
