import type { MetricsDateRangeProps } from '../DateRange';
import type { MetricsSearchProps } from '../Search';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import ContentContainer from '@ui/Dash/ContentContainer';
import DashHeader from '@ui/Dash/Header';

import MetricsDateRange from '../DateRange';
import MetricsSearch from '../Search';
import SettingsDropdown from '../SettingsDropdown';

import styles from './style.module.scss';

interface Props {
  /** Optional bottom absolute positioned bar */
  bottomBar?: React.ReactNode;
  /** Class name for ContentContainer */
  containerClassName?: string;
  dateRangeOptions?: MetricsDateRangeProps;
  /** Whether to hide date range picker or not */
  hideDateRange?: boolean;
  /** Whether to hide search input or not */
  hideSearch?: boolean;
  /** Whether to hide settings dropdown or not */
  hideSettings?: boolean;
  /** Optional search options */
  searchOptions?: MetricsSearchProps;
  /** Size value for DashHeader and ContentContainer */
  size?: 'lg' | 'xl';
  /** Title for the DashHeader */
  title: React.ReactNode | string;
}

const MetricsView: React.FC<Props> = ({
  bottomBar,
  children,
  containerClassName = '',
  dateRangeOptions,
  hideDateRange = false,
  hideSearch = false,
  hideSettings = false,
  searchOptions,
  size = 'lg',
  title,
}) => {
  const bem = useClassy(styles, 'MetricsView');

  return (
    <div className={bem('&', bottomBar && '_with-bottom-bar')}>
      <DashHeader isTransparent size={size} title={title}>
        {!hideSearch && <MetricsSearch {...searchOptions} />}
        {!hideDateRange && <MetricsDateRange {...dateRangeOptions} />}
        {!hideSettings && <SettingsDropdown />}
      </DashHeader>

      <ContentContainer className={containerClassName} size={size}>
        {children}
      </ContentContainer>

      {bottomBar}
    </div>
  );
};

export default MetricsView;
