import React, { useCallback } from 'react';

import { useMetricsStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuItem, MenuDivider, MenuHeader } from '@ui/Menu';
import Toggle from '@ui/Toggle';

interface Props {
  includeUpdateSection?: boolean;
  triggerBtn?: React.ReactNode;
}

const SettingsDropdown = ({ includeUpdateSection = true, triggerBtn }: Props) => {
  const [updateDevelopmentData, updateIncludeTryItNow, developmentData, includeTryItNow] = useMetricsStore(s => [
    s.updateDevelopmentData,
    s.updateIncludeTryItNow,
    s.developmentData,
    s.includeTryItNow,
  ]);

  const handleToggleChange = useCallback(
    (option, value) => {
      // If neither toggle is enabled OR if only one toggle is enabled, allow option to be toggled
      if (!value || (!developmentData && !includeTryItNow)) {
        if (option === 'developmentData') {
          updateDevelopmentData(value);
        } else {
          updateIncludeTryItNow(value);
        }
        return;
      }

      // If toggling on, only allow one option to be toggled
      if (option === 'developmentData') {
        updateDevelopmentData(true);
        updateIncludeTryItNow(false);
      } else {
        updateDevelopmentData(false);
        updateIncludeTryItNow(true);
      }
    },
    [developmentData, includeTryItNow, updateDevelopmentData, updateIncludeTryItNow],
  );

  return (
    <Dropdown appendTo={() => document.body} sticky>
      {triggerBtn || (
        <Button kind="secondary" outline size="sm">
          <i className="icon-settings" />
        </Button>
      )}
      <Menu>
        {/* Enable Slack & Email menu items below in RM-2457 & RM-2458 */}
        {!!false && !!includeUpdateSection && (
          <>
            <MenuHeader>Get Dashboard Updates</MenuHeader>
            <MenuItem
              icon={
                <span className="icon icon-slack" style={{ opacity: 1 }}>
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              }
            >
              Via Slack
            </MenuItem>
            <MenuItem icon="icon icon-at-sign">Via Email</MenuItem>
            <MenuDivider />
          </>
        )}
        <MenuHeader>Configuration</MenuHeader>
        <MenuItem focusable={false}>
          <Flex align="center" justify="between">
            <span>Include Try It Data</span>{' '}
            <Toggle
              checked={!!includeTryItNow}
              onChange={() => {
                handleToggleChange('includeTryItNow', !includeTryItNow);
              }}
              type="toggle"
            />
          </Flex>
        </MenuItem>
        <MenuItem focusable={false}>
          <Flex align="center" justify="between">
            <span>Development Data</span>{' '}
            <Toggle
              checked={!!developmentData}
              onChange={() => {
                handleToggleChange('developmentData', !developmentData);
              }}
              type="toggle"
            />
          </Flex>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default SettingsDropdown;
