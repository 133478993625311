import type { $TSFixMe } from '@readme/iso';

import { createColumnHelper } from '@tanstack/react-table';

import { Column } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import type { APIMetricsData } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import {
  APIKeyCell,
  CompanyCell,
  MethodCell,
  StatusCell,
  TextCell,
  TimeCell,
  UserAgentCell,
} from '@ui/ReactTable/components/Cells';

import { FilterByEmailCell, UserCell } from '../Table/Cells';

const columnHelper = createColumnHelper<APIMetricsData>();

export default {
  title: 'API Calls',
  endpoint: 'requests',
  exportSource: 'request',
  fallback: 'metrics',
  filters: ['Group', 'Method', 'Status', 'User'],
  shortcuts: {
    'Calls By Status': { query: 'groupBy=status&groupBy=period', graphType: 'bar' },
    'Calls Over Time': { groupBy: 'period', graphType: 'line' },
  },

  graph: {
    type: 'line',
    query: 'groupBy=period',
    groups: ['company', 'groupEmail', 'groupId', 'useragent', 'status', 'statusCategory', 'url'],
    groupByBase: 'period',
  },
  table: {
    query: '',
    defaultSort: {
      column: Column.CreatedAt,
      direction: 'desc',
      query: '',
    },
    columns: [
      columnHelper.accessor(Column.CreatedAt, {
        enableHiding: false,
        header: columnHeaders[Column.CreatedAt],
        cell: ({ getValue }) => TimeCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Status, {
        header: columnHeaders[Column.Status],
        cell: ({ getValue }) => StatusCell({ value: getValue() }),
        maxSize: 70,
      }),
      columnHelper.accessor(Column.Method, {
        header: columnHeaders[Column.Method],
        cell: ({ getValue }) => MethodCell({ value: getValue() }),
        maxSize: 70,
      }),
      columnHelper.accessor(Column.Path, {
        enableHiding: false,
        header: columnHeaders[Column.Path],
        minSize: 200,
        cell: ({ row }) => {
          const { path, url } = row.original;
          return TextCell({ tooltipValue: url, value: path });
        },
      }),
      columnHelper.accessor(Column.GroupEmail, {
        header: columnHeaders[Column.User],
        minSize: 120,
        cell: ({ row }) => {
          const { groupEmail, groupLabel } = row.original;
          return UserCell({ groupEmail, groupLabel });
        },
      }),
      columnHelper.accessor(Column.GroupId, {
        header: columnHeaders[Column.GroupId],
        minSize: 120,
        cell: ({ getValue }) => APIKeyCell({ value: getValue<string>() }),
      }),
      columnHelper.accessor(Column.OperationId, {
        header: columnHeaders[Column.OperationId],
      }),
      columnHelper.accessor(Column.Company, {
        header: columnHeaders[Column.Company],
        minSize: 120,
        cell: ({ getValue }) => CompanyCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.UserAgent, {
        header: columnHeaders[Column.UserAgent],
        cell: ({ getValue }) => UserAgentCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Id, {
        id: 'filterByEmail',
        header: '',
        maxSize: 40,
        cell: ({ row, table }) => {
          const { groupEmail } = row.original;

          // filterByEmail is custom callback passed into table options
          const { filterByEmail } = table.options?.meta as $TSFixMe;

          if (!filterByEmail) return null;
          return FilterByEmailCell({ filterByEmail: () => filterByEmail(groupEmail) });
        },
      }),
    ],
    prefsName: TablePrefsKey.MetricsApiCalls,
    visibility: {
      [Column.OperationId]: false,
      [Column.UserAgent]: false,
    },
  },
};
