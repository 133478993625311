// We have helper functions we want to expose on the mapper that don't use this, so ignore this rule for the whole file
/* eslint-disable class-methods-use-this */

import type { CartographerConfig, CartographerConfigPlugin, MappingConfigType } from './config';
import type { Mapping, ValidAPIObjectValue } from './types';
import type { ReadonlyDeep } from 'type-fest';

import { deepFreeze } from '@readme/iso';

import { edit } from './edit';
import { read } from './read';
import { getNullNotEmptyString, getEmptyStringNotNull, nullNotEmptyNumber } from './util';

export enum MAPPER_MODES {
  CREATE = 'create',
  EDIT = 'edit',
  READ = 'read',
}

export class Mapper<
  RepresentationType extends ValidAPIObjectValue,
  Context extends Record<string, any>,
  Plugins extends CartographerConfigPlugin<Context, CartographerConfig<any>>,
> {
  readonly mode: MAPPER_MODES.CREATE | MAPPER_MODES.EDIT | MAPPER_MODES.READ;

  readonly requestBody?: ReadonlyDeep<Partial<RepresentationType>>;

  readonly context: Context;

  readonly plugins?: Plugins;

  constructor(
    mode: MAPPER_MODES.CREATE | MAPPER_MODES.EDIT | MAPPER_MODES.READ,
    requestBody?: Partial<RepresentationType>,
    context?: Context,
    plugins?: Plugins,
  ) {
    this.mode = mode;
    this.requestBody = deepFreeze(requestBody);
    this.context = context || ({} as Context);
    this.plugins = plugins;
  }

  async mapping(mapping: Mapping<RepresentationType, Context, MappingConfigType<Plugins>>) {
    switch (this.mode) {
      case MAPPER_MODES.READ:
        return read(mapping, this.context, this.plugins || {});
      case MAPPER_MODES.EDIT:
      case MAPPER_MODES.CREATE:
        // Create and edit are practically identical, but I think it will be useful for the mapping to know which mode they're in.
        // As for the as, when other packages (like dash) include this, it dies due to the readonly wrapper. This fixes that for now.
        return edit(mapping, (this.requestBody || {}) as Partial<RepresentationType>, this.context, this.plugins || {});
      default:
        throw new Error('Invalid mode');
    }
  }

  // todo: maybe have this configurable instead
  getNullNotEmptyString<T extends number | string | null>(string?: T) {
    return getNullNotEmptyString<T>(string);
  }

  getEmptyStringNotNull<T extends string | null>(string: T) {
    return getEmptyStringNotNull<T>(string);
  }

  getNullNotInvalidNumber<T extends number | string | null>(number?: T) {
    return nullNotEmptyNumber<T>(number);
  }

  /**
   * Clean up and remove a potentially object from being set to your data source.
   *
   */
  cleanUpPotentiallyEmptyObject<T extends Record<string, any>>(obj: T, key: keyof T) {
    if (typeof obj !== 'object' || Array.isArray(obj) || obj === null) {
      return;
    }

    if (!Object.keys(obj[key]).length) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  }
}
