import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import { SuperHubHashRoutePaths, type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';
import Segment, { SegmentItem } from '@ui/Segment';
import Truncate from '@ui/Truncate';

import BranchStatusIndicator from '../BranchStatusIndicator';
import useVersions from '../useVersions';

import classes from './index.module.scss';

const leafSegments: Record<
  NonNullable<Exclude<SuperHubHashRouteParams['action'], 'create' | 'my-developers'>>,
  {
    icon: string;
    label: string;
  }
> = {
  appearance: {
    icon: 'brush',
    label: 'Appearance',
  },
  configure: {
    icon: 'settings',
    label: 'Settings',
  },
  content: {
    icon: 'text',
    label: 'Content',
  },
};

/**
 * Displays a breadcrumb used to show the current location of the user within the project.
 * The first segment is the version/branch picker, and the second segment is the current settings section.
 */
export default function ProjectNavigationBreadcrumb() {
  const bem = useClassy(classes, 'ProjectNavigationBreadcrumb');
  const { activeVersion, versions } = useVersions();
  const isActiveVersionHidden = versions.find(v => v.isActive)?.isHidden;
  const { hash } = useLocation();
  const match = matchPath<SuperHubHashRouteParams>(hash.substring(1), Object.values(SuperHubHashRoutePaths));
  const hashRouteAction = match?.params.action;

  const leafSegment = hashRouteAction ? leafSegments[hashRouteAction] : null;

  // We don't want to show the version/branch menu when the user is configuring the project level settings,
  // because those will not be tied to a specific version.
  const showVersionSegment = hashRouteAction !== 'configure';

  return (
    <Segment
      circular
      className={bem(!showVersionSegment && '_leaf-segment-only')}
      gap="10px"
      ghost
      selectedIndex={leafSegment && showVersionSegment ? 1 : 0}
      size="xs"
    >
      {!!showVersionSegment && (
        <SegmentItem
          dropdownMenu={
            <Menu data-color-mode="dark" theme="dark">
              <MenuHeader>Version Branches</MenuHeader>
              {versions.map(({ label, path, isActive, isHidden, isStable }) => {
                return (
                  <MenuItem key={path} active={isActive} className={bem('&-version-menu-item')} href={path} TagName="a">
                    <Flex align="center" gap="sm">
                      <Flex align="center" gap="xs">
                        v{label}
                        {!!isStable && <span className={bem('-version-menu-item-main')}>(Stable)</span>}
                      </Flex>

                      {!isHidden && <BranchStatusIndicator />}
                    </Flex>
                  </MenuItem>
                );
              })}
              <MenuDivider />

              <MenuItem href="/dash?to=versions" TagName="a" target="_blank">
                <span>Manage Versions</span>
              </MenuItem>
            </Menu>
          }
        >
          {isActiveVersionHidden ? <Icon name="git-branch" size="sm" /> : <BranchStatusIndicator isMinimal />}
          <Truncate maxWidth="24ch">{`v${activeVersion}`}</Truncate>
        </SegmentItem>
      )}

      {!!leafSegment && (
        <SegmentItem className={bem('-leaf-segment')}>
          <Icon name={leafSegment.icon} />
          {leafSegment.label}
        </SegmentItem>
      )}
    </Segment>
  );
}
