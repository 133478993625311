import React from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import {
  ManageWebhookCustomVariables,
  ManageWebhookSecurityVariables,
  ManageWebhookServerVariables,
  ManageWebhookVariablesContainer,
} from '@routes/MyDevelopers/Setup/PersonalizedDocs/ManageWebhookVariables';
import useIsWebhookSetupComplete from '@routes/MyDevelopers/Setup/PersonalizedDocs/useIsWebhookSetupComplete';
import WebhookEnterpriseConfig from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookEnterpriseConfig';
import type { SuperHubHashRouteParams } from '@routes/SuperHub/types';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import PersonalizedDocsLayout from './Layout';
import PersonalizedDocsSetup from './Setup';
import styles from './style.module.scss';

export default function PersonalizedDocs() {
  const bem = useClassy(styles, 'PersonalizedDocs');

  const isSetupComplete = useIsWebhookSetupComplete();
  const [parent = false] = useProjectStore(s => [s.data.parent]);
  const isEnterprise = !!parent;
  const { type } = useParams<SuperHubHashRouteParams>();

  if (isEnterprise) {
    return (
      <PersonalizedDocsLayout>
        <div className={bem('-enterprise')}>
          <WebhookEnterpriseConfig parentSubdomain={parent} />
        </div>
      </PersonalizedDocsLayout>
    );
  }

  if (!isSetupComplete || type === 'setup') {
    return <PersonalizedDocsSetup />;
  }

  return (
    <PersonalizedDocsLayout>
      <Flex className={bem('-container')} layout="col">
        <ManageWebhookVariablesContainer>
          <ManageWebhookServerVariables />
          <ManageWebhookSecurityVariables />
          <ManageWebhookCustomVariables />
        </ManageWebhookVariablesContainer>

        <div className={bem('-separator')} />

        <Flex className={bem('-footer')} gap={10}>
          <Button kind="secondary" outline size="sm" to="/content/personalized-docs/setup">
            Edit Configuration <Icon name="settings" />
          </Button>
          <Button
            href="https://docs.readme.com/main/docs/personalized-docs"
            kind="secondary"
            outline
            size="sm"
            target="_blank"
          >
            Help <Icon name="help-circle" />
          </Button>
        </Flex>
      </Flex>
    </PersonalizedDocsLayout>
  );
}
