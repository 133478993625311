import type { EditNavigationRepresentationType } from '@readme/api/src/mappings/project/types';

import get from 'lodash/get';
import React, { useCallback, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import useOnClickOutside from '@core/hooks/useOnClickOutside';

import { useProjectSettingsFormContext } from '@routes/SuperHub/Settings/Form/Project/Context';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import NavListFieldGroup from '../NavListFieldGroup';

import classes from './index.module.scss';

interface NavListProps {
  /**
   * List of options to include in the dropdown menu
   * in each `NavListItem`.
   */
  itemTypeOptions?: Exclude<EditNavigationRepresentationType['type'], undefined>[];

  /**
   * Name of the field array to register with the form.
   */
  name: 'appearance.navigation.left' | 'appearance.navigation.right' | 'appearance.navigation.sub_nav';
}

/**
 * Configure a list of navigation item representations for the Project's `appearance.navigation.left`,
 * `appearance.navigation.right`, and `appearance.navigation.sub_nav`.
 */
function NavList({
  itemTypeOptions = [
    'home',
    'guides',
    'discussions',
    'changelog',
    'link_url',
    'custom_page',
    'user_controls',
    'reference',
    'recipes',
  ],
  name,
}: NavListProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const {
    control,
    formState: { errors },
  } = useProjectSettingsFormContext();

  const { fields, move, append, remove } = useFieldArray({
    control,
    name,
  });

  useOnClickOutside(ref, () => setOpenIndex(null), ref.current?.getRootNode());

  const handleMove = useCallback(
    (direction: 'down' | 'up', itemIndex: number) => {
      const delta = direction === 'up' ? -1 : 1;
      const targetIndex = itemIndex + delta;
      if (itemIndex === openIndex) {
        setOpenIndex(targetIndex);
      }

      move(itemIndex, targetIndex);
    },
    [move, openIndex],
  );

  const handleAdd = useCallback(() => {
    setOpenIndex(fields.length);
    append({ type: itemTypeOptions[0] });
  }, [append, fields.length, itemTypeOptions]);

  const handleRemove = useCallback(
    (index: number) => {
      if (openIndex === index) setOpenIndex(null);
      remove(index);
    },
    [openIndex, remove],
  );

  const handleOpen = useCallback(
    (index: number) => {
      if (openIndex !== index) setOpenIndex(index);
    },
    [openIndex],
  );

  return (
    <Flex ref={ref} align="stretch" gap={15} layout="col">
      {!!fields?.length && (
        <Flex align="stretch" className={classes.NavLinkList} gap="xs" layout="col" tag="ul">
          {fields?.map((field, index) => (
            <NavListFieldGroup
              key={field.id}
              canMoveDown={index < fields.length - 1}
              canMoveUp={index > 0}
              isOpen={openIndex === index || !!get(errors, `${name}.${index}`)}
              itemTypeOptions={itemTypeOptions}
              name={`${name}.${index}` as 'appearance.navigation.left.0'}
              onMoveDown={() => handleMove('down', index)}
              onMoveUp={() => handleMove('up', index)}
              onOpen={() => handleOpen(index)}
              onRemove={() => handleRemove(index)}
            />
          ))}
        </Flex>
      )}
      <div>
        <Button kind="secondary" onClick={handleAdd} size="sm">
          <Icon name="plus" />
          Add Item
        </Button>
      </div>
    </Flex>
  );
}

export default NavList;
