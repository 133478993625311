import React, { forwardRef, useMemo } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';

import { dashNavigate } from '@Dash/history';

import classy from '@core/utils/classy';

/**
 * An Angular UI Router-compatible version of the
 * React Router `<Link>` and `<NavLink>` elements.
 */
type DashLinkProps<T> = T & {
  children: React.ReactNode;
  className?: string;
  isNavLink?: boolean;
  onClick?: React.MouseEventHandler<T>;
  to: string;
};

const DashLink = forwardRef(function DashLink<T>(
  { children, className, to, isNavLink, ...props }: DashLinkProps<T>,
  ref: React.Ref<T>,
) {
  const history = useHistory();
  const { pathname } = history.location;
  const isActive = useMemo(() => pathname === to, [pathname, to]);

  const handleDashLinkClick = (e: React.MouseEvent<T, MouseEvent>) => {
    if (!isActive) dashNavigate(to);

    if (typeof props?.onClick === 'function') props.onClick(e);
  };

  const LinkTag = isNavLink ? NavLink : Link;

  return (
    <LinkTag
      {...props}
      ref={ref}
      className={classy(isActive && 'active', className)}
      onClick={handleDashLinkClick}
      to={to}
    >
      {children}
    </LinkTag>
  );
});

/**
 * A convenience wrapper for `<DashLink>` that treats it as a `<NavLink>`
 * https://reactrouter.com/en/main/components/nav-link
 * */
const DashNavLink = forwardRef(function DashNavLink<T>({ children, ...props }: DashLinkProps<T>, ref: React.Ref<T>) {
  return (
    <DashLink {...props} ref={ref} isNavLink>
      {children}
    </DashLink>
  );
});

export default DashLink;
export { DashLink as Link, DashNavLink as NavLink };
