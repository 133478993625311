import type { FormGateContextValue } from './FormGateContext';

import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import type { ProjectStateUpdate } from '@core/store';
import { useProjectStore } from '@core/store';

import { FormGateContextProvider } from './FormGateContext';

export type ProjectSettingsFormValues = ProjectStateUpdate & {
  // TODO: Remove once mapper defines this missing property
  custom_login: {
    PENDING_jwt_expiration: number;
  };
};

interface ProjectSettingsFormContextProps {
  children: React.ReactNode;
  formGate: FormGateContextValue;
}

export default function ProjectSettingsFormContext({ children, formGate }: ProjectSettingsFormContextProps) {
  const initialFormValues = useProjectStore(s => s.initialData);
  const formValue = useForm<ProjectSettingsFormValues>({
    defaultValues: initialFormValues,
  });

  return (
    <FormProvider {...formValue}>
      <FormGateContextProvider gate={formGate}>{children}</FormGateContextProvider>
    </FormProvider>
  );
}

/**
 * Convenience hook to get our React Hook Form Context with types that match our
 * project mapper form fields.
 * @link https://react-hook-form.com/docs/useformcontext
 */
export const useProjectSettingsFormContext = () => useFormContext<ProjectSettingsFormValues>();
export * from './FormGateContext';
