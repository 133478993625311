import type { CustomBlockMgmtItem } from '../types';

import React from 'react';

import { Link } from '@Dash/Link';

import useClassy from '@core/hooks/useClassy';
import { getElapsed } from '@core/utils/metrics';

import Flex from '@ui/Flex';
import Spinner from '@ui/Spinner';

import { markdownToPlainText } from '../../utils';

import classes from './style.module.scss';

interface SidebarNavItemProps {
  isActive?: boolean;
  isLoading?: boolean;
  item?: CustomBlockMgmtItem;
  style?: React.CSSProperties;
}

function SidebarNavItem({ isActive, isLoading, item, style }: SidebarNavItemProps) {
  const bem = useClassy(classes, 'SidebarNavItem');

  const editElapsed = item?.updated_at
    ? `Edited ${getElapsed(item.updated_at, {
        includeAgo: true,
      })}`
    : 'Creating now';

  const pageCount = item?.usage?.page_count || 0;
  const s = (count: number) => (count !== 1 ? 's' : '');
  const pageUsage = `In ${pageCount} page${s(pageCount)}`;

  return (
    <div className={bem('&', isLoading && '_loading')} style={style}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Link className={bem('-link', isActive && '-link_active')} to={item?.tag || 'new'}>
          <h2 className={bem('-title', !item?.name && '-title_placeholder')}>{item?.name || 'Name'}</h2>
          {!!item?.source && <p className={bem('-description')}>{markdownToPlainText(item.source)?.slice(0, 50)}</p>}
          <Flex align="center" justify="between">
            <span className={bem('-meta')}>{editElapsed}</span>
            {!!item?.tag && <span className={bem('-meta')}>{pageUsage}</span>}
          </Flex>
        </Link>
      )}
    </div>
  );
}

export default SidebarNavItem;
