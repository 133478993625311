import { createColumnHelper } from '@tanstack/react-table';

import { Column } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import type { DocMetricsData } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import { CommentCell, CompanyCell, TextCell, TimeCell, VoteCell } from '@ui/ReactTable/components/Cells';

import { LinkToHubCell } from '../Table/Cells';

const columnHelper = createColumnHelper<DocMetricsData>();

export default {
  title: 'Page Quality',
  endpoint: 'pageQuality',
  exportSource: 'thumbVotes',
  fallback: 'page-quality',
  filters: ['Group', 'Vote', 'Path', 'User'],
  graph: {
    type: 'bar',
    query: 'groupBy=vote&groupBy=period',
    stacked: true,
    groups: ['company', 'path', 'email'],
    groupByBase: 'vote',
    theme: 'page-quality',
  },
  shortcuts: {
    'Score By Page': { query: 'groupBy=path&groupBy=vote' },
    'Monthly Votes': { query: 'groupBy=period&groupBy=vote', rangeLength: 30, resolution: 'day' },
  },
  table: {
    query: '',
    defaultSort: {
      column: Column.CreatedAt,
      direction: 'desc',
    },
    columns: [
      columnHelper.accessor(Column.Vote, {
        header: '',
        size: 50,
        cell: ({ getValue }) => VoteCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.CreatedAt, {
        enableHiding: false,
        header: columnHeaders[Column.CreatedAt],
        cell: ({ getValue }) => TimeCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Page, {
        enableHiding: false,
        header: columnHeaders[Column.Page],
        cell: ({ getValue, row }) => {
          const { version } = row.original;
          return LinkToHubCell({ page: getValue(), version });
        },
      }),
      columnHelper.accessor(Column.Email, {
        header: columnHeaders[Column.Email],
        cell: ({ getValue }) => TextCell({ value: getValue() || '', copyable: true }),
      }),
      columnHelper.accessor(Column.Company, {
        header: columnHeaders[Column.Company],
        minSize: 120,
        cell: ({ getValue }) => CompanyCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Comment, {
        header: columnHeaders[Column.Comment],
        cell: ({ getValue }) => CommentCell({ value: getValue() }),
        meta: {
          // required for <td> to overflow properly
          enableOverflow: true,
        },
        minSize: 200,
      }),
    ],
    prefsName: TablePrefsKey.MetricsPageQuality,
  },
};
