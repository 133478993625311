import type { ColumnDef } from '@tanstack/react-table';

import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import { Column, TableType } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import useClassy from '@core/hooks/useClassy';
import type { APIKeyRequestRow, RecentRequestRow } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import Flex from '@ui/Flex';
import KeyUsageDropdown from '@ui/Metrics/KeyUsageDropdown';
import {
  TextCell,
  CopyableCell,
  StatusCell,
  MethodCell,
  UserAgentCell,
  APIKeyCell,
  CompanyCell,
  EmptyCell,
  ElapsedTimeCell,
} from '@ui/ReactTable/components/Cells';
import SmartLink from '@ui/SmartLink';
import Sparkline from '@ui/Sparkline';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

const DEFAULT_SORT_COLUMN = 'lastRequest';
const DEFAULT_SORT_DIRECTION = 'desc';
const DEFAULT_INSIGHTS_SORT_COLUMN = 'createdAt';

interface ITablePrefs {
  columns: ColumnDef<APIKeyRequestRow | RecentRequestRow>[];
  direction: typeof DEFAULT_SORT_DIRECTION;
  endpoint: string;
  order: string[];
  prefsName: TablePrefsKey;
  sort: typeof DEFAULT_INSIGHTS_SORT_COLUMN | typeof DEFAULT_SORT_COLUMN;
  visibility: Record<string, boolean>;
}

export interface TableMeta {
  navigateToKeyInsight: (apiKey: string) => void;
  navigateToUserPage: (email: string) => void;
  /** Optional override prop for Cell subcomponents */
  theme?: 'dark';
}

// Title tooltip defaults
const TOOLTIP_DELAY: [number | null, number | null] = [500, null];
const TOOLTIP_OFFSET: [number, number] = [0, 5];

// Columns are ultimately plain objects, but createColumnHelper helps manage expected types
const columnHelper = createColumnHelper<RecentRequestRow>();
const columnHelperAPIKey = createColumnHelper<APIKeyRequestRow>();

// Default column options
export const defaultColumn: Partial<ColumnDef<APIKeyRequestRow | RecentRequestRow>> = {
  enableSorting: false,
  cell: ({ getValue }) => <TextCell value={getValue<string>() || ''} />,
};

/* eslint-disable react-hooks/rules-of-hooks */

// The cell render functions below use the useClassy hook, but rules-of-hooks doesn't recognize it as React function component
export const RecentRequestsColumns = [
  columnHelper.accessor(Column.LastRequest, {
    header: columnHeaders[Column.LastRequest],
    enableSorting: true,
    size: 120,
    cell: ({ getValue }) => <ElapsedTimeCell value={getValue()} />,
  }),
  columnHelper.accessor(Column.Company, {
    header: columnHeaders[Column.Company],
    cell: ({ getValue, table }) => {
      const value = getValue();
      const company = { name: value?.name || '', logo: value?.logo || '' };

      /**
       * - navigateToKeyInsight: callback to navigate to key insight page
       * - theme: optional override prop for Cell subcomponents
       */
      const { navigateToKeyInsight, theme } = table.options.meta as TableMeta;

      if (!navigateToKeyInsight) return null;

      return (
        <CompanyCell value={company}>
          <KeyUsageDropdown onKeyClick={navigateToKeyInsight} {...value} theme={theme} />
        </CompanyCell>
      );
    },
  }),
  columnHelper.accessor(Column.User, {
    header: columnHeaders[Column.Email],
    cell: ({ getValue, table }) => {
      const bem = useClassy(classes, 'RecentRequestTable');
      const value = getValue();
      const email = value?.email;

      /**
       * - navigateToKeyInsight: callback to navigate to key insight page
       * - navigateToUserPage: callback to navigate to user page
       * - theme: optional override prop for Cell subcomponents
       */
      const { navigateToKeyInsight, navigateToUserPage, theme } = table.options.meta as TableMeta;

      if (!navigateToKeyInsight && !navigateToUserPage) return null;

      return (
        <Flex align="center" gap="sm" justify="between">
          {email ? (
            <CopyableCell text={email}>
              <Tooltip arrow={false} content={email} delay={TOOLTIP_DELAY} offset={TOOLTIP_OFFSET} placement="bottom">
                <SmartLink className={bem('-td-truncate', '-link')} onClick={() => navigateToUserPage(email)}>
                  {email}
                </SmartLink>
              </Tooltip>
            </CopyableCell>
          ) : (
            <EmptyCell />
          )}

          <KeyUsageDropdown onKeyClick={navigateToKeyInsight} {...value} theme={theme} />
        </Flex>
      );
    },
  }),
  columnHelper.accessor(Column.APIKey, {
    header: columnHeaders[Column.APIKey],
    enableHiding: false,
    minSize: 120,
    cell: ({ getValue, table }) => {
      const bem = useClassy(classes, 'RecentRequestTable');

      // navigateToKeyInsight is custom callback passed into table options
      const { navigateToKeyInsight } = table.options.meta as TableMeta;

      if (!navigateToKeyInsight) return null;

      return (
        <SmartLink className={bem('-link')} onClick={() => navigateToKeyInsight(getValue<string>())}>
          <APIKeyCell value={getValue<string>()} />
        </SmartLink>
      );
    },
  }),
  columnHelper.accessor(Column.Trend, {
    header: columnHeaders[Column.Trend],
    enableHiding: false,
    size: 170,
    cell: ({ getValue, table }) => {
      const bem = useClassy(classes, 'RecentRequestTable');
      const value = getValue();

      /**
       * - theme: optional override prop for Cell subcomponents
       */
      const { theme } = table.options.meta as TableMeta;

      return (
        <div className={bem('-td-sparkline')}>
          <Sparkline
            data={value?.data || {}}
            height="20px"
            labels={value?.labels || []}
            maxWidth="150px"
            useDarkMode={theme === 'dark'}
          />
        </div>
      );
    },
  }),
  columnHelper.accessor(Column.Label, {
    header: columnHeaders[Column.Label],
    cell: ({ getValue }) => <TextCell value={getValue()} />,
  }),
];

export const APIKeyInsightsColumns = [
  columnHelperAPIKey.accessor(Column.Time, {
    header: columnHeaders[Column.Time],
    enableSorting: true,
    enableHiding: false,
    maxSize: 100,
    cell: ({ getValue }) => <ElapsedTimeCell value={getValue()} />,
  }),
  columnHelperAPIKey.accessor(Column.Status, {
    header: columnHeaders[Column.Status],
    enableSorting: false,
    maxSize: 70,
    cell: ({ getValue }) => <StatusCell value={getValue()} />,
  }),
  columnHelperAPIKey.accessor(Column.Method, {
    header: columnHeaders[Column.Method],
    enableSorting: false,
    maxSize: 70,
    cell: ({ getValue }) => <MethodCell value={getValue()} />,
  }),
  columnHelperAPIKey.accessor(Column.Path, {
    header: 'Endpoint', // Override from columnHeaders to be more specific
    enableSorting: false,
    enableHiding: false,
    minSize: 300,
    cell: ({ getValue }) => <TextCell value={getValue()} />,
  }),
  columnHelperAPIKey.accessor(Column.UserAgent, {
    header: columnHeaders[Column.UserAgent],
    cell: ({ getValue }) => {
      const value = getValue();
      return <UserAgentCell value={value} />;
    },
  }),
];

/* eslint-enable react-hooks/rules-of-hooks */

/**
 * This returns all of the table preferences mapped by TableType
 * - columns: the columns array
 * - direction: the default sorting direction
 * - endpoint: endpoint to fetch data for table from
 * - order: the default order of columns
 * - sort: the default column to sort by
 * - prefsName: the key used when storing order and visibility prefs in localStorage under '@readme:tablePrefs' key
 * - visibility: the default visibilty of columns
 */
export const tablePrefs = {
  [TableType.RecentRequests]: {
    columns: RecentRequestsColumns,
    direction: DEFAULT_SORT_DIRECTION,
    endpoint: 'developers/recent',
    // @ts-ignore - accessorKey exists but react-table types is missing it
    order: RecentRequestsColumns.map(({ accessorKey }) => accessorKey),
    sort: DEFAULT_SORT_COLUMN,
    prefsName: TablePrefsKey.RecentRequests,
    visibility: {
      [Column.Label]: false,
    },
  } as ITablePrefs,
  [TableType.APIKeyInsights]: {
    columns: APIKeyInsightsColumns,
    direction: DEFAULT_SORT_DIRECTION,
    endpoint: 'requests/list',

    // @ts-ignore - accessorKey exists but react-table types is missing it
    order: APIKeyInsightsColumns.map(({ accessorKey }) => accessorKey),
    sort: DEFAULT_INSIGHTS_SORT_COLUMN,
    prefsName: TablePrefsKey.APIKeyInsightsRequests,
    visibility: {
      [Column.UserAgent]: false,
    },
  } as ITablePrefs,
};
