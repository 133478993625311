import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

export default function ParamDefaultHide({ style }: SharedSvgProps) {
  return (
    <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M0 0h92v62H0z" fill="#fff" id="reuse-0" />
      </defs>
      <g clipPath="url(#clip0_313_23266)">
        <use xlinkHref="#reuse-0" />
        <path
          d="M5.782 11.5V5.159h2.531c1.288 0 2.158.848 2.158 2.118v.009c0 1.265-.87 2.118-2.158 2.118H6.916V11.5H5.782Zm2.255-5.414H6.916V8.49h1.12c.814 0 1.288-.44 1.288-1.2v-.009c0-.76-.474-1.195-1.287-1.195ZM10.83 11.5l2.25-6.341h1.28l2.25 6.341h-1.187l-.532-1.64h-2.347l-.527 1.64h-1.187Zm2.853-5.164-.853 2.65h1.78l-.853-2.65h-.074Zm4.89 5.164V6.112h-1.95V5.16h5.04v.953h-1.956V11.5h-1.133Zm4.277 0V5.159h1.133v2.636h3.085V5.16h1.13V11.5h-1.13V8.749h-3.085V11.5h-1.133Zm9.316 0V5.159h2.531c1.288 0 2.158.848 2.158 2.118v.009c0 1.265-.87 2.118-2.158 2.118h-1.397V11.5h-1.134Zm2.254-5.414h-1.12V8.49h1.12c.813 0 1.288-.44 1.288-1.2v-.009c0-.76-.475-1.195-1.288-1.195Zm2.795 5.414 2.25-6.341h1.28l2.25 6.341h-1.187l-.532-1.64H38.93l-.527 1.64h-1.187Zm2.852-5.164-.852 2.65h1.78l-.853-2.65h-.075Zm4.017 5.164V5.159h2.562c1.362 0 2.18.76 2.18 1.968v.01c0 .839-.444 1.533-1.196 1.805l1.385 2.558h-1.297l-1.243-2.386h-1.257V11.5h-1.134Zm1.134-3.256h1.31c.716 0 1.133-.4 1.133-1.081v-.01c0-.663-.435-1.076-1.156-1.076H45.22v2.167Zm4.513 3.256 2.25-6.341h1.279l2.25 6.341h-1.187l-.532-1.64h-2.346l-.527 1.64h-1.187Zm2.852-5.164-.853 2.65h1.78l-.852-2.65h-.075Zm4.017 5.164V5.159h1.3l1.908 4.878h.074l1.903-4.878h1.3V11.5h-1.019V6.991h-.079L60.23 11.5h-.773l-1.758-4.509h-.075V11.5h-1.023Zm10.252.158c-1.472 0-2.408-.725-2.487-1.82l-.005-.06h1.099l.004.043c.053.532.62.884 1.424.884.765 0 1.301-.378 1.301-.923v-.005c0-.457-.33-.734-1.147-.91l-.681-.14c-1.297-.272-1.859-.879-1.859-1.824V6.9c0-1.134.976-1.899 2.347-1.899 1.406 0 2.28.77 2.36 1.79l.004.056h-1.086l-.008-.053c-.08-.492-.55-.843-1.275-.839-.703 0-1.204.33-1.204.87v.005c0 .448.32.73 1.112.896l.681.145c1.336.281 1.894.817 1.894 1.776v.004c0 1.235-.953 2.008-2.474 2.008Z"
          fill="#4F5A66"
        />
        <g clipPath="url(#clip1_313_23266)">
          <g filter="url(#filter0_i_313_23266)">
            <path d="M5 18h82v20H5z" fill="#F8F8F8" />
            <rect fill="#000" fillOpacity=".1" height="6" rx="2.628" width="25" x="8" y="21" />
            <rect fill="#000" fillOpacity=".1" height="4" rx="2" width="36" x="8" y="31" />
            <rect fill="#fff" height="14" rx="2.306" width="32" x="52" y="21" />
            <rect
              height="14.461"
              rx="2.537"
              stroke="#000"
              strokeOpacity=".1"
              strokeWidth=".461"
              width="32.461"
              x="51.769"
              y="20.769"
            />
          </g>
          <path d="M5 38h82v20H5z" fill="#F8F8F8" />
          <rect fill="#000" fillOpacity=".1" height="6" rx="2.628" width="25" x="8" y="41" />
          <rect fill="#000" fillOpacity=".1" height="4" rx="2" width="39" x="8" y="51" />
          <rect fill="#fff" height="14" rx="2.306" width="32" x="52" y="41" />
          <rect
            height="14.461"
            rx="2.537"
            stroke="#000"
            strokeOpacity=".1"
            strokeWidth=".461"
            width="32.461"
            x="51.769"
            y="40.769"
          />
        </g>
        <rect
          height="40.461"
          rx="4.231"
          stroke="#000"
          strokeOpacity=".1"
          strokeWidth=".461"
          width="82.461"
          x="4.769"
          y="17.769"
        />
      </g>
      <defs>
        <clipPath id="clip0_313_23266">
          <use xlinkHref="#reuse-0" />
        </clipPath>
        <clipPath id="clip1_313_23266">
          <rect fill="#fff" height="40" rx="4" width="82" x="5" y="18" />
        </clipPath>
      </defs>
    </svg>
  );
}
