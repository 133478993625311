import type { ErrorRepresentation } from '../legacy_mappings/error';
import type { z } from 'zod';

/**
 * This error handles errors that occur within our API Representation mapping layer.
 *
 */
export default class MapperError extends Error {
  errors: z.infer<typeof ErrorRepresentation>['errors'];

  constructor(msg: string, errors: MapperError['errors']) {
    super(msg);

    this.name = 'MapperError';
    this.errors = errors;
  }

  getErrors() {
    return this.errors;
  }
}
