import React from 'react';

import useClassy from '@core/hooks/useClassy';

import CopyToClipboard from '@ui/CopyToClipboard';

import styles from './style.module.scss';

interface Props {
  children: string;
  variant?: 'multiline';
}

export default function CopyCode({ children, variant }: Props) {
  const bem = useClassy(styles, 'CopyCode');
  return (
    <div className={bem('-copy-code', variant === 'multiline' && '-copy-code_multiline')}>
      <code>
        <span className={bem('-copy-code-muted')}>$ </span>
        {children}
      </code>
      <CopyToClipboard className={bem('-copy-code-btn')} text={children} />
    </div>
  );
}
