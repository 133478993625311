import type { ComponentProps } from 'react';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import MockPlayground from '@ui/MockPlayground';

import { Page } from '../../shared';

import styles from './style.module.scss';

const PersonalizedDocsLayout: React.FC = ({ children }) => {
  const bem = useClassy(styles, 'PersonalizedDocsLayout');

  const tooltips: ComponentProps<typeof MockPlayground>['tooltips'] = [
    {
      selector: 'auth-section',
      eyebrow: 'API Keys',
      icon: 'key',
      title: 'Pre-Populate API Keys',
      content: 'With variables populated from the OAS files',
      position: 'top',
    },
    {
      selector: 'code-snippet-section',
      icon: 'terminal',
      eyebrow: 'Server Variables',
      title: 'Personalize your ‘Try it’',
      content: 'With variables populated from the OAS files',
      position: 'bottom',
    },
  ];

  return (
    <Page renderActionSlot={false}>
      <div className={bem('&')}>
        <div className={bem('-content')}>{children}</div>
        <div className={bem('-mock-try-it')} inert="">
          <MockPlayground
            apiKey="readme-apikey-5"
            cycleInterval={10000}
            method="GET"
            slug="my-endpoint"
            theme="light"
            tooltips={tooltips}
          />
        </div>
      </div>
      {/* used by the error modal in PersonalizedDocs/WebhookTestForm/index.tsx */}
      <div className="ModalWrapper" id="personalized-docs-modal-target" />
    </Page>
  );
};

export default PersonalizedDocsLayout;
