import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

export default function ResponseSchemaCollapsed({ style }: SharedSvgProps) {
  return (
    <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M0 0h92v62H0z" fill="#fff" id="reuse-0" />
      </defs>
      <g clipPath="url(#clip0_314_23350)">
        <use xlinkHref="#reuse-0" />
        <g clipPath="url(#clip1_314_23350)">
          <g filter="url(#filter0_i_314_23350)">
            <path d="M5 8h82v23H5z" fill="#F8F8F8" />
            <g filter="url(#filter1_dii_314_23350)">
              <g filter="url(#filter2_dii_314_23350)">
                <rect fill="#12CA93" height="5.535" rx="2.767" width="5.535" x="9" y="13.732" />
                <rect
                  height="5.074"
                  rx="2.537"
                  stroke="#000"
                  strokeOpacity=".15"
                  strokeWidth=".461"
                  width="5.074"
                  x="9.231"
                  y="13.963"
                />
              </g>
            </g>
            <path
              d="M17.45 19v-.594l1.87-1.965c.746-.777.961-1.066.961-1.523v-.012c0-.55-.379-.957-.996-.957-.633 0-1.055.414-1.055 1.031l-.003.012h-.82l-.009-.008c.004-1.03.801-1.757 1.934-1.757 1.05 0 1.824.668 1.824 1.597v.012c0 .64-.297 1.133-1.32 2.148l-1.195 1.192v.078h2.605V19H17.45Zm6.933.137c-1.301 0-2.082-1.137-2.082-2.953v-.008c0-1.817.781-2.95 2.082-2.95 1.297 0 2.082 1.133 2.082 2.95v.008c0 1.816-.785 2.953-2.082 2.953Zm0-.727c.754 0 1.199-.848 1.199-2.226v-.008c0-1.38-.445-2.219-1.2-2.219-.757 0-1.198.84-1.198 2.219v.008c0 1.378.441 2.226 1.199 2.226Zm5.195.727c-1.3 0-2.082-1.137-2.082-2.953v-.008c0-1.817.781-2.95 2.082-2.95 1.297 0 2.082 1.133 2.082 2.95v.008c0 1.816-.785 2.953-2.082 2.953Zm0-.727c.754 0 1.2-.848 1.2-2.226v-.008c0-1.38-.446-2.219-1.2-2.219-.758 0-1.2.84-1.2 2.219v.008c0 1.378.442 2.226 1.2 2.226Z"
              fill="#384248"
            />
            <rect fill="#000" fillOpacity=".1" height="4" rx="2" width="40" x="9" y="23" />
            <path
              clipRule="evenodd"
              d="M74.942 17.437c.12-.12.314-.12.434 0l1.846 1.845c.12.12.12.315 0 .435l-1.846 1.845a.308.308 0 1 1-.434-.435l1.627-1.627-1.627-1.628a.308.308 0 0 1 0-.435Z"
              fill="#637288"
              fillRule="evenodd"
            />
          </g>
          <path d="M5 31h82v23H5z" fill="#F8F8F8" />
          <g filter="url(#filter3_dii_314_23350)">
            <g filter="url(#filter4_dii_314_23350)">
              <rect fill="#E95F6A" height="5.535" rx="2.767" width="5.535" x="9" y="36.732" />
              <rect
                height="5.074"
                rx="2.537"
                stroke="#000"
                strokeOpacity=".15"
                strokeWidth=".461"
                width="5.074"
                x="9.231"
                y="36.963"
              />
            </g>
          </g>
          <path
            d="M20.035 42v-1.121h-2.75v-.742a76.895 76.895 0 0 1 2.348-3.774h1.246v3.774h.777v.742h-.777V42h-.844Zm-1.91-1.848h1.922v-3.05h-.05a64.326 64.326 0 0 0-1.872 2.996v.054Zm6.586 1.985c-1.3 0-2.082-1.137-2.082-2.953v-.008c0-1.817.781-2.95 2.082-2.95 1.297 0 2.082 1.133 2.082 2.95v.008c0 1.816-.785 2.953-2.082 2.953Zm0-.727c.754 0 1.2-.847 1.2-2.226v-.008c0-1.38-.446-2.219-1.2-2.219-.758 0-1.2.84-1.2 2.219v.008c0 1.379.442 2.226 1.2 2.226Zm5.195.727c-1.3 0-2.082-1.137-2.082-2.953v-.008c0-1.817.781-2.95 2.082-2.95 1.297 0 2.082 1.133 2.082 2.95v.008c0 1.816-.785 2.953-2.082 2.953Zm0-.727c.754 0 1.2-.847 1.2-2.226v-.008c0-1.38-.446-2.219-1.2-2.219-.758 0-1.199.84-1.199 2.219v.008c0 1.379.441 2.226 1.2 2.226Z"
            fill="#384248"
          />
          <rect fill="#000" fillOpacity=".1" height="4" rx="2" width="54" x="9" y="46" />
          <path
            clipRule="evenodd"
            d="M74.942 40.437c.12-.12.314-.12.434 0l1.846 1.845c.12.12.12.315 0 .435l-1.846 1.845a.308.308 0 1 1-.434-.435l1.627-1.627-1.627-1.628a.308.308 0 0 1 0-.435Z"
            fill="#637288"
            fillRule="evenodd"
          />
        </g>
        <rect
          height="46.461"
          rx="4.231"
          stroke="#000"
          strokeOpacity=".1"
          strokeWidth=".461"
          width="82.461"
          x="4.769"
          y="7.769"
        />
      </g>
      <defs>
        <clipPath id="clip0_314_23350">
          <use xlinkHref="#reuse-0" />
        </clipPath>
        <clipPath id="clip1_314_23350">
          <rect fill="#fff" height="46" rx="4" width="82" x="5" y="8" />
        </clipPath>
      </defs>
    </svg>
  );
}
