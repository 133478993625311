import type { ComponentProps, ReactNode } from 'react';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';
import Title from '@ui/Title';

import VariableTooltip from '../VariableTooltip';
import WebhookVariablesTable from '../WebhookVariablesTable';

import styles from './style.module.scss';

export const ManageWebhookVariablesContainer: React.FC = ({ children }) => {
  const bem = useClassy(styles, 'ManageWebhookVariables');

  return <div className={bem('-variable-sections')}>{children}</div>;
};

export const ManageWebhookVariablesSection: React.FC = ({ children }) => {
  const bem = useClassy(styles, 'ManageWebhookVariables');

  return <section className={bem('-variable-sections-item')}>{children}</section>;
};

interface TooltipProps extends ComponentProps<typeof VariableTooltip> {
  children: ReactNode;
}

export function ManageWebhookVariablesTooltip({ children, ...rest }: TooltipProps) {
  const bem = useClassy(styles, 'ManageWebhookVariables');

  return (
    <VariableTooltip {...rest}>
      <span className={bem('-variables-section-tooltip')}>
        {children}
        <Icon className={bem('-variables-section-tooltip-icon')} name="help-circle" />
      </span>
    </VariableTooltip>
  );
}

interface HeaderProps {
  description?: ReactNode;
  title: ReactNode;
}

export function ManageWebhookVariablesHeader({ title, description }: HeaderProps) {
  const bem = useClassy(styles, 'ManageWebhookVariables');

  return (
    <div className={bem('-variable-sections-item-header')}>
      <Title as={2} className={bem('-variable-sections-item-header-title')} level={5}>
        {title}
      </Title>
      {!!description && <p className={bem('-variable-sections-item-header-description')}>{description}</p>}
    </div>
  );
}

export function ManageWebhookVariablesTable(props: ComponentProps<typeof WebhookVariablesTable>) {
  const bem = useClassy(styles, 'ManageWebhookVariables');

  return (
    <div className={bem('-variable-sections-item-table')}>
      <WebhookVariablesTable {...props} />
    </div>
  );
}
