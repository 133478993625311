import type { CustomBlockMgmtContextValue } from '.';

import React, { useMemo, useState } from 'react';

import CustomBlockMgmtContext from '.';

export interface CustomBlockMgmtContextProviderProps {
  children: React.ReactNode;
  type: CustomBlockMgmtContextValue['type'];
}

export function CustomBlockMgmtContextProvider({ children, type }: CustomBlockMgmtContextProviderProps) {
  const [filter, setFilter] = useState({ search: '', sort: 'updated_at' });
  const routeSegment = type === 'component' ? 'custom-components' : 'reusable-content';

  return (
    <CustomBlockMgmtContext.Provider
      value={useMemo(() => ({ type, filter, setFilter, routeSegment }), [filter, routeSegment, type])}
    >
      {children}
    </CustomBlockMgmtContext.Provider>
  );
}
