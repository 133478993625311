import React from 'react';

import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuItem from '@ui/Menu/Item';

import MetricsImage from './images/pricing-tooltip-metrics.png';
import MyDevelopersImage from './images/pricing-tooltip-my-developers.png';
import classes from './style.module.scss';
import { PricingTooltipType } from './types';

interface Props {
  /** Whether the tooltip is being used in the Hub */
  isHub?: boolean;
  link: string;
  type: PricingTooltipType.Limit | PricingTooltipType.Metrics | PricingTooltipType.MyDevelopers;
}

export const PricingTooltip = ({ isHub, type, link }: Props) => {
  return (
    <Menu className={classes.PricingTooltip} theme="dark">
      <MenuItem focusable={false} img={{ src: type === 'metrics' ? MetricsImage : MyDevelopersImage }} />
      <MenuItem className={classes['PricingTooltip-header']} focusable={false} icon={<Icon name="sparkles" />}>
        <span>Add On</span>
      </MenuItem>
      <MenuItem className={classes['PricingTooltip-description']} focusable={false}>
        <span>Add Developer Dashboard to your plan to get access to 1 year of request history (and more)!</span>
      </MenuItem>
      <MenuDivider />
      <MenuItem
        href={link}
        TagName="a"
        {...(isHub
          ? {
              target: '_blank',
            }
          : {
              icon: <Icon name="arrow-right" strokeWeight={2.5} />,
              alignIcon: 'right',
            })}
      >
        <span>Manage Plan</span>
      </MenuItem>
    </Menu>
  );
};

export const PricingText = ({ type }: { type: PricingTooltipType }) => {
  return (
    <div className={classes.PricingText}>
      <i className="icon-sparkles" /> {type === PricingTooltipType.Limit ? 'UPGRADE' : 'ADD ON'}
    </div>
  );
};
