import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Profile from '@ui/Profile';
import Spinner from '@ui/Spinner';
import Timestamp from '@ui/Timestamp';

import classes from './style.module.scss';

interface UserProfileProps {
  className?: string;
  isLoading?: boolean;
  isSuperhub?: boolean;
  user?: {
    avatar: string;
    email: string;
    label: string;
    lastActive?: Date;
  };
}

function UserProfileCard({
  className,
  isLoading,
  isSuperhub,
  user = {
    avatar: '',
    email: '',
    label: '',
  },
}: UserProfileProps) {
  const bem = useClassy(classes, 'UserProfileCard');

  return (
    <Flex align="center" className={bem(className, '&', '&-metaData')} justify="center" layout="col">
      {isLoading ? (
        <Spinner size="md" />
      ) : (
        <>
          <Profile
            avatar={user.avatar || user.label || user.email || ' '}
            className={bem('&-avatar')}
            subtitle={
              user.lastActive ? (
                <>
                  Active&nbsp;
                  <Timestamp value={user.lastActive} />
                </>
              ) : undefined
            }
            textClassName={bem('&-text')}
            title={user.label || user.email || 'Unknown'}
          />
          <Button
            className={bem('&-email')}
            href={`mailto:${user.email}`}
            kind={isSuperhub ? 'success' : 'primary'}
            outline
            size="sm"
          >
            <Icon name="mail" />
            Send Email
          </Button>
        </>
      )}
    </Flex>
  );
}

export default UserProfileCard;
