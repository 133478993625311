import React from 'react';

import useDebounced from '@core/hooks/useDebounced';
import { useMetricsStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Input from '@ui/Input';
import Menu, { MenuHeader, MenuItem } from '@ui/Menu';

interface Props {
  onChange: () => void;
}

const FilterPath = ({ onChange }: Props) => {
  const [updateQuery, query] = useMetricsStore(s => [s.updateQuery, s.query]);

  const handleChange = useDebounced(function handler(filter) {
    updateQuery('query', { path: filter || null });
    onChange();
  }, 500);

  const isActive = Boolean(query.path);

  return (
    <Dropdown align="bottom" justify="start" sticky>
      <Button dropdown kind={isActive ? 'primary' : 'secondary'} outline={!isActive} size="sm">
        <Flex align="center" gap="xs">
          <span className="Filter-label">{query.path || 'Page'}</span>
        </Flex>
      </Button>
      <Menu>
        <MenuHeader>Filter</MenuHeader>
        <MenuItem focusable={false} TagName="label">
          <Input
            autoComplete="off"
            name="path"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value)}
            placeholder="/"
            size="sm"
            type="text"
            value={query.path || ''}
          />
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default FilterPath;
