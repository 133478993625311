import base64url from 'base64url';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import usePrevious from '@core/hooks/usePrevious';
import type { MyDevelopersRouteParams } from '@core/types/metrics';

import { useMetricsStore } from '..';

export interface ConnectMyDevelopersToRouterProps {
  children: React.ReactNode;
}

/**
 * Middleware component that listens for browser router updates and continually
 * updates the store state whenever the route changes.
 */
export function ConnectMyDevelopersToRouter({ children }: ConnectMyDevelopersToRouterProps) {
  const [updateQuery, resetFilters, updateActiveSegment, updateFilters, updateRoute, matchingSegment] = useMetricsStore(
    s => [
      s.updateQuery,
      s.myDevelopers.resetFilters,
      s.myDevelopers.updateActiveSegment,
      s.myDevelopers.updateFilters,
      s.myDevelopers.updateRoute,
      s.myDevelopers.getMatchingSegment(),
    ],
  );

  // Route params from /metrics/developers/:type(key|segment|user)?/:identifier path
  const { type, identifier } = useParams<MyDevelopersRouteParams>();
  const prevType = usePrevious(type);
  const { search } = useLocation();

  // Tracks if we've currently in the middle of navigation change (:type route param has changed)
  const isNavigating = useMemo(() => {
    return prevType !== type;
  }, [prevType, type]);

  // Keep route params in sync with store
  useEffect(() => {
    updateRoute({
      type,
      identifier,
    });
  }, [type, identifier, updateRoute]);

  // Set active segment based on any matching segment from /segment/:slug route
  useEffect(() => {
    if (matchingSegment) {
      updateActiveSegment(matchingSegment);
    }
  }, [matchingSegment, updateActiveSegment]);

  /**
   * Support query filtering for userSearch param via query params
   * eg: `/?userSearch=tony@readme.io`
   */
  useEffect(() => {
    if (!search) return;

    const searchParams = new URLSearchParams(search);
    const userSearch = searchParams.get('userSearch') ?? '';
    const isEncoded = searchParams.get('encoded') === 'true';

    // Apply search to filters
    if (userSearch) {
      const value = isEncoded ? base64url.decode(userSearch) : userSearch;

      updateFilters({
        userSearch: value,
      });
    }
  }, [search, updateFilters]);

  /**
   * Navigation side effects
   *
   * When navigating away from segment route:
   * - Reset filters to initial state
   * - Maintain active date filters if navigating to API Key insight route (/metrics/developers/key/:identifier) or back to "Recent Requests" route
   *
   * When navigating to user route:
   * - Reset userSearch filter to initial state
   */
  useEffect(() => {
    if (isNavigating) {
      if (prevType === MyDevelopersSubrouteType.Segment) {
        const maintainCurrentDateFilters = type === MyDevelopersSubrouteType.Key;
        resetFilters(maintainCurrentDateFilters);
      }

      if (type === MyDevelopersSubrouteType.User) {
        updateQuery('query', { userSearch: null });
      }
    }
  }, [isNavigating, resetFilters, prevType, type, updateQuery]);

  return <>{children}</>;
}
