import { useEffect, useRef } from 'react';

import { useSuperHubStore } from '@core/store';

/**
 * Auto-scrolls the window to the very top whenever the Island is expanded. When
 * the Island collapses, window auto-scrolls back to the original scroll
 * position just before the Island exapnded.
 */
export default function useAutoScrollToTop() {
  const layout = useSuperHubStore(s => s.layout);

  /**
   * Keeps track of the last scroll-Y position before the island expands so we
   * can scroll back to where the user last left off when the island collapses.
   */
  const lastScrollTopRef = useRef(window.scrollY);

  useEffect(() => {
    let nextScrollTop: number;

    if (layout === 'default') {
      lastScrollTopRef.current = window.scrollY;
      nextScrollTop = 0;
    } else {
      nextScrollTop = lastScrollTopRef.current;
    }

    // Smooth scroll either to the top or to their last position.
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: nextScrollTop,
    });
  }, [layout]);
}
