import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

const HeaderTypeGradient = ({ style }: SharedSvgProps) => (
  <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3_885)">
      <path d="M0 0h92v62H0z" fill="url(#paint0_linear_3_885)" />
      <g clipPath="url(#clip1_3_885)">
        <rect fill="#F0F0F0" height="62" rx="4" width="118" x="9" y="15" />
        <path d="M0 0h190v34H0z" fill="url(#paint1_linear_3_885)" transform="translate(9 15)" />
        <path
          d="M9 15h190H9Zm190 21H9v-2h190v2ZM9 35V15v20Zm190-20v20-20Z"
          fill="#000"
          fillOpacity=".1"
          mask="url(#path-2-inside-1_3_885)"
        />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="4" rx="2" width="18" x="19" y="40" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="4" rx="2" width="18" x="45" y="40" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="4" rx="2" width="18" x="71" y="40" />
      </g>
    </g>
    <defs>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3_885"
        x1="75.414"
        x2="95.644"
        y1="14.208"
        y2="14.293"
      >
        <stop stopColor="#AE9ED4" />
        <stop offset="1" stopColor="#8AB0FF" />
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="paint1_linear_3_885" x1="95" x2="95" y1="0" y2="34">
        <stop stopColor="var(--Preview-primary-color)" />
        <stop offset="1" stopColor="var(--Preview-primary-alt-color)" />
      </linearGradient>
      <clipPath id="clip0_3_885">
        <path d="M0 0h92v62H0z" fill="#fff" />
      </clipPath>
      <clipPath id="clip1_3_885">
        <rect fill="#fff" height="62" rx="4" width="118" x="9" y="15" />
      </clipPath>
    </defs>
  </svg>
);
export default HeaderTypeGradient;
