import React, { useContext } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import { CopyableCell, EmptyCell } from '@ui/ReactTable/components/Cells';
import Tooltip from '@ui/Tooltip';

import styles from './style.module.scss';

const TOOLTIP_DELAY: [number | null, number | null] = [500, null];
const TOOLTIP_OFFSET: [number, number] = [0, 5];

export const LinkToHubCell = ({ page, version }: { page: string; version?: string }) => {
  const bem = useClassy(styles, 'MetricsTableCell');
  const { project } = useContext(ProjectContext) as ProjectContextValue;

  if (!page) return <EmptyCell />;

  let pageUrl = `/go/${project.subdomain}?redirect=${version ? `/v${version}` : ''}${page}`;
  let pageDisplay = page;

  // strip the subdomain from the url if it’s there
  if (page.split('/')[1] === project.subdomain) {
    pageUrl = `/go/${project.subdomain}?redirect=${version ? `/v${version}` : ''}${page.replace(
      `/${project.subdomain}`,
      '',
    )}`;
    pageDisplay = page.replace(`/${project.subdomain}`, '');
  }

  return (
    <Tooltip arrow={false} content={pageDisplay} delay={TOOLTIP_DELAY} offset={TOOLTIP_OFFSET}>
      <a className={bem('-page-url')} href={pageUrl} rel="noreferrer" target="_blank">
        <span className={bem('-page-url-text')}>{pageDisplay}</span>
        <Icon className={bem('-page-url-icon')} name="arrow-up-right" />
      </a>
    </Tooltip>
  );
};

export const UserCell = ({ groupEmail, groupLabel }: { groupEmail: string; groupLabel: string }) => {
  const bem = useClassy(styles, 'MetricsTableCell');

  return (
    <CopyableCell text={groupEmail}>
      <Flex className={bem('-user-label')} gap={0} layout="col">
        <Tooltip arrow={false} content={groupLabel || groupEmail} delay={TOOLTIP_DELAY} offset={TOOLTIP_OFFSET}>
          <span>{groupLabel || groupEmail}</span>
        </Tooltip>

        {!!groupLabel && !!groupEmail && (
          <Tooltip arrow={false} content={groupEmail} delay={TOOLTIP_DELAY} offset={TOOLTIP_OFFSET}>
            <span className={bem('-user_secondary')}>{groupEmail}</span>
          </Tooltip>
        )}
      </Flex>
    </CopyableCell>
  );
};

export const FilterByEmailCell = ({ filterByEmail }: { filterByEmail: () => void }) => {
  return (
    <Tooltip arrow={false} content="Filter by Email" offset={TOOLTIP_OFFSET}>
      <Button
        ghost
        kind="minimum"
        onClick={e => {
          e?.stopPropagation();
          filterByEmail();
        }}
        size="xs"
      >
        <Icon name="filter" />
      </Button>
    </Tooltip>
  );
};
