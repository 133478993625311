import type { InstallationDeletedEvent, PushEvent } from '@octokit/webhooks-types';

import { z } from 'zod';

import { zodUnpaginatedCollectionWrapper } from '../../core/mapper';

export type GithubEvent = InstallationDeletedEvent | PushEvent;

export const Provider = {
  github: 'github',
  gitlab: 'gitlab',
  local: 'local',
} as const;

export type GitSyncProviderUnion = (typeof Provider)[keyof typeof Provider];

const OwnerType = {
  Organization: 'Organization',
  User: 'User',
} as const;

export type GitSyncOwnerTypeUnion = (typeof OwnerType)[keyof typeof OwnerType];

export const Visibility = {
  public: 'public',
  private: 'private',
  internal: 'internal',
} as const;

export type GitSyncVisibilityUnion = (typeof Visibility)[keyof typeof Visibility];

const SyncStage = {
  ingested: 'ingested',
  enqueued: 'enqueued',
  redundant: 'redundant',
  syncing: 'syncing',
  caching: 'caching',
} as const;

const SyncStageStatus = {
  successful: 'successful',
  failed: 'failed',
  processing: 'processing',
} as const;

export const ExternalRepository = z.object({
  description: z.string().nullable(),
  full_name: z.string().describe('The full name of the repo.'),
  id: z.string().describe('The stringified external repo id.'),
  name: z.string().describe('The name of the repo.'),
  privacy: z.object({
    private: z.boolean(),
    visibility: z
      .enum([Visibility.public, Visibility.private, Visibility.internal])
      .describe('Whether this repo is private, public, or internal.'),
  }),
  url: z.string().describe('The url to the repo.'),
  organization: z.object({
    id: z.string().describe('The mongo _id of the organization this repo belongs to.'),
    name: z.string().describe('The name of the organization this repo belongs to.'),
    type: z
      .enum([OwnerType.User, OwnerType.Organization])
      .describe('The type of the organization this repo belongs to.'),
  }),
  provider: z.enum([Provider.gitlab, Provider.github, Provider.local]).describe('The provider this repo comes from.'),
});

export const ExternalRepositoriesList = z.object({
  owner: z.object({
    id: z.number().describe('The ID of the owner organization.'),
    login: z.string().describe('The name of the owner organization.'),
    type: z.string(),
    connectionId: z
      .string()
      .describe('The stringified installation ID from GitHub or GitLab needed to externally link to the connection.'),
  }),
  repositories: z.array(ExternalRepository),
});

export const ConnectedExternalRepository = z.object({
  owner: z.object({
    id: z.number().describe('The ID of the owner organization.'),
    login: z.string().describe('The name of the owner organization.'),
    type: z
      .enum([OwnerType.User, OwnerType.Organization])
      .describe('The type of the organization this repo belongs to.'),
    connectionId: z
      .string()
      .describe('The stringified installation ID from GitHub or GitLab needed to externally link to the connection.'),
  }),
  repository: ExternalRepository,
});

export const ReducedConnectedRepository = z.object({
  id: z.string().describe('The stringified external repo id.'),
  name: z.string().describe('The name of the repo.'),
  url: z.string().describe('The url to the repo.'),
  organization: z.object({
    name: z.string().describe('The name of the organization this repo belongs to.'),
    type: z
      .enum([OwnerType.User, OwnerType.Organization])
      .describe('The type of the organization this repo belongs to.'),
    active: z.boolean().default(true).describe('Whether the organization is currently suspended or not.'),
  }),
  connected_at: z
    .string()
    .datetime()
    .describe('An ISO 8601 formatted date for when the repository was last connected.'),
  connected_by: z.string().nullable().describe('The email of the User who last connected the external repository.'),
});

const SyncStatus = z.object({
  id: z.string(),
  completedAt: z.string().datetime().optional().nullable(),
  branch: z.string(),
  external_repository: z.string(),
  attempt: z.number(),
  action: z.enum(['push', 'pull']),
  provider: z.enum([Provider.github, Provider.gitlab, Provider.local]),
  logs: z.array(
    z.object({
      stage: z.enum([
        SyncStage.ingested,
        SyncStage.enqueued,
        SyncStage.redundant,
        SyncStage.syncing,
        SyncStage.caching,
      ]),
      message: z.string().optional().nullable(),
      timestamp: z.string().datetime(),
      status: z.enum([SyncStageStatus.failed, SyncStageStatus.successful, SyncStageStatus.processing]),
    }),
  ),
});

export const SyncStatusByBranch = z.object({
  branch: z.string(),
  lastSync: SyncStatus.nullable(),
});

export const GetRepositoriesRepresentation = zodUnpaginatedCollectionWrapper(ExternalRepositoriesList);
export const GetConnectedRepositoryRepresentation = z.object({ data: ReducedConnectedRepository });
export const GetSyncStatusRepresentation = z.object({ data: z.array(SyncStatusByBranch) });

export type ExternalRepositoriesListType = z.infer<typeof ExternalRepositoriesList>;
export type ExternalRepositoryType = z.infer<typeof ExternalRepository>;
export type ConnectedExternalRepositoryType = z.infer<typeof ConnectedExternalRepository>;
export type GetRepositoriesType = z.infer<typeof GetRepositoriesRepresentation>;
export type ReducedConnectedRepositoryType = z.infer<typeof ReducedConnectedRepository>;
export type SyncStatusListType = z.infer<typeof SyncStatusByBranch>;
