import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';

import styles from './style.module.scss';

const FormError: React.FC = ({ children }) => {
  const bem = useClassy(styles, 'FormError');

  return <div className={bem('-error-message')}>{children}</div>;
};

export const FormErrorHeading: React.FC = ({ children }) => {
  const bem = useClassy(styles, 'FormError');

  return (
    <h4 className={bem('-error-message-heading')}>
      <Icon className={bem('-error-message-icon')} name="alert-circle" />
      <div>{children}</div>
    </h4>
  );
};

export const FormErrorDescription: React.FC = ({ children }) => {
  const bem = useClassy(styles, 'FormError');

  return <p className={bem('-description')}>{children}</p>;
};

export default FormError;
