import type { ComponentProps } from 'react';

import PropTypes from 'prop-types';
import React from 'react';

import BaseInstructions from './base';

const installCode = 'composer require readme/metrics';
const publishCode = `php artisan vendor:publish \\
--provider="ReadMe\\ServiceProvider"`;

const classCode = `<?php
namespace App\\Handler;

use Illuminate\\Http\\Request;

// Define a class to decorate API requests with context
class ReadMe implements \\ReadMe\\Handler
{
  public static function group(Request $request): array
  {
    return [
      // User's API Key
      'api_key' => 'owlbert-api-key',
      // Username to show in ReadMe's dashboard
      'label' => 'Owlbert',
      // User's email address
      'email' => 'owlbert@example.com'
    ];
  }
}
`;

const apiCallCode = `/**
* The application's route middleware groups.
*
* @var array
*/
protected $middlewareGroups = [
  'web' => [
      // Any web middleware you may have set up.
  ],
  'api' => [
      \\ReadMe\\Middleware::class,
      // Any other API middleware you may have set up.
  ],
];
`;

const getConfigCode = apiKey => `'apiKey': '${apiKey}',`;

interface PHPInstructionsProps extends Omit<ComponentProps<typeof BaseInstructions>, 'instructions'> {
  selectedAPIKey: string;
}

function PHPInstructions({ apiKeyDropdown, isQuickstart, log, selectedAPIKey }: PHPInstructionsProps) {
  const installPrompt = (
    <span>
      Install the{' '}
      <a href="https://packagist.org/packages/readme/metrics" rel="noreferrer" target="_blank">
        readme/metrics
      </a>{' '}
      package from{' '}
      <a href="https://packagist.org" rel="noreferrer" target="_blank">
        Packagist
      </a>
      .
    </span>
  );

  const publishPrompt = (
    <div>
      Publish the configuration file for readme/metrics, named <code>readme.php</code>, to your application via the
      <code>config/</code> directory.
    </div>
  );

  const thirdPrompt = (
    <div>
      In <code>config/readme.php</code>, change <code>api_key</code> to your ReadMe project API key.
    </div>
  );

  const fourthPrompt = (
    <div>
      Create a new class in <code>app\Handler</code> that works as a grouping function. This can give additional context
      to your API request, such as a user’s email and API key.
    </div>
  );

  const fifthPrompt = (
    <>
      <div>
        Once you’ve done all that, add <code>\ReadMe\Middleware::class</code> to your API middleware in{' '}
        <code>app/Http/Kernel.php</code>.
      </div>
    </>
  );

  const instructions = [
    {
      code: installCode,
      prompt: installPrompt,
      language: 'php',
    },
    {
      code: publishCode,
      prompt: publishPrompt,
      language: 'php',
    },
    {
      prompt: thirdPrompt,
      code: getConfigCode(selectedAPIKey),
      includesAPIKey: true,
      language: 'php',
    },
    {
      prompt: fourthPrompt,
      code: classCode,
      language: 'php',
    },
    {
      prompt: fifthPrompt,
      code: apiCallCode,
      language: 'php',
    },
  ];

  return (
    <BaseInstructions
      apiKeyDropdown={apiKeyDropdown}
      instructions={instructions}
      isQuickstart={isQuickstart}
      log={log}
    />
  );
}

PHPInstructions.propTypes = {
  apiKeyDropdown: PropTypes.element.isRequired,
  isQuickstart: PropTypes.bool,
  log: PropTypes.object,
  selectedAPIKey: PropTypes.string,
};

export default PHPInstructions;
