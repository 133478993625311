import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import DemoGate from '@routes/SuperHub/Layout/PlanAccess/DemoGate';

import CodeInput from '@ui/CodeInput';
import { RHFGroup } from '@ui/RHF';

import { useProjectSettingsFormContext, useFormGateContext } from '../Context';
import { FormRow, Page, PageContent } from '../shared';

import classes from './style.module.scss';

function CustomHTML() {
  const uid = useUniqueId('CustomHTML');
  const bem = useClassy(classes, 'CustomCode');
  const { control } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();

  return (
    <Page>
      <PageContent isCondensed>
        <DemoGate access={formGate === 'demo' ? 'locked' : 'open'} feature="html">
          <FormRow columns={1} fullWidth>
            <RHFGroup
              control={control}
              description="Goes under <head> tag. Good for meta tags and loading external CSS."
              id={uid('header')}
              label="Header"
              name="appearance.custom_code.html.header"
              size="sm"
            >
              {({ field }) => (
                <CodeInput
                  {...field}
                  className={bem('-code-input')}
                  initialValue={field.value}
                  isDarkMode
                  language="html"
                />
              )}
            </RHFGroup>

            <RHFGroup
              control={control}
              id={uid('end-of-body')}
              label="End of Body Tag"
              name="appearance.custom_code.html.home_footer"
              size="sm"
            >
              {({ field }) => (
                <CodeInput
                  {...field}
                  className={bem('-code-input')}
                  initialValue={field.value}
                  isDarkMode
                  language="html"
                />
              )}
            </RHFGroup>
          </FormRow>
        </DemoGate>
      </PageContent>
    </Page>
  );
}

export default CustomHTML;
