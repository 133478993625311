import React from 'react';

import classes from './index.module.scss';

interface PreviewRowProps {
  children: React.ReactNode;
}
const PreviewRow = ({ children }: PreviewRowProps) => (
  <div className={classes.PreviewRow}>
    <div className={classes['PreviewRow-layout']}>{children}</div>
  </div>
);

export default PreviewRow;
