import type { ComponentProps } from 'react';

import PropTypes from 'prop-types';
import React from 'react';

import BaseInstructions from './base';

const installCode = 'dotnet add package ReadMe.Metrics';
const getAPICallCode = () => `app.Use(async (context, next) =>
{
    HttpRequest req = context.Request;
    // User's API Key
    context.Items["apiKey"] = "owlbert-api-key";
    // Username to show in ReadMe's dashboard
    context.Items["label"] = "Owlbert";
    // User's email address
    context.Items["email"] = "owlbert@example.com";

    await next();
});

app.UseMiddleware<ReadMe.Metrics>();
`;

const getConfigCode = apiKey => `"readme": {
  "apiKey": "${apiKey}",
}
`;

interface DotNETInstructionsProps extends Omit<ComponentProps<typeof BaseInstructions>, 'instructions'> {
  selectedAPIKey: string;
}

function DotNETInstructions({ apiKeyDropdown, isQuickstart, log, selectedAPIKey }: DotNETInstructionsProps) {
  const apiCallCode = getAPICallCode();
  const configCode = getConfigCode(selectedAPIKey);
  const installPrompt = (
    <span>
      Install the{' '}
      <a href="https://www.nuget.org/packages/ReadMe.Metrics/" rel="noreferrer" target="_blank">
        ReadMe.Metrics
      </a>{' '}
      package from{' '}
      <a href="https://www.nuget.org/" rel="noreferrer" target="_blank">
        NuGet
      </a>
      .
    </span>
  );

  const configPrompt = (
    <span>
      Locate <code>appsettings.json</code> in your application’s root directory and add the following JSON. This
      authenticates your middleware with ReadMe.
    </span>
  );

  const apiCallPrompt = (
    <span>
      Once you have the package installed, and your ReadMe <code>apiKey</code> configured, you’ll need to add the
      middleware to <code>Program.cs</code> for .NET Core 6, or <code>Startup.cs</code> for older versions. For more
      context on each API call, populate API request attributes, such as a user’s email and API key.
    </span>
  );

  const instructions = [
    { code: installCode, prompt: installPrompt, language: 'csharp' },
    { code: configCode, prompt: configPrompt, language: 'json', includesAPIKey: true },
    { code: apiCallCode, prompt: apiCallPrompt, language: 'csharp' },
  ];

  return (
    <BaseInstructions
      apiKeyDropdown={apiKeyDropdown}
      instructions={instructions}
      isQuickstart={isQuickstart}
      log={log}
    />
  );
}

DotNETInstructions.propTypes = {
  apiKeyDropdown: PropTypes.element.isRequired,
  isQuickstart: PropTypes.bool,
  log: PropTypes.object,
  selectedAPIKey: PropTypes.string,
};

export default DotNETInstructions;
