import type { WebhookAction } from '@readme/iso';
import type { ComponentProps } from 'react';

import React from 'react';

import useReadmeApi from '@core/hooks/useReadmeApi';

import {
  ServerVariablesPreview,
  SecuritySchemePreview,
  CustomVariablesPreview,
} from '@routes/MyDevelopers/Setup/PersonalizedDocs/VariablesPreview';

import {
  ManageWebhookVariablesSection,
  ManageWebhookVariablesHeader,
  ManageWebhookVariablesTooltip,
  ManageWebhookVariablesTable,
} from './base';
import useManageWebhookVariables from './useManageWebhookVariables';

export * from './base';

interface Props extends Pick<ComponentProps<typeof ManageWebhookVariablesTable>, 'setHighlight'> {}

export function ManageWebhookServerVariables({ setHighlight }: Props) {
  const { version, variableDefaults, subdomain } = useManageWebhookVariables();
  const { server } = variableDefaults;

  return (
    <ManageWebhookVariablesSection>
      <ManageWebhookVariablesHeader
        description={
          <>
            These are pulled from your{' '}
            <ManageWebhookVariablesTooltip
              definitionLink={`/project/${subdomain}/v${version}/reference`}
              type="server"
              variableCount={server.length}
            >
              API Definitions
            </ManageWebhookVariablesTooltip>
          </>
        }
        title="Server Variables"
      />
      <ManageWebhookVariablesTable
        editable={false}
        isTableStriped
        preview={<ServerVariablesPreview />}
        setHighlight={setHighlight}
        type="server"
        variables={server}
      />
    </ManageWebhookVariablesSection>
  );
}

export function ManageWebhookSecurityVariables({ setHighlight }: Props) {
  const { version, variableDefaults, subdomain } = useManageWebhookVariables();
  const { security } = variableDefaults;

  return (
    <ManageWebhookVariablesSection>
      <ManageWebhookVariablesHeader
        description={
          <>
            These are pulled from your{' '}
            <ManageWebhookVariablesTooltip
              definitionLink={`/project/${subdomain}/v${version}/reference`}
              type="security"
              variableCount={security.length}
            >
              API Definitions
            </ManageWebhookVariablesTooltip>
          </>
        }
        title="Security Scheme"
      />
      <ManageWebhookVariablesTable
        editable={false}
        isTableStriped
        preview={<SecuritySchemePreview />}
        setHighlight={setHighlight}
        type="security"
        variables={security}
      />
    </ManageWebhookVariablesSection>
  );
}

interface VariableSuggestion {
  action: WebhookAction;
  created_at: string;
  id: string;
  name: string;
}

export function ManageWebhookCustomVariables({ setHighlight }: Props) {
  const { variableDefaults, subdomain } = useManageWebhookVariables();
  const { custom } = variableDefaults;

  const { data: variableSuggestionsResponse } = useReadmeApi<{ data: VariableSuggestion[] }>(
    `${subdomain}/api-next/v2/webhooks/variable-suggestions`,
  );
  const variableSuggestions = variableSuggestionsResponse?.data || [];

  return (
    <ManageWebhookVariablesSection>
      <ManageWebhookVariablesHeader description="Manually configured variables" title="Custom Variables" />
      <ManageWebhookVariablesTable
        editable={true}
        isTableStriped
        preview={<CustomVariablesPreview />}
        setHighlight={setHighlight}
        suggestions={variableSuggestions}
        type="custom"
        variables={custom}
      />
    </ManageWebhookVariablesSection>
  );
}
