import qs from 'qs';
import React, { useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';
import type { MetricsFilters } from '@core/types/metrics';
import { smartCaps } from '@core/utils/metrics';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuItem, MenuDivider, MenuHeader } from '@ui/Menu';
import { groupByFilterDisplayName } from '@ui/Metrics/Graph/utils';

import styles from './style.module.scss';

const groups = Object.entries({
  user: ['company', 'email', 'eventName'],
  documentation: ['path', 'searchTerm', 'type', 'vote'],
  request: ['groupId', 'groupEmail', 'url', 'useragent'],
  response: ['status', 'statusCategory'],
});

interface Props {
  /** Whether filter is being used in Superhub (so dropdown can have dark-on-black styling) */
  isSuperhub?: boolean;
  /** Optional override for 'None' label */
  noneOverride?: string;
}

export const useGetActiveGroup = () => {
  const [metricsPageConfig, graphQuery] = useMetricsStore(s => [s.metricsPageConfig, s.graphQuery]);

  const base = metricsPageConfig.graph.groupByBase || false;
  const groupBy = ([] as string[]).concat(graphQuery?.groupBy || []);

  // Find first group that isn't base group
  return groupBy.find(val => val !== base) || '';
};

const FilterGroup = ({ isSuperhub, noneOverride }: Props) => {
  const bem = useClassy(styles, 'MetricsFilter-FilterGroup');
  const [updateQuery, resetQuery, metricsPageConfig] = useMetricsStore(s => [
    s.updateQuery,
    s.resetQuery,
    s.metricsPageConfig,
  ]);

  const activeGroup = useGetActiveGroup();

  const isSectionEnabled = useCallback(
    (options = []) => {
      const allowedGroups = metricsPageConfig.graph?.groups || [];
      return options.some(o => allowedGroups.includes(o));
    },
    [metricsPageConfig.graph?.groups],
  );

  const handleReset = useCallback(() => {
    // Reset the query to either the noGroupByQuery (if it exists) or the default query
    const { noGroupByQuery, query } = metricsPageConfig.graph;

    updateQuery('graphQuery', qs.parse(noGroupByQuery || query) as Partial<MetricsFilters>);
    resetQuery('tableQuery');
  }, [metricsPageConfig.graph, resetQuery, updateQuery]);

  const handleClick = useCallback(
    group => {
      const base = metricsPageConfig.graph.groupByBase || false;

      // Set ?groupBy param, and optionally append any base groups
      updateQuery('graphQuery', {
        groupBy: [group, base].filter(Boolean),
      });
      resetQuery('tableQuery');
    },
    [metricsPageConfig.graph.groupByBase, resetQuery, updateQuery],
  );

  const allowedGroups = metricsPageConfig.graph?.groups;
  const isActive = activeGroup && activeGroup !== 'period';
  const visibleGroups = groups.filter(([, options]) => {
    const sectionEnabled = isSectionEnabled(options);
    return allowedGroups && sectionEnabled;
  });

  return (
    <Dropdown align="bottom" className={bem('&')} justify="start" sticky>
      <Button
        className={bem(isSuperhub && '-btn-dark-on-black')}
        dropdown
        kind={isActive ? 'primary' : 'secondary'}
        outline={!isActive}
        size="sm"
      >
        <Flex align="center" gap="xs">
          <span className="Filter-label">
            Group By {!!isActive && groupByFilterDisplayName(smartCaps(activeGroup))}
          </span>
        </Flex>
      </Button>
      <Menu>
        {visibleGroups.map(([section, options], index) => {
          return (
            <React.Fragment key={`FilterGroup-${section}`}>
              <MenuHeader>{smartCaps(section)}</MenuHeader>
              {options.map(
                f =>
                  allowedGroups.includes(f) && (
                    <MenuItem
                      key={`FilterGroup-${section}-${f}`}
                      active={activeGroup === f}
                      onClick={() => handleClick(f)}
                    >
                      {groupByFilterDisplayName(smartCaps(f))}
                    </MenuItem>
                  ),
              )}
              {!noneOverride && index === visibleGroups.length - 1 && <MenuDivider />}
            </React.Fragment>
          );
        })}
        <MenuItem active={!allowedGroups.includes(activeGroup)} onClick={handleReset}>
          {noneOverride || 'None'}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default FilterGroup;
