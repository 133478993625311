import type { ProjectSettingsFormValues } from '../Context';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Tooltip from '@ui/Tooltip';

import styles from './index.module.scss';

export default function GlossaryTooltip({
  children,
  term,
}: {
  children: React.ReactNode;
  term: NonNullable<ProjectSettingsFormValues['glossary']>[0];
}) {
  const bem = useClassy(styles, 'Glossary');

  return (
    <Tooltip
      arrow={false}
      content={
        <>
          <strong>
            <em>{term.term}</em>
          </strong>
          <span> - {term.definition}</span>
        </>
      }
      offset={[0, 5]}
      placement="bottom-start"
    >
      <span className={bem('-example')}>{children}</span>
    </Tooltip>
  );
}
