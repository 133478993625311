import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

const LayoutLinks = ({ style }: SharedSvgProps) => (
  <svg
    className={styles.Preview}
    fill="none"
    style={style}
    viewBox="0 0 92 62"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2_2754)">
      <path d="M0 0h92v62H0z" fill="url(#paint0_linear_2_2754)" />
      <g clipPath="url(#clip1_2_2754)">
        <rect fill="#fff" height="58" rx="3" width="86" x="15" y="15" />
        <mask fill="#fff" id="path-2-inside-1_2_2754">
          <path d="M15 15h86v14H15V15Z" />
        </mask>
        <path d="M15 15h86v14H15V15Z" fill="var(--Preview-primary-color)" />
        <path
          d="M15 15h86-86Zm86 15H15v-2h86v2Zm-86-1V15v14Zm86-14v14-14Z"
          fill="#000"
          fillOpacity=".1"
          mask="url(#path-2-inside-1_2_2754)"
        />
        <path d="M15 29h86v11H15z" fill="var(--Preview-primary-color)" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="3" rx="1.5" width="12" x="20" y="33" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="3" rx="1.5" width="12" x="36" y="33" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="3" rx="1.5" width="12" x="52" y="33" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="3" rx="1.5" width="12" x="68" y="33" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="3" rx="1.5" width="12" x="84" y="33" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2_2754">
        <path d="M0 0h92v62H0z" fill="#fff" />
      </clipPath>
      <clipPath id="clip1_2_2754">
        <rect fill="#fff" height="58" rx="3" width="86" x="15" y="15" />
      </clipPath>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_2_2754"
        x1="75.414"
        x2="95.644"
        y1="14.208"
        y2="14.293"
      >
        <stop stopColor="#AE9ED4" />
        <stop offset="1" stopColor="#8AB0FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default LayoutLinks;
