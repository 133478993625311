import { WebhookAction } from '@readme/iso';
import { useContext } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import { useProjectStore } from '@core/store';

export default function useIsWebhookSetupComplete() {
  const { project: projectFromContext } = useContext(ProjectContext);
  const [webhooks] = useProjectStore(store => [store.data.webhooks]);

  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  if (isHub) {
    // TODO we don't have the `webhooks` property available on `parent` to support enterprise
    return !!webhooks?.find(webhook => webhook.action === WebhookAction.LOGIN);
  }

  const currentWebhookUrl = projectFromContext.webhooks?.find(hook => hook.action === 'login')?.url || '';
  return projectFromContext.parent
    ? projectFromContext.parent.webhookEnabled
    : !!currentWebhookUrl || projectFromContext.webhookEnabled;
}
