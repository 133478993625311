import React, { useContext, useEffect, useCallback, useState } from 'react';

import { ProjectContext } from '@core/context';
import { useReadmeApiWrite } from '@core/hooks/useReadmeApi';
import { getDecoratedHeaders } from '@core/utils/makeFetch';

import Notification, { notify } from '@ui/Notification';

export default function useToggleThumbs() {
  const { project } = useContext(ProjectContext);
  // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
  const { apiKey, metrics, mutate, subdomain } = project;
  const [endPoint, setEndpoint] = useState(null);

  const options = {
    headers: {
      Authorization: `Basic ${Buffer.from(`${apiKey}:`).toString('base64')}`,
      ...getDecoratedHeaders(),
    },
    method: 'post',
  };

  const { data, error, isLoading } = useReadmeApiWrite(endPoint, options);

  useEffect(() => {
    const isThumbsEnabled = !metrics.thumbsEnabled;
    if (data && data.message === 'Ok') {
      mutate('metrics.thumbsEnabled', isThumbsEnabled);
      notify(
        <Notification kind="success">
          User voting {isThumbsEnabled ? 'successfully enabled!' : 'has been disabled.'}
        </Notification>,
      );
      setEndpoint(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      notify(
        <Notification kind="error">
          There was a problem {!metrics.thumbsEnabled ? 'disabling' : 'enabling'} user voting.
        </Notification>,
      );
      setEndpoint(null);
    }
  }, [error, metrics.thumbsEnabled]);

  const toggle = useCallback(() => {
    setEndpoint(`api/projects/${subdomain}/toggle-thumbs/${!metrics.thumbsEnabled}`);
  }, [metrics.thumbsEnabled, subdomain]);

  return { toggle, response: data, isLoading };
}
