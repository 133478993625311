import React, { useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Title from '@ui/Title';

import classes from './style.module.scss';

const UsersTitle = () => {
  const bem = useClassy(classes, 'MyDevelopersTitle');

  const [userEmail] = useMetricsStore(s => [s.query.email]);

  const { buildUrl, navigate } = useMyDevelopers();

  const navigateToDefaultView = useCallback(() => {
    const url = buildUrl();
    navigate(url);
  }, [buildUrl, navigate]);

  return (
    <Flex align="center" gap="xs">
      <Button kind="secondary" onClick={navigateToDefaultView} size="sm" text>
        <Icon name="arrow-left" />
      </Button>
      <Title className={bem('-email')}>{userEmail || 'Users Page'}</Title>
    </Flex>
  );
};

export default UsersTitle;
