import { createColumnHelper } from '@tanstack/react-table';

import { Column } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import type { DocMetricsData } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import { CompanyCell, TimeCell, TextCell } from '@ui/ReactTable/components/Cells';

const columnHelper = createColumnHelper<DocMetricsData>();

export default {
  title: 'New Users',
  endpoint: 'users',
  exportSource: 'new_users',
  fallback: null,
  filters: ['Group', 'User'],
  graph: {
    type: 'line',
    query: 'groupBy=period',
    groups: ['company', 'eventName'],
    groupByBase: 'period',
  },
  shortcuts: {
    'Active This Week': { groupBy: 'period', rangeLength: 7, resolution: 'day' },
    'Active Week Over Week': {
      groupBy: 'period',
      subset: 'new_users',
      compare: true,
      rangeLength: 7,
      resolution: 'day',
    },
  },
  table: {
    query: '',
    defaultSort: {
      column: Column.CreatedAt,
      direction: 'desc',
    },
    columns: [
      columnHelper.accessor(Column.CreatedAt, {
        enableHiding: false,
        header: 'First Seen',
        cell: ({ getValue }) => TimeCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Email, {
        enableHiding: false,
        header: columnHeaders[Column.Email],
        cell: ({ getValue }) => TextCell({ value: getValue() || '', copyable: true }),
      }),
      columnHelper.accessor(Column.Company, {
        header: columnHeaders[Column.Company],
        cell: ({ getValue }) => CompanyCell({ value: getValue() }),
        minSize: 120,
      }),
      columnHelper.accessor(Column.EventName, {
        header: columnHeaders[Column.EventName],
      }),
    ],
    prefsName: TablePrefsKey.MetricsNewUsers,
  },
};
