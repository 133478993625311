import React from 'react';

import { NavLink as Link } from '@Dash/Link';

import SpeechBubble from '@ui/SpeechBubble';

import classes from './style.module.scss';
import UserEvent from './UserEvent';

interface Props {
  children: React.ReactNode;
  href: string;
  logo?: string;
  target?: 'blank' | 'self';
  user: {
    email: string;
    name: string;
  };
}

const FeedComment = ({ children, href, logo, target = 'self', user }: Props) => {
  const LinkNode = () =>
    target === 'blank' ? (
      <a className={classes['Feed-Comment_link']} href={href} rel="noreferrer" target="_blank">
        <SpeechBubble content={children} />
      </a>
    ) : (
      <Link className={classes['Feed-Comment_link']} to={href}>
        <SpeechBubble content={children} />
      </Link>
    );

  return (
    <UserEvent align="end" className={classes['Feed-Comment-avater']} logo={logo} user={user}>
      {href ? <LinkNode /> : <SpeechBubble content={children} />}
    </UserEvent>
  );
};

export default React.memo(FeedComment);
