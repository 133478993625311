import type useSaveWebhook from '../useSaveWebhook';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import SetupAWSPersonalizationForm from '@routes/MyDevelopers/Setup/PersonalizedDocs/SetupAWSPersonalizationForm';

import Accordion, { AccordionPanel, AccordionToggle } from '@ui/Accordion';
import Flex from '@ui/Flex';

import { WebhookManualInstructions } from '../WebhookInstructions';

import styles from './style.module.scss';

type UseSaveWebhookArgs = Parameters<typeof useSaveWebhook>;

interface AwsWebhookIntegrationProps {
  onSaveWebhook: UseSaveWebhookArgs[1];
}

export default function AwsWebhookIntegration({ onSaveWebhook }: AwsWebhookIntegrationProps) {
  const bem = useClassy(styles, 'AwsWebhookIntegration');

  return (
    <Accordion headingLevel={2}>
      <AccordionPanel initiallyOpen>
        <AccordionToggle>
          <Flex gap="xs" layout="col">
            <span className={bem('-title')}>Option 1: ReadMe Managed</span>
            <p>Let us manage AWS Gateway API keys for you</p>
          </Flex>
        </AccordionToggle>
        <SetupAWSPersonalizationForm inputSize="md" isButtonOutline onSaveWebhook={onSaveWebhook} />
      </AccordionPanel>
      <AccordionPanel>
        <AccordionToggle>
          <Flex gap="xs" layout="col">
            <span className={bem('-title')}>Option 2: Custom setup</span>
            <p>Manage your own API keys</p>
          </Flex>
        </AccordionToggle>
        <WebhookManualInstructions onSaveWebhook={onSaveWebhook} />
      </AccordionPanel>
    </Accordion>
  );
}
