import { useState } from 'react';

/**
 * Manages an active index correlating to a list of values.
 *
 * The Input within a ComboBox is included in the page Tab sequence,
 * but the pop-up, and pop-up menu descendants should be excluded from the page Tab sequence.
 * We use up/down arrows to navigate within the pop-up menu, and `aria-activedescendant`
 * to manage visible focus within the pop-up menu for assistive technologies. So we need to
 * track the index of the current active descendant.
 */

export default function useActiveIndex(_list: unknown[]) {
  const [list, setList] = useState(_list);
  const [activeIdx, setActiveIdx] = useState<number | null>(null);
  const lastIdx = list.length - 1;

  const updateActiveIndexList = (updatedList: unknown[]) => setList(updatedList);

  const clearActiveIndex = () => setActiveIdx(null);

  const goToFirst = () => setActiveIdx(0);

  const goToLast = () => setActiveIdx(lastIdx);

  const gotoNext = () => {
    // move down in the list
    // if you've reached the end of the list, go to the beginning
    setActiveIdx(idx => (idx != null ? (idx === lastIdx ? 0 : idx + 1) : null));
  };

  const goToPrevious = () => {
    // move up in the list
    // if you've reached the beginning of the list, go to the end
    setActiveIdx(idx => (idx != null ? (idx === 0 ? lastIdx : idx - 1) : null));
  };

  return { activeIdx, goToFirst, goToLast, gotoNext, goToPrevious, updateActiveIndexList, clearActiveIndex };
}
