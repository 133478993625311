import { availableWebhookTargets } from '@readme/metrics-sdk-snippets';
import React, { useState } from 'react';

import type { MetricsApiKeysSupportedLanguagesType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import WebhookEnterpriseConfig from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookEnterpriseConfig';

import SetupLanguagePicker from '@ui/Metrics/SetupLanguagePicker';

import keyImg from '../images/key.webp';
import PersonalizedDocsLayout from '../Layout';

import styles from './style.module.scss';
import SetupWebhookStep from './WebhookStep';

type SetupApiKeysStep = 'pick-language' | 'setup-webhook';
type SupportedLanguages = `${MetricsApiKeysSupportedLanguagesType}`[];

export default function PersonalizedDocsSetup() {
  const bem = useClassy(styles, 'SetupPersonalizedDocs');
  const [currentStep, setCurrentStep] = useState<SetupApiKeysStep>('pick-language');

  const availableLanguages: SupportedLanguages = availableWebhookTargets().map(target => target.key);

  const parent = useProjectStore(s => s.data.parent);
  const isEnterprise = !!parent;

  const handleLanguageChange = () => {
    setCurrentStep('setup-webhook');
  };

  if (isEnterprise) {
    return (
      <PersonalizedDocsLayout>
        <div className={bem('-enterprise')}>
          <WebhookEnterpriseConfig parentSubdomain={parent} />
        </div>
      </PersonalizedDocsLayout>
    );
  }

  return (
    <PersonalizedDocsLayout>
      <div className={bem('-instructions')}>
        {currentStep === 'pick-language' && (
          <SetupLanguagePicker
            availableLanguages={availableLanguages}
            onChangeLanguage={handleLanguageChange}
            title={
              <>
                Enhance your API Reference Section with <span style={{ color: 'var(--green)' }}>API Keys,</span>
                <span style={{ color: 'var(--green)' }}> Server Variables, </span> & More
              </>
            }
            titleGraphic={
              <div className={bem('-key-graphic')}>
                <img alt="Personalized Docs Key" className={bem('-image')} src={keyImg} />
              </div>
            }
            titleLevel={4}
          />
        )}
        {currentStep === 'setup-webhook' && <SetupWebhookStep onBack={() => setCurrentStep('pick-language')} />}
      </div>
    </PersonalizedDocsLayout>
  );
}
