import { createColumnHelper } from '@tanstack/react-table';

import { Column } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import type { MetricsPageConfig } from '@core/store/Metrics/types';
import type { APIMetricsData } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import { APIKeyCell, MethodCell, StatusCell, TextCell, TimeCell, UserAgentCell } from '@ui/ReactTable/components/Cells';

const columnHelper = createColumnHelper<APIMetricsData>();

export const UserPageConfig = {
  title: 'API Calls',
  type: 'users',
  endpoint: 'requests',
  exportSource: 'request',
  fallback: 'metrics',
  filters: ['Group', 'Method', 'Status', 'User'],
  graph: {
    type: 'line',
    query: 'groupBy=period',
    groups: ['groupId', 'useragent', 'status', 'statusCategory', 'url'],
    groupByBase: 'period',
  },
  table: {
    query: '',
    defaultSort: {
      column: Column.CreatedAt,
      direction: 'desc',
      query: '',
    },
    columns: [
      columnHelper.accessor(Column.CreatedAt, {
        enableHiding: false,
        header: columnHeaders[Column.CreatedAt],
        cell: ({ getValue }) => TimeCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Status, {
        header: columnHeaders[Column.Status],
        cell: ({ getValue }) => StatusCell({ value: getValue() }),
        maxSize: 70,
      }),
      columnHelper.accessor(Column.Method, {
        header: columnHeaders[Column.Method],
        cell: ({ getValue }) => MethodCell({ value: getValue() }),
        maxSize: 70,
      }),
      columnHelper.accessor(Column.Path, {
        enableHiding: false,
        header: columnHeaders[Column.Path],
        minSize: 200,
        cell: ({ row }) => {
          const { path, url } = row.original;
          return TextCell({ tooltipValue: url, value: path });
        },
      }),
      columnHelper.accessor(Column.GroupId, {
        header: columnHeaders[Column.GroupId],
        minSize: 120,
        cell: ({ getValue }) => APIKeyCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.OperationId, {
        header: columnHeaders[Column.OperationId],
      }),
      columnHelper.accessor(Column.UserAgent, {
        header: columnHeaders[Column.UserAgent],
        cell: ({ getValue }) => UserAgentCell({ value: getValue() }),
      }),
    ],
    prefsName: TablePrefsKey.MetricsApiCalls,
    visibility: {
      [Column.OperationId]: false,
      [Column.UserAgent]: false,
    },
  },
} as MetricsPageConfig;
