import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

const HeaderTypeSolid = ({ style }: SharedSvgProps) => (
  <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3_873)">
      <path d="M0 0h92v62H0z" fill="url(#paint0_linear_3_873)" />
      <g clipPath="url(#clip1_3_873)">
        <rect fill="#F0F0F0" height="62" rx="4" width="118" x="9" y="15" />
        <mask fill="#fff" id="path-2-inside-1_3_873">
          <path d="M9 19a4 4 0 0 1 4-4h110a4 4 0 0 1 4 4v16H9V19Z" />
        </mask>
        <path d="M9 19a4 4 0 0 1 4-4h110a4 4 0 0 1 4 4v16H9V19Z" fill="var(--Preview-primary-color)" />
        <path
          d="M9 15h118H9Zm118 21H9v-2h118v2ZM9 35V15v20Zm118-20v20-20Z"
          fill="#000"
          fillOpacity=".1"
          mask="url(#path-2-inside-1_3_873)"
        />
        <path d="M9 35h118v14H9z" fill="var(--Preview-primary-color)" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="4" rx="2" width="18" x="19" y="40" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="4" rx="2" width="18" x="45" y="40" />
        <rect fill="var(--Preview-header-link-color)" fillOpacity=".8" height="4" rx="2" width="18" x="71" y="40" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3_873">
        <path d="M0 0h92v62H0z" fill="#fff" />
      </clipPath>
      <clipPath id="clip1_3_873">
        <rect fill="#fff" height="62" rx="4" width="118" x="9" y="15" />
      </clipPath>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3_873"
        x1="75.414"
        x2="95.644"
        y1="14.208"
        y2="14.293"
      >
        <stop stopColor="#AE9ED4" />
        <stop offset="1" stopColor="#8AB0FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default HeaderTypeSolid;
