import type { ProjectSchema } from '@readme/backend/models/project/types';

import React, { useContext, useEffect, useMemo, useState } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext, VersionContext } from '@core/context';
import useReadmeApi, { useReadmeApiWrite } from '@core/hooks/useReadmeApi';
import type { MetricsSDKSetupLog } from '@core/types/metrics';
import { getDecoratedHeaders } from '@core/utils/makeFetch';

import classes from '@routes/Dash/Project/Setup/style.module.scss';
import useMetricsWebSocket from '@routes/MyDevelopers/Setup/ApiLogs/useMetricsWebSocket';

import type { TokenObject } from '@ui/API/APIKeyDropdown';
import APIKeyDropdown, { UNSELECTED_LABEL } from '@ui/API/APIKeyDropdown';
import Flex from '@ui/Flex';

interface Args {
  isQuickstart?: boolean;
  onLogReceived?: (log: MetricsSDKSetupLog) => void;
}

const useMetricsOnboarding = ({ isQuickstart, onLogReceived }: Args = {}) => {
  const { version } = useContext(VersionContext);
  const { project } = useContext(ProjectContext);
  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const { subdomain } = project as ProjectSchema;

  const [log, setLog] = useState<MetricsSDKSetupLog | null>(null);

  useEffect(() => {
    if (log) {
      onLogReceived?.(log);
    }
  }, [log, onLogReceived]);

  /**
   * Start of API Key Management
   */
  const isHub = name === 'Hub';
  const apiKeysUrl = `${subdomain}/api-next/v2/projects/me/apikeys`;

  const {
    data: apiKeysData,
    mutate: mutateRead,
    isLoading: isLoadingTokens,
    error: apiKeyError,
  } = useReadmeApi<{ data: TokenObject[] }>(apiKeysUrl);

  const apiKeyOptions = apiKeysData?.data;

  // If the modal is open and the user has no tokens, make one for them.
  const shouldCreateNewToken = !!(apiKeyOptions && !apiKeyOptions.length && !apiKeyError);

  const {
    data: newTokenData,
    // error: createTokenError,
    // isLoading: isCreateTokenLoading,
    reset: resetCreateToken,
  } = useReadmeApiWrite(
    apiKeysUrl,
    {
      method: 'POST',
      headers: getDecoratedHeaders(),
      body: JSON.stringify({ label: 'Developer Dashboard' }),
    },
    shouldCreateNewToken,
  );

  // If the user created tokens, clean up everything back to the initial state
  if (newTokenData) {
    mutateRead();
    resetCreateToken();
  }

  const [selectedAPIKey, setSelectedAPIKey] = useState('');

  // Once the tokens are loaded, if the user hadn't already selected one, preselect a token
  if (apiKeyOptions && !isLoadingTokens && !selectedAPIKey) {
    if (apiKeyOptions.length) {
      // If the user has tokens, preselect the first one
      setSelectedAPIKey(apiKeyOptions[0].token);
    } else {
      // If the user has no tokens, use the UNSELECTED_LABEL value
      setSelectedAPIKey(UNSELECTED_LABEL);
    }
  }

  // If we previously selected UNSELECTED due to not having a token, but the user now has one because we created one
  //    select that instead
  if (selectedAPIKey === UNSELECTED_LABEL && !!apiKeyOptions?.length) {
    setSelectedAPIKey(apiKeyOptions[0].token);
  }

  const apiKeyDropdown = useMemo(() => {
    return (
      <Flex align="center" justify="end">
        <APIKeyDropdown
          apiKeyOptions={apiKeyOptions || []}
          className={classes['Setup-apiKey']}
          isHub={isHub}
          onChange={setSelectedAPIKey}
          selectedAPIKey={selectedAPIKey}
          subdomain={subdomain}
          version={version}
        />
      </Flex>
    );
  }, [apiKeyOptions, isHub, selectedAPIKey, subdomain, version]);

  /**
   * End of API Key Management
   */

  useMetricsWebSocket(selectedAPIKey, setLog, isQuickstart);

  return {
    apiKeyDropdown,
    selectedAPIKey,
    log,
  };
};

export default useMetricsOnboarding;
