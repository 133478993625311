import { useContext, useEffect } from 'react';

import { ConfigContext, ProjectContext, UserContext } from '@core/context';

import useUniqueId from '../useUniqueId';

const useWootric = () => {
  const { createdAt, isGod, user } = useContext(UserContext);
  const { project } = useContext(ProjectContext);
  const uid = useUniqueId('useWootric');
  const {
    wootric: { enabled, accountToken },
  } = useContext(ConfigContext);

  useEffect(() => {
    if (enabled) {
      window.wootric_survey_immediately = true;
      window.wootricSettings = {
        account_token: accountToken,
        aria: true,
        created_at: Math.floor(new Date(createdAt).getTime() / 1000),
        email: user?.email || 'anonymous',
        scale_color: 'gradient',
        properties: {
          name: user?.name,
          plan: project?.plan,
          subdomain: project?.subdomain,
          superhub: project?.flags?.superHub,
        },
      };

      // Beacon
      const beacon = document.createElement('script');
      beacon.type = 'text/javascript';
      beacon.id = uid('wootric-beacon');
      beacon.async = true;
      beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
      beacon.onload = () => window.wootric('run');

      if (!document.getElementById('wootric-beacon')) {
        document.body.appendChild(beacon);
      }
    }
  }, [accountToken, createdAt, enabled, isGod, project, uid, user]);
};

export default useWootric;
