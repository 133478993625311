import type { UserMetricDataProps } from '../types/props';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Spinner from '@ui/Spinner';
import Truncate from '@ui/Truncate';

import classes from './style.module.scss';

interface UserMetricProps {
  className?: string;
  data?: UserMetricDataProps[];
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  isSuperhub?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: { btnText?: string; errorText: string; title: string };
}

function UserMetric({ isLoading, className, data, iconPosition = 'left', isSuperhub, text, onClick }: UserMetricProps) {
  const bem = useClassy(classes, 'UserMetric');

  return (
    <div className={bem('&', className)}>
      <h1 className={bem('&-title')}>{text.title || 'Error'}</h1>
      {isLoading ? (
        <Spinner size="md" />
      ) : (
        <>
          {data?.length ? (
            <>
              <div>
                {data?.map((d, index) => (
                  <Flex key={`${index}-${d.value}-${d.label}`} className={bem('&-row')} gap={0}>
                    <Flex
                      align="center"
                      className={bem('&-label', iconPosition === 'right' && '&-label-icon-right')}
                      gap="xs"
                      inline="true"
                      justify="start"
                    >
                      {d.icon}
                      <Truncate maxWidth="15ch">{d.label as string}</Truncate>
                    </Flex>
                    <span className={bem('&-value')}>{d.value}</span>
                  </Flex>
                ))}
              </div>
              {!!text.btnText && (
                <Button
                  className={bem('&-btn')}
                  kind={isSuperhub ? 'success' : 'primary'}
                  onClick={onClick}
                  size="sm"
                  text
                >
                  <Flex align="center" gap="xs" justify="start">
                    {text.btnText}
                    <Icon name="bar-chart-2" />
                  </Flex>
                </Button>
              )}
            </>
          ) : (
            <div className={bem('&-label')}>{text.errorText}</div>
          )}
        </>
      )}
    </div>
  );
}

export default UserMetric;
