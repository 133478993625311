import type { FormGateContextValue } from './Context/FormGateContext';

import React from 'react';

import ConnectFormToProjectStore from './ConnectFormToProjectStore';
import ProjectSettingsFormContent from './Content';
import ProjectSettingsFormContext from './Context';

interface ProjectSettingsFormProps {
  children: React.ReactNode;
  /**
   * Determines if the form should be gated by the project's plan.
   */
  formGate?: FormGateContextValue;
}

/**
 * Sets up the React Hook Form instance that manages our project settings
 * forms. Renders it with a `FormProvider` that allows all child components to
 * access the form context and all of its properties.
 *
 * Child components should call `useProjectSettingsFormContext()` hook to access the
 * form context that is properly typed.
 *
 * @link https://react-hook-form.com/docs/formprovider
 */
export default function ProjectSettingsForm({ children, formGate = 'none' }: ProjectSettingsFormProps) {
  return (
    <ProjectSettingsFormContext formGate={formGate}>
      <ConnectFormToProjectStore>
        <ProjectSettingsFormContent>{children}</ProjectSettingsFormContent>
      </ConnectFormToProjectStore>
    </ProjectSettingsFormContext>
  );
}
