import React, { useCallback } from 'react';

import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore } from '@core/store';
import type { KeyInsightsResponse } from '@core/types/metrics';
import cap from '@core/utils/capitalize';
import prettyNumber from '@core/utils/prettyNumber';

import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import APIKeyDropdown from '@ui/Metrics/APIKeyDropdown';
import UserAgentPlatformGraphic from '@ui/Metrics/UserAgentPlatformGraphic';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';
import Spinner from '@ui/Spinner';

import styles from './style.module.scss';

const APIKeyInsightsTitle = () => {
  const bem = useClassy(styles, 'MyDevelopersTitle');

  const [activeAPIKey, rangeQueryParams, selectedDateRangeKey, generateRequestPath, isReadyToFetch] = useMetricsStore(
    s => [
      s.myDevelopers.activeAPIKey,
      s.myDevelopers.getRangeQueryParams(),
      s.myDevelopers.getSelectedDateRangeKey(),
      s.myDevelopers.generateRequestPath,
      s.myDevelopers.getReadyToFetch(),
    ],
  );

  const { buildUrl, navigate } = useMyDevelopers();

  const { data, isLoading } = useMetricsAPI<KeyInsightsResponse>(
    generateRequestPath('developers/keyInsights', rangeQueryParams),
    isReadyToFetch,
  );

  const { apiKeys, company, totalRequests, userAgents } = data || {};

  const navigateToAPIKey = useCallback(
    (key: string) => {
      const url = buildUrl({ type: MyDevelopersSubrouteType.Key, identifier: key });
      navigate(url);
    },
    [buildUrl, navigate],
  );

  const navigateToDefaultView = useCallback(() => {
    const url = buildUrl();
    navigate(url);
  }, [buildUrl, navigate]);

  if (isLoading) {
    return (
      <div className={bem('-insights-loading')}>
        <Spinner size="sm" />
      </div>
    );
  }

  if (!data || data?.apiKeys.length === 0) {
    return (
      <Flex align="center" gap="xs">
        <Button kind="secondary" onClick={navigateToDefaultView} size="sm" text>
          <Icon name="arrow-left" />
        </Button>
        <div className={bem('-insights-error')}>
          <ObfuscatedAPIKey
            allowCopy={false}
            allowExpansion={false}
            apiKey={activeAPIKey}
            conceal="before"
            displayLength={4}
          />
        </div>
      </Flex>
    );
  }

  const overTimePeriod =
    selectedDateRangeKey === 'day'
      ? 'Requests Today'
      : `Requests This ${selectedDateRangeKey === 'custom' ? 'Period' : cap(selectedDateRangeKey)}`;

  const menuHeader = company?.name ? `${company?.name} API Keys` : 'API Keys';

  return (
    <Flex align="start" gap="xs">
      <Button kind="secondary" onClick={navigateToDefaultView} size="sm" text>
        <Icon name="arrow-left" />
      </Button>
      <Flex align="start" gap="sm" layout="col">
        <Flex align="center" gap="xs">
          <APIKeyDropdown
            activeAPIKey={activeAPIKey}
            apiKeys={apiKeys}
            menuHeader={menuHeader}
            onClick={navigateToAPIKey}
          />
          <Flex align="center" className={bem('-insights-company')} gap="xs">
            {!!company?.logo && <Avatar className={bem('-insights-companyLogo')} imgSrc={company?.logo} size="xs" />}
            {company?.name ? <span>{company?.name}</span> : <span>&mdash;</span>}
          </Flex>
        </Flex>
        <Flex align="center" gap="sm">
          <Flex className={bem('-insights-requests')} gap="xs">
            <Icon color="gray70" name="calendar" size="sm" strokeWeight={3} />
            {prettyNumber(totalRequests)} {overTimePeriod}
          </Flex>
          <Flex className={bem('-insights-userAgents')} gap="sm">
            {userAgents?.map((ua, i: number) => (
              <Flex key={i} align="center" gap="xs">
                <UserAgentPlatformGraphic platform={ua.platform} />
                <span>{ua.percent}%</span>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default APIKeyInsightsTitle;
