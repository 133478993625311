import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore } from '@core/store';

import SettingsDropdown from '@routes/Dash/Project/Metrics/SettingsDropdown';

import Banner from '@ui/Banner';
import Button from '@ui/Button';

import classes from './style.module.scss';

interface Props {
  isOverview: boolean;
}

const MetricsBanner = ({ isOverview }: Props) => {
  const bem = useClassy(classes, 'MetricsBanner');

  const [developmentData, isSetupComplete] = useMetricsStore(s => [s.developmentData, s.isSetupComplete]);

  const { buildUrl } = useMyDevelopers();

  const url = buildUrl();

  // If customer hasn't completed API Logs setup, prompt them to setup Metrics
  if (!isOverview && !isSetupComplete) {
    return (
      <Banner className={bem('&')} icon="icon-api-metrics" isFixed={false} kind="warn">
        Set up Metrics to understand how your customers use your API.
        <Button className={bem('-ctaButton')} outline size="sm" to={url}>
          Get Started
        </Button>
      </Banner>
    );
  }

  // If development flag is enabled, show its accompanying banner
  if (developmentData) {
    return (
      <Banner className={bem('&')} icon="icon-api-metrics" isFixed={false} kind="alert">
        You’re looking at metrics marked as development data.
        <SettingsDropdown
          includeUpdateSection={false}
          triggerBtn={
            <Button className={bem('-ctaButton', '-ctaButton_alert')} dropdown outline size="sm">
              Change
            </Button>
          }
        />
      </Banner>
    );
  }

  return null;
};

export default MetricsBanner;
