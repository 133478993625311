import qs from 'qs';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useMetricsStore } from '@core/store';
import classy from '@core/utils/classy';

import { UserPageConfig } from '@routes/MyDevelopers/Users/config';

import Button from '@ui/Button';

import useAsyncRouteState from '../hooks/useAsyncRouteState';

import { useGetActiveGroup } from './Group';
import classes from './style.module.scss';

function ResetFilters() {
  const [updateMetricsPageConfig, updateQuery, query] = useMetricsStore(s => [
    s.updateMetricsPageConfig,
    s.updateQuery,
    s.query,
  ]);
  const { type, identifier, slug } = useParams();

  // If the route is a user page, use the UserPageConfig as the default state rather than the APIConfig
  const isUsersMetricsPage = type === 'user' && !!identifier;
  const apiConfig = useAsyncRouteState(slug);
  const routeDefaultState = isUsersMetricsPage ? UserPageConfig : apiConfig;

  // Default graph query groupBy
  const defaultQuery = useMemo(
    () => (routeDefaultState ? qs.parse(routeDefaultState.graph.query) : null),
    [routeDefaultState],
  );

  const groupByBase = useMemo(() => routeDefaultState?.graph.groupByBase, [routeDefaultState]);

  const activeGroup = useGetActiveGroup();

  // Does the route use a group filter?
  const hasGroupFilter = useMemo(() => routeDefaultState?.filters.includes('Group'), [routeDefaultState?.filters]);

  // Is the active group part of the default query?
  const isActiveGroupInDefault = useMemo(
    () => (defaultQuery ? Object.values(defaultQuery).flat().includes(activeGroup) : false),
    [activeGroup, defaultQuery],
  );

  // Is there a default group that isn't groupByBase?
  const isGroupedByDefault = useMemo(() => {
    if (!defaultQuery || !defaultQuery.groupBy) return false;
    const groupBy = Array.isArray(defaultQuery.groupBy) ? defaultQuery.groupBy : [defaultQuery.groupBy];
    return groupBy.some(param => param !== groupByBase);
  }, [defaultQuery, groupByBase]);

  // If there is an active group and it isn't the default, the group filter has changed
  // If there is no active group but the route is grouped by default, the group filter has changed
  const isGroupFilterChanged =
    activeGroup && activeGroup !== groupByBase ? !isActiveGroupInDefault : isGroupedByDefault;

  const isAnyFilterChanged = useMemo(() => {
    return !!query.method || !!query.status || !!query.vote || !!query.path || (hasGroupFilter && isGroupFilterChanged);
  }, [query, hasGroupFilter, isGroupFilterChanged]);

  const handleResetFilters = useCallback(() => {
    if (!routeDefaultState) return;

    // Reset the route config to default state
    updateMetricsPageConfig({
      ...routeDefaultState,
    });

    // Clear all filter params from base query params
    const nextQuery = {
      ...query,
      compare: null,
      vote: null,
      method: null,
      status: null,
      path: null,
    };

    updateQuery('query', nextQuery);
  }, [routeDefaultState, updateMetricsPageConfig, updateQuery, query]);

  if (!isAnyFilterChanged) return null;

  return (
    <Button className={classes['MetricsFilter-Reset']} ghost kind="secondary" onClick={handleResetFilters} size="sm">
      <i className={classy('icon-x', classes['MetricsFilter-icon'])} />
      Reset Filters
    </Button>
  );
}

export default ResetFilters;
