// eslint-disable-next-line readme-internal/no-restricted-imports
import jsonDiff from '@readme/server-shared/jsonDiff';

import makeFetch from '../makeFetch';

const saveProject = (project, packet) => {
  const url = `/api/projects/${project.subdomain}`;

  const changes = jsonDiff(project, packet);
  if (packet.childrenProjects) {
    const childrenChanges = packet.childrenProjects
      .map((child, idx) => {
        return { _id: child._id, ...jsonDiff(project.childrenProjects[idx], child) };
      })
      .filter(child => {
        const evaluated = { ...child };
        delete evaluated._id;
        return Object.values(evaluated).length; // is it empty?
      });
    if (childrenChanges.length) changes.childrenProjects = childrenChanges;
  }

  return makeFetch(url, {
    method: 'PUT',
    body: JSON.stringify(changes),
  });
};

export default saveProject;
