import type { ColorModePreviewProps } from './ColorModePreviewProps';

import React from 'react';

const ColorModeHeader = ({ isLineHeader, theme }: ColorModePreviewProps) => {
  const headerBackgroundFill = isLineHeader
    ? theme === 'dark'
      ? 'var(--gray10)'
      : 'white'
    : 'var(--Preview-primary-color)';
  const headerLineFill = isLineHeader ? 'var(--Preview-primary-color)' : 'rgba(0,0,0,0.1';
  const headerLinkFill = isLineHeader
    ? theme === 'dark'
      ? 'white'
      : 'var(--gray20)'
    : 'var(--Preview-header-link-color)';

  return (
    <>
      <mask fill="#fff" id="color-mode-header-d">
        <path d="M10 15h84v14H10V15Z" />
      </mask>
      <path d="M10 15h84v14H10V15Z" fill={headerBackgroundFill} />
      <rect fill={headerLinkFill} fillOpacity=".9" height="6" rx="3" width="12" x="15" y="19" />
      <rect fill={headerLinkFill} fillOpacity=".8" height="3" rx="1.5" width="6" x="29" y="20.5" />
      <rect fill={headerLinkFill} fillOpacity=".8" height="3" rx="1.5" width="6" x="37" y="20.5" />
      <rect fill={headerLinkFill} fillOpacity=".8" height="3" rx="1.5" width="6" x="45" y="20.5" />
      {theme === 'system' && !!isLineHeader && <rect fill="#303B42" height="13" width="39" x="53" y="15" />}
      <path
        d="M10 15h84-84Zm84 15H10v-2h84v2Zm-84-1V15v14Zm84-14v14-14Z"
        fill={headerLineFill}
        mask="url(#color-mode-header-d)"
      />
    </>
  );
};

export default ColorModeHeader;
