import type { ForwardedRef } from 'react';

import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import classes from './index.module.scss';

interface NavItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  /**
   * Whether or not the item is interactive (clickable).
   */
  isInteractive?: boolean;
  /**
   * Controls whether or not the open state is shown.
   */
  open?: boolean;
}

/**
 * Applies visual styling to an item in a list of Header navigation items.
 */
function NavItem(
  { children, open = false, isInteractive = true, className }: NavItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) {
  const bem = useClassy(classes, 'NavListItem');
  return (
    <li ref={ref} className={bem('&', open && '_open', isInteractive && '_interactive', className)}>
      {children}
    </li>
  );
}

export default forwardRef(NavItem);
