import { createContext, useContext } from 'react';

export interface CustomBlockMgmtContextValue {
  /**
   * sidebar filter state
   */
  filter: { search: string; sort: string };
  /**
   * active route segment (used to redir after CRUD operations)
   */
  routeSegment?: 'custom-components' | 'reusable-content';
  /**
   * sidebar filter setter
   */
  setFilter: React.Dispatch<
    React.SetStateAction<{
      search: string;
      sort: string;
    }>
  >;
  /**
   * custom block type
   */
  type?: 'component' | 'content';
}

const CustomBlockMgmtContext = createContext<CustomBlockMgmtContextValue>({
  filter: { search: '', sort: '' },
  setFilter: () => {},
});

export function useCustomBlockMgmtContext() {
  const value = useContext(CustomBlockMgmtContext);
  if (!value) throw new Error('CustomBlockMgmtContextProvider is not provided');
  return value;
}

export * from './Provider';

export default CustomBlockMgmtContext;
