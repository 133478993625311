import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';

import { APIBaseUrlContext } from '@core/context';
import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import { fetcher } from '@core/hooks/useReadmeApi';
import useSaveProject from '@core/hooks/useSaveProject/index';
import { getDecoratedHeaders } from '@core/utils/makeFetch';

import ErrorModal from '@routes/MyDevelopers/Setup/PersonalizedDocs/ErrorModal';
import FormError, {
  FormErrorDescription,
  FormErrorHeading,
} from '@routes/MyDevelopers/Setup/PersonalizedDocs/FormError';

import Button from '@ui/Button';
import CodeSnippet from '@ui/CodeSnippet';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import type Modal from '@ui/Modal';
import Title from '@ui/Title';

import PanelContent from '../PanelContent';
import useSaveWebhook from '../useSaveWebhook';

import { awsApiGatewayPolicyText, awsArnRegex, validAwsRegions } from './AWSConstants';
import AWSCrossAccountInfo from './AWSCrossAccountInfo';

interface SetupAWSPersonalizationFormProps {
  inputSize?: 'lg' | 'md' | 'sm' | 'xs';
  isButtonOutline?: boolean;
  onSaveWebhook?: (url: string) => void;
}

type SetupError = Error & {
  info: {
    message: string;
    type: 'access-denied' | 'invalid-usage-plan' | 'missing-params';
  };
  status: number;
};

function SetupAWSPersonalizationForm({
  isButtonOutline = false,
  inputSize = 'sm',
  onSaveWebhook,
}: SetupAWSPersonalizationFormProps) {
  const modalRef = useRef<Modal>(null);

  const { track } = useAmplitude();
  const [roleArn, setRoleArn] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [usagePlanId, setUsagePlanId] = useState<string>('');

  // TODO RM-11035: use APIv2
  const { data: project, isProjectLoading, saveProject } = useSaveProject();
  // the initialWebhookURL arg isn't needed since we're not tracking the state of the webhook
  // url via a user controlled input, but construct it ourselves on save
  const { handleSave: saveWebhook, handleUpdate: updateWebhook } = useSaveWebhook('', onSaveWebhook);
  const { subdomain, _id: externalId } = project;
  const [setupCompleted, setSetupCompleted] = useState(false);
  const [setupError, setSetupError] = useState<SetupError>();
  const [isLoading, setIsLoading] = useState(false);
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const host = apiBaseUrl !== '/' ? apiBaseUrl : '';

  useEffect(() => {
    if (!isProjectLoading && project.integrations?.login?.kind === 'aws') {
      setRoleArn(project.integrations?.login?.roleArn);
      setRegion(project.integrations?.login?.region);
      setUsagePlanId(project.integrations?.login?.usagePlanId);
    }
  }, [
    isProjectLoading,
    project.integrations?.login?.kind,
    project.integrations?.login?.region,
    project.integrations?.login?.roleArn,
    project.integrations?.login?.usagePlanId,
  ]);

  useEffect(() => {
    if (project?.fullBaseUrl) {
      updateWebhook(`${project.fullBaseUrl}login-webhook`);
    }
  }, [project?.fullBaseUrl, updateWebhook]);

  const testWebhook = useCallback(
    async formData => {
      setIsLoading(true);
      setSetupCompleted(false);
      setSetupError(undefined);

      try {
        if (!project?.fullBaseUrl) {
          throw new Error('Something went wrong. Please refresh the page and try again.');
        }

        await fetcher(`${host}/api/projects/${subdomain}/aws-personalization-webhook-setup`, {
          headers: getDecoratedHeaders(),
          method: 'post',
          body: JSON.stringify(formData),
        });
        await saveProject({
          integrations: {
            ...project.integrations,
            login: {
              kind: 'aws',
              roleArn,
              region,
              usagePlanId,
              externalId,
            },
          },
        });
        await saveWebhook();
        await setSetupCompleted(true);
      } catch (err) {
        setSetupError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [
      externalId,
      host,
      project?.fullBaseUrl,
      project.integrations,
      region,
      roleArn,
      saveProject,
      saveWebhook,
      subdomain,
      usagePlanId,
    ],
  );

  function toggleModal() {
    if (modalRef.current) modalRef.current.toggle();
  }

  const isValidArn = awsArnRegex.test(roleArn);
  const isValidRegion = validAwsRegions.includes(region);
  const isValidUsageId = usagePlanId?.length >= 6;
  const validOptions = isValidUsageId && isValidArn && isValidRegion;

  const { errorMessage, errorModalHeader, errorModalText } = useMemo(() => {
    if (!setupError) return { errorMessage: null, errorModalHeader: null, errorModalText: null };

    const defaultErrorMessage = (
      <FormError>
        <FormErrorHeading>There was a problem setting up your resources.</FormErrorHeading>
      </FormError>
    );
    if (setupError?.status === 400)
      switch (setupError?.info?.type) {
        case 'missing-params':
          return {
            errorMessage: (
              <FormError>
                <FormErrorHeading>Missing Params</FormErrorHeading>
                <FormErrorDescription>
                  Did you forget to fill in the AWS Access Key ID, AWS Secret Access Key ID, or AWS Usage Plan ID?
                </FormErrorDescription>
              </FormError>
            ),
            errorModalHeader: null,
            errorModalText: null,
          };
        case 'invalid-usage-plan':
          return {
            errorMessage: (
              <FormError>
                <FormErrorHeading>Usage Plan Not Found</FormErrorHeading>
                <FormErrorDescription>{setupError?.info?.message}</FormErrorDescription>
              </FormError>
            ),
            errorModalHeader: null,
            errorModalText: null,
          };
        case 'access-denied':
          return {
            errorMessage: (
              <Button kind="destructive" onClick={toggleModal} size="xs" text>
                Provided credentials do not correct permissions.
                <Icon name="maximize-2" title="Show details" />
              </Button>
            ),
            errorModalHeader: 'Provided credentials do not correct permissions.',
            errorModalText: `The credentials you provided do not have the correct permissions to create the resources needed for the webhook. Please make sure you have the correct permissions and try again. error: ${setupError.info?.message}`,
          };
        default:
          break;
      }

    return { errorMessage: defaultErrorMessage, errorModalHeader: null, errorModalText: null };
  }, [setupError]);

  return (
    <form
      onSubmit={ev => {
        ev.preventDefault();
        // Send event to amplitude
        if (validOptions) {
          track(AMPLITUDE_EVENT.PD_WEBHOOK_TEST, {
            type: AMPLITUDE_EVENT_PROPERTY.SETUP,
          });
        }

        testWebhook({
          externalId,
          roleArn,
          region,
          usagePlanId,
        });
      }}
    >
      <PanelContent>
        <Title level={5}>Step 1: Create IAM Role</Title>
        <AWSCrossAccountInfo externalId={externalId} />
        <Title level={5}>Step 2: Give us required information</Title>
        <Title level={6}>Role ARN</Title>
        <p>Provided by AWS once the IAM role is created. It allows ReadMe to assume the IAM role.</p>
        <Input
          id="roleArn"
          name="roleArn"
          onInput={ev => {
            const target = ev.target as HTMLInputElement;
            setRoleArn(target.value);
          }}
          placeholder="arn:aws:iam::012345678901:role/ReadMeSyncRole"
          required
          size={inputSize}
          value={roleArn}
        />
        <Title level={6}>AWS Region</Title>
        <p>The region your usage plan lives in.</p>
        <Input
          id="region"
          name="region"
          onInput={ev => {
            const target = ev.target as HTMLInputElement;
            setRegion(target.value);
          }}
          placeholder="us-east-1"
          required
          size={inputSize}
          type=""
          value={region}
        />
        <Title level={6}>AWS Usage Plan ID</Title>
        <p>The Usage plan to create api keys under.</p>
        <Input
          id="usagePlanId"
          minLength={5}
          name="usagePlanId"
          onInput={ev => {
            const target = ev.target as HTMLInputElement;
            setUsagePlanId(target.value);
          }}
          placeholder="1a2bc3"
          required
          size={inputSize}
          value={usagePlanId}
        />
        <Button
          disabled={!validOptions}
          fullWidth
          loading={isLoading}
          outline={isButtonOutline}
          size={inputSize}
          type="submit"
        >
          Setup
        </Button>

        {!!setupCompleted && (
          <Flex justify="center">
            <Icon name="check-circle" />
            <span>Success! We are linked to your AWS API Gateway</span>
          </Flex>
        )}
        {!!errorMessage && (
          <>
            <Flex justify="center">{errorMessage}</Flex>
            {!!errorModalHeader && !!errorModalText && (
              <ErrorModal
                ref={modalRef}
                errorHeader={errorModalHeader}
                errorText={errorModalText}
                target="#modal-target"
                toggle={toggleModal}
                webhookCodeSnippet={<CodeSnippet code={awsApiGatewayPolicyText} language={'json'}></CodeSnippet>}
              />
            )}
          </>
        )}
      </PanelContent>
    </form>
  );
}

export default SetupAWSPersonalizationForm;
