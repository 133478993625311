import type { ColorModePreviewProps } from './ColorModePreviewProps';

import React from 'react';

import ColorModeLayout from './ColorModeLayout.svg';

const ColorModeDark = ({ isLineHeader = false, style }: ColorModePreviewProps) => (
  <ColorModeLayout isLineHeader={isLineHeader} style={style} theme="dark">
    <path d="M10 29h19v47H10z" fill="#242E34" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="9" x="12" y="34" />
    <rect fill="#fff" height="3" rx="1" width="15" x="12" y="39" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="11" x="12" y="45" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="10" x="12" y="50" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="9" x="12" y="55" />
    <path d="M29 29h65v47H29z" fill="#303B42" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="42" x="32" y="34" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="49" x="32" y="39" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="59" x="32" y="44" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="36" x="32" y="49" />
    <rect fill="#ADB4C1" fillOpacity=".5" height="2" rx="1" width="49" x="32" y="54" />
    <rect fill="#fff" height="3" rx="1" width="10" x="83" y="33" />
  </ColorModeLayout>
);

export default ColorModeDark;
