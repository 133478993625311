import type { ComponentProps } from 'react';

import React from 'react';

import Flex from '@ui/Flex';

import CopyCode from './CopyCode';

function NgrokInstructions() {
  const installSteps = {
    mac: 'brew install ngrok/ngrok/ngrok',
    windows: 'choco install ngrok',
    linux:
      'curl -s https://ngrok-agent.s3.amazonaws.com/ngrok.asc | \\ \n  sudo tee /etc/apt/trusted.gpg.d/ngrok.asc >/dev/null && \\ \n  echo "deb https://ngrok-agent.s3.amazonaws.com buster main" | \\ \n  sudo tee /etc/apt/sources.list.d/ngrok.list && \\ \n  sudo apt update && sudo apt install ngrok',
  };

  let platformSteps: string;
  let variant: ComponentProps<typeof CopyCode>['variant'];
  const platform = navigator.platform;
  if (platform.indexOf('Win') !== -1) {
    platformSteps = installSteps.windows;
  } else if (platform.indexOf('Linux') !== -1) {
    platformSteps = installSteps.linux;
    variant = 'multiline';
  } else {
    platformSteps = installSteps.mac;
  }

  return (
    <Flex align="stretch" gap="sm" justify="start" layout="col">
      <CopyCode variant={variant}>{platformSteps}</CopyCode>
      <CopyCode>ngrok http 8000</CopyCode>
    </Flex>
  );
}

export default NgrokInstructions;
