import type { TippyProps } from '@tippyjs/react';

import React from 'react';

import Tooltip from '@ui/Tooltip';

const TOOLTIP_OFFSET: TippyProps['offset'] = [0, 5];

interface TooltipWrapperProps {
  children: React.ReactNode;
  className?: string;
  content: string;
  showTooltip: boolean;
}

/**
 * Conditionally wraps a ProjectNavigationButton with a tooltip.
 */
export default function TooltipWrapper({ children, content, className, showTooltip }: TooltipWrapperProps) {
  return React.isValidElement(children) && showTooltip ? (
    <Tooltip arrow={false} className={className} content={content} offset={TOOLTIP_OFFSET} placement="bottom">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}
