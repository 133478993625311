import type { VariableDefault as VariableDefaultV1 } from '@readme/backend/models/project/types';
import type { $TSFixMe } from '@readme/iso';

import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react';

import type { ConfigContextValue, ProjectContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useSaveProject from '@core/hooks/useSaveProject';
import { useProjectStore } from '@core/store';

import Button from '@ui/Button';
import Collapsible from '@ui/Collapsible';
import ComboBox from '@ui/ComboBox';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Table from '@ui/Table';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

export interface Suggestion {
  name: string;
}

/**
 * In the new project store, the `_id` property was replaced by `id`. This component
 * supports both the new project store and old project context, so we support both
 * variable shapes via this union type and discriminator function.
 */
type VariableDefaultV2 = Omit<VariableDefaultV1, '_id'> & {
  id: VariableDefaultV1['_id'];
};
type VariableDefault = VariableDefaultV1 | VariableDefaultV2;

function getId(variableDefault: VariableDefault) {
  return 'id' in variableDefault ? variableDefault.id : variableDefault._id;
}

interface Props {
  /* whether or not the variables should be editable (false for OAS-generated sections, true for manually defined) */
  editable?: boolean;
  /* an array of an array of strings for table headers/column names */
  head?: string[];
  isTableStriped: boolean;
  preview: React.ReactNode;
  rowHoverEnter: (e: React.MouseEvent<HTMLTableRowElement>, key: $TSFixMe) => void;
  rowHoverLeave: () => void;
  suggestions?: Suggestion[];
  /* an array of variable objects (as they would be stored in the Project model) */
  variables: VariableDefault[];
}

const VariablesTable = ({
  editable = false,
  variables,
  rowHoverEnter,
  rowHoverLeave,
  suggestions = [],
  isTableStriped,
  preview,
}: Props) => {
  const bem = useClassy(classes, 'VariablesTable');
  const [vars, setVars] = useState(variables);
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { name: configName } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = configName === 'Hub';

  const { saveProject: saveProjectV1 } = useSaveProject();
  const saveProjectV2 = useProjectStore(store => store.save);

  const { project } = useContext(ProjectContext) as ProjectContextValue;

  useEffect(() => {
    // Update vars when new variables received
    setVars(variables);
  }, [variables]);

  const parentDefaults: VariableDefault[] = useMemo(
    () => project.parent?.variableDefaults.map(v => v._id) || [],
    [project.parent?.variableDefaults],
  );

  const saveVarsToProject = useCallback(
    async (varsToSave: VariableDefault[]) => {
      setIsSaving(true);

      try {
        // preserve existing security and server variables
        const existingNonCustomVars = project.variableDefaults.filter(v => v?.source);

        const variableDefaults = existingNonCustomVars
          .concat(varsToSave)
          .reduce<(VariableDefault & { id: string })[]>((acc, next) => {
            const id = getId(next) || '';
            // dont save parent vars to child projects
            if (!parentDefaults.includes(id)) {
              // update object to have an `id` in addition to `_id`
              acc.push({ ...next, id });
            }
            return acc;
          }, []);

        if (isHub) {
          await saveProjectV2({ variable_defaults: variableDefaults });
        } else {
          await saveProjectV1({ variableDefaults }, res => {
            res.variableDefaults =
              project.parent?.variableDefaults.concat(res.variableDefaults) || res.variableDefaults;
            return res;
          });
        }
      } finally {
        setIsSaving(false);
      }
    },
    [isHub, parentDefaults, project.parent?.variableDefaults, project.variableDefaults, saveProjectV1, saveProjectV2],
  );

  const deleteVar = useCallback(
    async (deletedVar: VariableDefault) => {
      const { name, default: val } = deletedVar;
      const deletedId = getId(deletedVar);
      const newVars = vars.filter(v => {
        const idMatches = getId(v) === deletedId;
        const nameMatches = v?.name === name;
        const valMatches = v?.default === val;
        /* Remove the deleted var from state by matching (1) exclusively on
         * the var's ID, if it exists, or else (2) against BOTH its name
         * and value. (The latter is only required for cases when a user
         * tries to delete a default *before* they've actually saved it to
         * the DB.)
         */
        return deletedId ? !idMatches : !(nameMatches && valMatches);
      });
      setVars(newVars);
      saveVarsToProject(newVars);
    },
    [vars, saveVarsToProject],
  );

  const saveVar = useCallback(
    async (i, variable) => {
      vars[i] = { ...vars[i], ...variable };
      setVars([...vars]);
      // Only save if the row is completely filled out
      // Might be a better way to blur on the row instead of a single input,
      // but this is what I could think of
      if (vars[i].name !== '' && vars[i].default !== '') {
        await saveVarsToProject(vars);
      }
    },
    [vars, saveVarsToProject],
  );

  const addRow = useCallback(() => {
    // Don't let them add multiple blank rows
    if (!vars.find(v => v?.name === '')) {
      vars.push({
        name: '',
        default: '',
        source: '',
      });
      setVars([...vars]);
    }
  }, [vars]);

  // for editable table, need onBlur handler for each row, rather than each input field
  // do we want to save on blur of each input or on blur of the whole row?
  const rows = useMemo(
    () =>
      vars
        .filter(v => v)
        .map((variable, i) => {
          const row: $TSFixMe = {};
          const isParentVar = parentDefaults.includes(getId(variable));
          if (!editable) {
            row.key = (
              <Flex align="center" gap={0} justify="start">
                {variable.name}
              </Flex>
            );
            row.default = variable.default;
          } else {
            row.key = (
              <ComboBox
                disabled={isParentVar}
                menuHeader="Suggested from Data Sent"
                menuOptions={suggestions?.map(({ name }) => name)}
                onBlur={value => saveVar(i, { name: value, default: variable.default })}
                required
                size="sm"
                value={variable.name}
              ></ComboBox>
            );
            row.default = (
              <Flex align="center">
                <Input
                  disabled={isParentVar}
                  onBlur={e => saveVar(i, { name: variable.name, default: e.target.value })}
                  required
                  size="sm"
                  value={variable.default}
                />
              </Flex>
            );
            row.delete = (
              <Flex align="center" justify="center">
                {isParentVar ? (
                  <Tooltip content="Edit in parent project…">
                    <Button
                      disabled={isSaving}
                      href={`/group/${project.parent?.subdomain}/personalized-docs`}
                      kind="minimum"
                      size="sm"
                      target="_blank"
                      text
                    >
                      <Icon name="edit-2" />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button disabled={isSaving} kind="minimum" onClick={() => deleteVar(variable)} size="sm" text>
                    <Icon name="trash" title="Remove variable" />
                  </Button>
                )}
              </Flex>
            );
          }
          return row;
        }),
    [vars, parentDefaults, editable, suggestions, isSaving, project.parent?.subdomain, saveVar, deleteVar],
  );

  const bodyKeys = useMemo(() => {
    if (!editable) return undefined;
    return vars.filter(v => v && getId(v)).map(variable => getId(variable));
  }, [vars, editable]);

  const head = editable ? ['key', 'default', ''] : ['key', 'default'];
  const emptyState = <td colSpan={head.length}>No Variables</td>;

  return (
    <div className={bem('&')}>
      <Table
        body={rows}
        bodyKeys={bodyKeys}
        className={bem('-table', editable && '-table_editable', !rows.length && '-table_empty')}
        emptyStateFallback={emptyState}
        head={[head]}
        isStriped={isTableStriped}
        rowHoverEnter={rowHoverEnter}
        rowHoverLeave={rowHoverLeave}
      />
      {(!!preview || !!editable) && (
        <Flex align="center">
          {!!preview && (
            <button
              className={bem('-preview-summary', isPreviewExpanded && '-preview-summary_open')}
              onClick={() => setIsPreviewExpanded(!isPreviewExpanded)}
            >
              <Icon className={bem('-preview-summary-icon')} name="chevron-right" />
              Example Usage
            </button>
          )}
          {!!editable && (
            <Button disabled={isSaving} onClick={addRow} size="xs">
              <Icon name="plus" /> Add
            </Button>
          )}
        </Flex>
      )}
      {!!preview && (
        <Collapsible className={bem('-preview')} opened={isPreviewExpanded}>
          {preview}
        </Collapsible>
      )}
    </div>
  );
};

export default VariablesTable;
