import type { VariableDefault } from '@readme/backend/models/project/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Collapsible from '@ui/Collapsible';
import Flex from '@ui/Flex';

import WebhookVariablesTable from '../WebhookVariablesTable';

import styles from './style.module.scss';

interface Props {
  onVariableHover: (lineNumber?: number) => void;
  variables: {
    security: VariableDefault[];
    server: VariableDefault[];
  };
}

export default function WebhookVariablesSections({ variables, onVariableHover }: Props) {
  const bem = useClassy(styles, 'WebhookVariablesSections');

  const sections = [
    { type: 'server', title: ' Your Server Variables', variables: variables.server },
    { type: 'security', title: 'Your Security Scheme', variables: variables.security },
  ] as const;

  return (
    <Flex className={bem('-container')} gap="sm" layout="col">
      {sections.map(section => {
        return (
          <Collapsible key={section.type} summary={<div className={bem('-title')}>{section.title}</div>}>
            <WebhookVariablesTable
              editable={false}
              isTableStriped
              setHighlight={onVariableHover}
              type={section.type}
              variables={section.variables}
            />
          </Collapsible>
        );
      })}
    </Flex>
  );
}
