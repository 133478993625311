import React, { useCallback } from 'react';

import { useMetricsStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';

interface Props {
  onChange: () => void;
}
const FilterVote = ({ onChange }: Props) => {
  const [updateQuery, query] = useMetricsStore(s => [s.updateQuery, s.query]);

  const type = query.vote || '';
  const icons: Record<number, string> = {
    0: 'icon icon-thumbs-down1',
    1: 'icon icon-thumbs-up-2',
  };

  const handleChange = useCallback(
    to => {
      updateQuery('query', { vote: to !== type ? to : null });
      onChange();
    },
    [onChange, type, updateQuery],
  );

  const isActive = query.vote !== undefined;

  return (
    <Dropdown align="bottom" justify="start" sticky>
      <Button dropdown kind={isActive ? 'primary' : 'secondary'} outline={!isActive} size="sm">
        <Flex align="center" gap="xs">
          <i className={icons?.[type] || 'icon-inbox'} />
          Votes
        </Flex>
      </Button>
      <Menu>
        <MenuItem active={type === 1} icon={icons[1]} onClick={() => handleChange(1)}>
          Up Votes
        </MenuItem>
        <MenuItem active={type === 0} icon={icons[0]} onClick={() => handleChange(0)}>
          Down Votes
        </MenuItem>
        <MenuDivider />
        <MenuItem active={query.vote == null} onClick={() => handleChange(null)}>
          Show All
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default FilterVote;
