import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';

import styles from './index.module.scss';

export interface SidebarNavSectionProps {
  children?: React.ReactNode;
  className?: string;
  label?: string;
}

/**
 * Creates a spatial grouping of child `SidebarNavLink` or `SidebarNavGroup`
 * items within the top-level `SidebarNav` or inside another `SidebarNavGroup`.
 */
export function SidebarNavSection({ children, className, label }: SidebarNavSectionProps) {
  const bem = useClassy(styles, 'SidebarNavSection');

  return (
    <Flex align="stretch" className={bem('&', className)} gap="2px" layout="col" tag="section">
      {!!label && <span className={bem('-label')}>{label}</span>}
      <Flex align="stretch" className={bem('-content')} gap="3px" layout="col" tag="ul">
        {children}
      </Flex>
    </Flex>
  );
}
