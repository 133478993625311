import React, { useContext } from 'react';

import type { ConfigContextValue, ProjectContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';

import useSaveWebhook from '../useSaveWebhook';

import styles from './style.module.scss';

type UseSaveWebhookArgs = Parameters<typeof useSaveWebhook>;
interface Props {
  onSaveWebhook: UseSaveWebhookArgs[1];
}

export default function WebhookSaveForm({ onSaveWebhook }: Props) {
  const bem = useClassy(styles, 'WebhookSaveForm');

  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const { project: projectFromContext } = useContext(ProjectContext) as ProjectContextValue;
  const webhooksFromStore = useProjectStore(store => store.data.webhooks) || [];
  const webhooks = isHub ? webhooksFromStore : projectFromContext.webhooks;

  const initialWebhookURL = webhooks.find(hook => hook.action === 'login')?.url || '';

  const { webhookUrl, isWebhookUrlDirty, isWebhookUrlValid, isLoading, handleUpdate, handleSave } = useSaveWebhook(
    initialWebhookURL,
    onSaveWebhook,
  );

  return (
    <Flex align="stretch" gap="sm">
      <Input
        id="url"
        name="url"
        onInput={ev => {
          handleUpdate((ev.target as HTMLInputElement).value);
        }}
        placeholder="https://example.com/webhook"
        required
        size="md"
        suffix={!!webhookUrl && !isWebhookUrlDirty ? <Icon name="check" /> : <span />}
        type="url"
        value={webhookUrl}
      />
      <Button
        className={bem('-save')}
        disabled={!isWebhookUrlDirty || !isWebhookUrlValid}
        kind="primary"
        loading={isLoading}
        onClick={() => handleSave()}
        size="md"
      >
        Save
      </Button>
    </Flex>
  );
}
