import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Modal, { ModalBody } from '@ui/Modal';

import classes from './style.module.scss';

interface Props {
  errorHeader: string;
  errorText: string;
  onClose?: () => void;
  rootNode?: Node;
  target: string;
  toggle: () => void;
  webhookCodeSnippet: React.ReactNode;
}

const ErrorModal = forwardRef(
  (
    { onClose = () => {}, rootNode, target, webhookCodeSnippet, toggle, errorHeader, errorText }: Props,
    ref: React.Ref<Modal>,
  ) => {
    const bem = useClassy(classes, 'WebhooksErrorModal');

    return (
      <Modal ref={ref} className={bem()} onClose={onClose} rootNode={rootNode} size="lg" target={target} verticalCenter>
        <ModalBody className={bem('-body')}>
          <section className={bem('-mirror')} data-color-mode="dark">
            {webhookCodeSnippet}
          </section>
          <Flex align="stretch" className={bem('-error')} justify="between" layout="col">
            <div className={bem('-error-section')}>
              <h3 className={bem('-error-section-title')}>{errorHeader}</h3>
              <p className={bem('-error-section-description')}>{errorText}</p>
            </div>
            <Flex className={bem('-error-footer')} gap="sm" layout="col">
              <Button className={bem('-error-footer-button')} fullWidth onClick={toggle}>
                Close
              </Button>
              <Button
                className={bem('-error-footer-button')}
                fullWidth
                href="https://docs.readme.com/main/docs/personalized-docs"
                onClick={toggle}
                outline
                target="_blank"
              >
                Learn More
                <Icon name="arrow-up-right" />
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </Modal>
    );
  },
);

ErrorModal.displayName = 'ErrorModal';

export default ErrorModal;
