import type { ComponentProps } from 'react';

import PropTypes from 'prop-types';
import React from 'react';

import BaseInstructions from './base';

const installCode = 'gem "readme-metrics"';
const getAPICallCode = apiKey => `require "readme/metrics"

options = {
 api_key: "${apiKey}",
}

config.middleware.use Readme::Metrics, options do env
  {
    # User's API Key
    api_key: "owlbert-api-key",
    # Username to show in ReadMe's dashboard
    label: "Owlbert",
    # User's email address
    email: "owlbert@example.com"
  }
end
`;

interface RubyInstructionsProps extends Omit<ComponentProps<typeof BaseInstructions>, 'instructions'> {
  selectedAPIKey: string;
}

function RubyInstructions({ apiKeyDropdown, isQuickstart, log, selectedAPIKey }: RubyInstructionsProps) {
  const apiCallCode = getAPICallCode(selectedAPIKey);

  const installPrompt = (
    <span>
      Install the{' '}
      <a href="https://rubygems.org/gems/readme-metrics" rel="noreferrer" target="_blank">
        readme-metrics
      </a>{' '}
      package from{' '}
      <a href="https://rubygems.org" rel="noreferrer" target="_blank">
        RubyGems
      </a>
      .
    </span>
  );

  const apiCallPrompt = (
    <span>
      Add the server middleware to <code>config/environments/development.rb</code> and populate API request attributes,
      such as a user’s email and API key.
    </span>
  );

  const instructions = [
    { code: installCode, prompt: installPrompt, language: 'ruby' },
    { code: apiCallCode, prompt: apiCallPrompt, language: 'ruby', includesAPIKey: true },
  ];

  return (
    <BaseInstructions
      apiKeyDropdown={apiKeyDropdown}
      instructions={instructions}
      isQuickstart={isQuickstart}
      log={log}
    />
  );
}

RubyInstructions.propTypes = {
  apiKeyDropdown: PropTypes.element.isRequired,
  isQuickstart: PropTypes.bool,
  log: PropTypes.object,
  selectedAPIKey: PropTypes.string,
};

export default RubyInstructions;
