import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';
import type { MyDevelopersRouteParams } from '@core/types/metrics';

import MetricsFilters from '@routes/Dash/Project/Metrics/Filters';

import MetricsPage from '@readme/react/src/routes/Dash/Project/Metrics/Page';

import { UserPageConfig } from './config';
import Sidepanel from './Sidepanel';
import classes from './style.module.scss';

interface Props {
  /** Whether component is being used in Superhub (so subcomponents can have dark-on-black styling) */
  isSuperhub?: boolean;
}

function MyDevelopersUsers({ isSuperhub }: Props) {
  const bem = useClassy(classes, 'MyDevelopersUsers');

  const [updateMetricsPageConfig, updateQuery, isReady, metricsPageConfig] = useMetricsStore(s => [
    s.updateMetricsPageConfig,
    s.updateQuery,
    s.isReady,
    s.metricsPageConfig,
  ]);

  const { identifier } = useParams<MyDevelopersRouteParams>();

  /**
   * We're hijacking the Metrics page config here to use the same MetricsPage components w/ API Calls
   * And we're updating the email on MetricsStore query to filter the data for the specified user.
   */
  useEffect(() => {
    updateQuery('query', { email: identifier });
    updateMetricsPageConfig(UserPageConfig);

    // Clear email from the query on unmount
    return () => {
      updateQuery('query', { email: null });
    };
  }, [updateMetricsPageConfig, updateQuery, identifier]);

  if (!isReady || !metricsPageConfig?.endpoint) return null;

  return (
    <div className={bem('-layout')}>
      <Sidepanel isSuperhub={isSuperhub} />
      <div className={bem('-page')}>
        <MetricsFilters isSuperhub={isSuperhub} />
        <MetricsPage isSuperhub={isSuperhub} />
      </div>
    </div>
  );
}

export default MyDevelopersUsers;
