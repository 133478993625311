import React from 'react';

import classes from './style.module.scss';

const content = {
  css: {
    avoid: (
      <ul className={classes['BestPractices-list']}>
        <li>
          Relying on <em>any</em> selector that ends in a hash, like:{' '}
          <code className={classes['BestPractices-code']}>{'.Sidebar7b72qiz831 {}'}</code>
        </li>
        <li>
          Highly markup-dependent selectors, like:{' '}
          <code className={classes['BestPractices-code']}>{'header.header-top > h1 > a.link'}</code>
        </li>
        <li>Sweeping layout changes, moving elements with floats, and position hacks</li>
      </ul>
    ),
    globalVars: null,
    tips: (
      <ul className={classes['BestPractices-list']}>
        <li>
          Prefer <code className={classes['BestPractices-code']}>!important</code> to override properties over long,
          complex selectors
        </li>
        <li>
          Duplicate selectors to increase specificity as needed, like:{' '}
          <code className={classes['BestPractices-code']}>.red.red.red</code>
        </li>
        <li>
          Preview your changes in dark mode. Read our{' '}
          <a
            className={classes['BestPractices-link']}
            href="https://docs.readme.com/main/docs/custom-css-with-dark-mode"
          >
            guide on Custom CSS with dark mode
          </a>
        </li>
      </ul>
    ),
  },
  javascript: {
    avoid: (
      <ul className={classes['BestPractices-list']}>
        <li>Logic and DOM manipulation that is highly dependent on nested markup or class structures</li>
      </ul>
    ),
    globalVars: (
      <ul className={classes['BestPractices-list']}>
        <li>
          <code className={classes['BestPractices-code']}>RM_ReferenceSidebarScrollTopOffset</code>: pixel offset for
          the scroll-to-active-item sidebar logic in continuous Reference sections
        </li>
      </ul>
    ),
    tips: (
      <ul className={classes['BestPractices-list']}>
        <li>Your custom JavaScript will be included at the bottom of the page</li>
        <li>
          Encapsulate logic in thoughtfully named functions (ie.{' '}
          <code className={classes['BestPractices-code']}>moveSearch</code>)
        </li>
        <li>
          To run JavaScript on each page load, use the <code className={classes['BestPractices-code']}>pageload</code>{' '}
          event.
          <code className={classes['BestPractices-code_block']}>
            <pre className={classes['BestPractices-pre']}>{`$(window).on('pageLoad', () => {
  // Your Code Here
  });
  `}</pre>
          </code>
        </li>
        <li>
          Stick to vanilla JavaScript where you can! (But just in case, we&apos;ve preloaded{' '}
          <a className={classes['BestPractices-link']} href="https://github.com/fabiospampinato/cash#documentation">
            a lightweight jQuery alternative
          </a>{' '}
          which you can use from the <code className={classes['BestPractices-code']}>$</code> global.)
        </li>
      </ul>
    ),
  },
};

export type BestPracticesContentType = keyof typeof content;

export const getContent = (type: BestPracticesContentType) => {
  return content[type];
};
