import type { ComponentProps, Dispatch, SetStateAction } from 'react';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';

import BaseInstructions from './base';
import { PythonPackages as Packages } from './constants';

const apiCallCode = (pkg, apiKey) => {
  switch (pkg) {
    case Packages.Django:
      return `from readme_metrics import MetricsApiConfig

# Define ReadMe's Metrics middleware
README_METRICS_CONFIG = MetricsApiConfig(
    api_key="${apiKey}",
    grouping_function="module.path.to.grouping_func"
)

# Include it in your existing middleware declarations
MIDDLEWARE = [
    ...,
    "readme_metrics.django.MetricsMiddleware"
]`;

    case Packages.Flask:
      return `from flask import Flask
from readme_metrics import MetricsApiConfig
from readme_metrics.flask_readme import ReadMeMetrics

# Create your flask app
app = Flask("Your-App-Name")

# Define ReadMe's Metrics middleware
metrics_extension = ReadMeMetrics(
    MetricsApiConfig(
        api_key="${apiKey}",
        grouping_function=grouping_function
    )
)

# Integrate it with your flask app
metrics_extension.init_app(app)`;

    case Packages.WSGI:
    default:
      return `from readme_metrics import MetricsApiConfig, MetricsMiddleware

# Create your flask app
app = Flask(__name__)

# Define ReadMe's Metrics middleware
app.wsgi_app = MetricsMiddleware(
  app.wsgi_app,
  MetricsApiConfig(
    "${apiKey}", # ReadMe API Key
    lambda req: {
      # User's API Key
      api_key: "owlbert-api-key",
      # Username to show in ReadMe's dashboard
      label: "Owlbert",
      # User's email address
      email: "owlbert@example.com",
    }
  )
)`;
  }
};

const groupingCode = `def grouping_function(request):
  if user_is_authenticated:
      return {
        # User's API Key
        "api_key": "owlbert-api-key",
        # Username to show in ReadMe's dashboard
        "label": "Owlbert",
        # User's email address
        "email": "owlbert@example.com",
      }
  else:
      return None
`;

type PackageValue = (typeof Packages)[keyof typeof Packages];
interface PythonInstructionsProps extends Omit<ComponentProps<typeof BaseInstructions>, 'instructions'> {
  languagePackage: PackageValue;
  selectedAPIKey: string;
  setLanguagePackage: Dispatch<SetStateAction<PackageValue>>;
}

function PythonInstructions({
  apiKeyDropdown,
  isQuickstart,
  languagePackage,
  log,
  setLanguagePackage,
  selectedAPIKey,
}: PythonInstructionsProps) {
  const installCode = `pip install readme-metrics${languagePackage === Packages.WSGI ? '' : `[${languagePackage}]`}`;
  const installPrompt = (
    <span>
      Install the {languagePackage === Packages.WSGI ? '' : languagePackage}{' '}
      <a href="https://pypi.org/project/readme-metrics" rel="noreferrer" target="_blank">
        readme-metrics
      </a>{' '}
      package from{' '}
      <a href="https://pypi.org" rel="noreferrer" target="_blank">
        PyPI
      </a>
      .
    </span>
  );

  const groupingPrompt = (
    <span>
      Once the new package is installed, define a grouping function. This can give additional context to your API
      request, such as a user’s email and API key.
    </span>
  );

  const callPrompt = useMemo(() => {
    switch (languagePackage) {
      case Packages.Django:
        return (
          <span>
            Next, define server middleware via the <code>MetricsApiConfig</code> function in your{' '}
            <code>settings.py</code> configuration file. Add this to your existing middleware.
          </span>
        );
      case Packages.Flask:
        return (
          <span>
            Next, declare a new extension using <code>ReadMeMetrics</code>, <code>MetricsApiConfig</code>, and the
            grouping function you defined previously. Add this extension wherever your Flask app is created.
          </span>
        );
      case Packages.WSGI:
      default:
        return (
          <span>
            Next, add the server middleware and populate API request attributes, such as a user’s email and API key.
          </span>
        );
    }
  }, [languagePackage]);

  const instructions = [
    {
      code: installCode,
      prompt: installPrompt,
      language: 'python',
    },
    ...(languagePackage === Packages.WSGI
      ? []
      : [
          {
            code: groupingCode,
            prompt: groupingPrompt,
            language: 'python',
          },
        ]),
    {
      prompt: callPrompt,
      code: apiCallCode(languagePackage, selectedAPIKey),
      language: 'python',
      includesAPIKey: true,
    },
  ];

  return (
    <>
      <Flex align="center" justify="end">
        <Dropdown clickInToClose sticky style={{ marginRight: '10px' }}>
          <Button dropdown kind="secondary" outline size="xs">
            {languagePackage}
          </Button>
          <Menu>
            {Object.values(Packages).map(pkg => (
              <MenuItem key={pkg} active={languagePackage === pkg} onClick={() => setLanguagePackage(pkg)}>
                {pkg}
              </MenuItem>
            ))}
          </Menu>
        </Dropdown>
      </Flex>
      <BaseInstructions
        apiKeyDropdown={apiKeyDropdown}
        instructions={instructions}
        isQuickstart={isQuickstart}
        log={log}
      />
    </>
  );
}

PythonInstructions.propTypes = {
  apiKeyDropdown: PropTypes.element.isRequired,
  isQuickstart: PropTypes.bool,
  languagePackage: PropTypes.oneOf(Object.values(Packages)),
  log: PropTypes.object,
  selectedAPIKey: PropTypes.string,
  setLanguagePackage: PropTypes.func.isRequired,
};

export default PythonInstructions;
