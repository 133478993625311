import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

const HeaderTypeLine = ({ style }: SharedSvgProps) => (
  <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3_910)">
      <path d="M0 0h92v62H0z" fill="url(#paint0_linear_3_910)" />
      <g clipPath="url(#clip1_3_910)">
        <rect fill="#F0F0F0" height="62" rx="4" width="118" x="9" y="15" />
        <path d="M9 19a4 4 0 0 1 4-4h110a4 4 0 0 1 4 4v16H9V19Z" fill="#fff" />
        <path d="M9 34h83v1H9z" fill="var(--Preview-primary-color)" />
        <path d="M9 35h118v14H9z" fill="#fff" />
        <rect fill="#ADB4C1" fillOpacity=".5" height="4" rx="2" width="18" x="19" y="40" />
        <rect fill="#ADB4C1" fillOpacity=".5" height="4" rx="2" width="18" x="45" y="40" />
        <rect fill="#ADB4C1" fillOpacity=".5" height="4" rx="2" width="18" x="71" y="40" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3_910">
        <path d="M0 0h92v62H0z" fill="#fff" />
      </clipPath>
      <clipPath id="clip1_3_910">
        <rect fill="#fff" height="62" rx="4" width="118" x="9" y="15" />
      </clipPath>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_3_910"
        x1="75.414"
        x2="95.644"
        y1="14.208"
        y2="14.293"
      >
        <stop stopColor="#AE9ED4" />
        <stop offset="1" stopColor="#8AB0FF" />
      </linearGradient>
    </defs>
  </svg>
);

export default HeaderTypeLine;
