import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Badge from '@ui/Badge';
import Icon from '@ui/Icon';
import SmartLink from '@ui/SmartLink';

import styles from './index.module.scss';

export interface SidebarNavLinkProps {
  /**
   * Renders a badge to display.
   */
  badge?: React.ReactNode;
  className?: string;
  href?: string;
  /**
   * Renders an icon from [Icons library](#/Foundations/Icons).
   */
  icon: string;
  /**
   * Renders an beta badge.
   */
  isBeta?: boolean;
  /**
   * Link label to display.
   */
  label: string;
  /**
   * Specifies where to open the linked document. Only applies when `href` is
   * provided.
   */
  target?: '_blank' | '_self';
  /**
   * Renders a `NavLink` that automatically navigates to this route. Requires a
   * ReactRouter to be present such as `BrowserRouter` or `HashRouter`.
   */
  to?: string;
}

/**
 * Renders an anchor element that is a standard link with `href` attribute or a
 * ReactRouter `NavLink` with `to` prop. To use the latter, a router must be
 * present somewhere up the component tree.
 */
export function SidebarNavLink({
  badge,
  className,
  href,
  icon,
  isBeta = false,
  label,
  target = '_self',
  to,
}: SidebarNavLinkProps) {
  const bem = useClassy(styles, 'SidebarNavLink');

  return (
    <li className={bem('&', className)}>
      <SmartLink activeClassName={bem('-link_active')} className={bem('-link')} href={href} target={target} to={to}>
        <Icon className={bem('-icon')} name={icon} size="sm" />
        <span className={bem('-label')}>
          {label}{' '}
          {!!href && target === '_blank' && <Icon className={bem('-external-icon')} name="arrow-up-right" size="sm" />}
        </span>

        {!!isBeta && (
          <Badge className={bem('-badge')} kind="alert">
            Beta
          </Badge>
        )}
        {!!badge && <span className={bem('-badge')}>{badge}</span>}
      </SmartLink>
    </li>
  );
}
