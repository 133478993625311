import React, { useEffect, useState } from 'react';

import { MetricsApiLogsSupportedLanguagesType } from '@core/enums/metrics';
import useAmplitude, { AMPLITUDE_EVENT } from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import { InitializeReferenceStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import SetupLanguagePicker from '@ui/Metrics/SetupLanguagePicker';
import MyDevelopersAnimation from '@ui/MyDevelopersHero';

import styles from './index.module.scss';
import InstallSDKStep from './InstallSDKStep';

type SetupApiLogsStep = 'install-sdk' | 'pick-language';

export default function SetupApiLogs() {
  const bem = useClassy(styles, 'SetupApiLogs');
  const { track } = useAmplitude();

  const [currentStep, setCurrentStep] = useState<SetupApiLogsStep>('pick-language');

  const availableLanguages = Object.values(MetricsApiLogsSupportedLanguagesType);

  useEffect(() => {
    track(AMPLITUDE_EVENT.METRICS_SDK_VIEW);
  }, [track]);

  return (
    <InitializeReferenceStore>
      <Button circular className={bem('-view-demo')} kind="primary" size="sm" to="/my-developers/demo">
        View Demo
      </Button>
      <Flex
        align="center"
        className={bem('-container')}
        justify={currentStep === 'install-sdk' ? 'flex-start' : 'center'}
        layout="col"
      >
        {currentStep === 'pick-language' && (
          <>
            <MyDevelopersAnimation />
            <SetupLanguagePicker
              availableLanguages={availableLanguages}
              description="Get real-time API insights, helping you understand users and where they encounter errors."
              onChangeLanguage={() => setCurrentStep('install-sdk')}
              title="Get to Know Your Users"
            />
          </>
        )}
        {currentStep === 'install-sdk' && <InstallSDKStep onBack={() => setCurrentStep('pick-language')} />}
      </Flex>
    </InitializeReferenceStore>
  );
}
