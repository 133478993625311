import React from 'react';

import { NavLink as Link } from '@Dash/Link';

import Title from '@ui/Title';

import classes from './style.module.scss';

interface Props {
  action?: string | (() => void);
  actionLabel?: string;
  description?: string;
  heading?: string;
  icon?: string;
  target?: '_blank' | '_self';
}

const FeedEmptyState = ({ icon, heading, description, actionLabel, action, target }: Props) => {
  const Tag: React.ElementType = target ? 'a' : Link;

  return (
    <div className={classes['Feed-EmptyState']}>
      {!!icon && <i className={`${icon} ${classes['Feed-EmptyState-icon']}`} />}

      {!!heading && (
        <Title className={classes['Feed-EmptyState-title']} level={3}>
          {heading}
        </Title>
      )}

      {!!description && <p className={classes['Feed-EmptyState-description']}>{description}</p>}

      {!!actionLabel &&
        !!action &&
        (typeof action === 'function' ? (
          <button className={classes['Feed-link']} onClick={action}>
            {actionLabel}
          </button>
        ) : (
          <Tag
            className={classes['Feed-link']}
            {...(target ? { href: action, target, rel: 'noreferrer' } : { to: action })}
          >
            {actionLabel}
            {target === '_blank' && <i className="icon-arrow-up-right" />}
            {target === '_self' && <i className="icon-arrow-right2" />}
          </Tag>
        ))}
    </div>
  );
};

export default FeedEmptyState;
