import type { $TSFixMe } from '@readme/iso';

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import type { ConfigContextValue, ProjectContextValue, UserContextValue } from '@core/context';
import { BaseUrlContext, ConfigContext, ProjectContext, UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useProjectStore } from '@core/store';

import Badge from '@ui/Badge';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import Truncate from '@ui/Truncate';

import ProjectNavigationButton from '../Button';

import classes from './index.module.scss';

export default function ProjectMenu() {
  const bem = useClassy(classes, 'ProjectMenu');
  const baseUrl = useContext(BaseUrlContext);
  const { domainFull: dashUrl } = useContext(ConfigContext) as ConfigContextValue;
  const { isGod } = useContext(UserContext) as $TSFixMe as UserContextValue;
  const {
    project: { custom_domain: customDomain },
  } = useContext(ProjectContext) as ProjectContextValue;
  const [name, logo, favicon] = useProjectStore(s => [
    s.data.name,
    s.data.appearance.logo.main,
    s.data.appearance.logo.favicon,
  ]);
  const { pathname } = useLocation();

  const avatarUrl = favicon.url ? favicon.url : logo.url;
  const cleanBaseUrl = baseUrl === '/' ? '' : baseUrl;

  const protocolRegex = /^(http|https):\/\//;
  // If custom domain has a protocol, use it, otherwise add a protocol
  const customDomainHref = protocolRegex.test(customDomain) ? customDomain : `//${customDomain}`;
  // Clean custom domain for display
  const customDomainLabel = customDomain
    .replace(protocolRegex, '') // remove http(s)://
    .replace(/\/$/, ''); // remove trailing slash

  return (
    <Dropdown className={bem('&-wrapper')} clickInToClose justify="start">
      <ProjectNavigationButton>
        <Icon name="more-vertical" size="sm" />
        <Truncate>{name}</Truncate>
      </ProjectNavigationButton>

      <Menu data-color-mode="dark" theme="dark">
        <Flex align="center" className={bem('&-header')} gap="xs" layout="col">
          {!!avatarUrl && <img alt={`${name} logo`} className={bem('&-avatar')} src={avatarUrl} />}
          <span className={bem('&-header-name')}>{name}</span>
          {!!customDomain && (
            <a className={bem('&-header-url')} href={customDomainHref} rel="noreferrer" target="_blank">
              {customDomainLabel}
            </a>
          )}
        </Flex>

        {!!isGod && (
          <>
            <MenuItem href="/dash?to=settings" TagName="a" target="_blank">
              <Icon name="sliders" />
              <Flex gap="xs" justify="start">
                Project Settings
                <Badge kind="info">God</Badge>
              </Flex>
            </MenuItem>
            <MenuDivider />
          </>
        )}

        <MenuItem
          onClick={() => {
            window.location.hash = '/configure/project-settings';
          }}
        >
          <Icon name="settings" /> Settings
        </MenuItem>

        <MenuDivider />

        <MenuItem href={dashUrl} TagName="a" target="_blank">
          <Icon name="grid" /> <span>My Projects</span>
        </MenuItem>
        <MenuItem href={`${dashUrl}/user/edit`} TagName="a" target="_blank">
          <Icon name="user" /> <span>My Account</span>
        </MenuItem>
        <MenuItem href={`/logout?redirect_uri=${cleanBaseUrl}${pathname}`} TagName="a">
          <Icon name="log-out" /> Log Out
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
