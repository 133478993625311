import React, { useEffect, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import useFullStory from '@core/hooks/useFullStory';
import useWootric from '@core/hooks/useWootric';
import {
  ConnectProjectStoreToApi,
  ConnectProjectStoreToDocument,
  ConnectSuperHubStoreToRouter,
  InitializeSuperHubStore,
  useSuperHubStore,
} from '@core/store';

import HashRouter from './HashRouter';
import classes from './index.module.scss';
import { SuperHubContextProvider } from './Layout/Context';
import ProjectNavigation from './Layout/ProjectNavigation';
import useHubEditModeTransition from './Layout/useHubEditModeTransition';
import WelcomeModal from './Layout/WelcomeModal';

export interface SuperHubProps {
  children: React.ReactNode;
}

/**
 * Renders superhub content for admins with settings and edit access.
 */
function AdminContainer({ children }: SuperHubProps) {
  const bem = useClassy(classes, 'SuperHub');
  const [layout, myDevPanelExpanded] = useSuperHubStore(s => [s.layout, s.isMyDevPanelExpanded]);

  useEffect(() => {
    document.body.classList.add(bem('_no-scroll'));
    return () => {
      document.body.classList.remove(bem('_no-scroll'));
    };
  }, [bem]);

  useHubEditModeTransition();

  const isLayoutExpanded = useMemo(() => {
    return layout && layout === 'my-developers' && myDevPanelExpanded;
  }, [layout, myDevPanelExpanded]);

  // Enable FullStory & Wootric for SuperHub admins
  useFullStory();
  useWootric();

  return (
    <SuperHubContextProvider>
      <ConnectSuperHubStoreToRouter>
        <ConnectProjectStoreToApi>
          <ConnectProjectStoreToDocument>
            <div className={bem('&', '_admin', layout && `_${layout}`, isLayoutExpanded && `_${layout}-expanded`)}>
              <ProjectNavigation className={bem('-project-controls')} />
              <WelcomeModal />
              <div className={bem('-hub-wrapper')}>
                <div className={bem('-hub-wrapper-overflow')}>{children}</div>
              </div>

              <HashRouter />
            </div>
          </ConnectProjectStoreToDocument>
        </ConnectProjectStoreToApi>
      </ConnectSuperHubStoreToRouter>
    </SuperHubContextProvider>
  );
}

/**
 * Renders superhub content for non-admin users with view-only access.
 */
function ViewContainer({ children }: SuperHubProps) {
  const bem = useClassy(classes, 'SuperHub');

  return (
    <SuperHubContextProvider>
      <ConnectSuperHubStoreToRouter>
        <div className={bem()}>{children}</div>
      </ConnectSuperHubStoreToRouter>
    </SuperHubContextProvider>
  );
}

function Content({ children }: SuperHubProps) {
  const [isSuperHub, isSuperHubAdmin] = useSuperHubStore(s => [s.isSuperHub, s.isSuperHubAdmin]);

  return isSuperHubAdmin ? (
    <AdminContainer>{children}</AdminContainer>
  ) : isSuperHub ? (
    <ViewContainer>{children}</ViewContainer>
  ) : (
    // For non-superhub projects, render content as is.
    <>{children}</>
  );
}

/**
 * Top-level wrapper provides our required context that all sub-components need.
 * This allows our top-level SuperHub component to also access context values.
 */
export default function SuperHub(props: SuperHubProps) {
  return (
    <InitializeSuperHubStore>
      <Content {...props} />
    </InitializeSuperHubStore>
  );
}
