import { createColumnHelper } from '@tanstack/react-table';

import { Column } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import type { DocMetricsData } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import { CompanyCell, TextCell, TimeCell } from '@ui/ReactTable/components/Cells';

import { LinkToHubCell } from '../Table/Cells';

const columnHelper = createColumnHelper<DocMetricsData>();

// This is the base query for the page when no group by is selected
const noGroupByQuery = 'groupBy=period';

export default {
  title: 'Search',
  endpoint: 'search',
  exportSource: 'searchTerms',
  fallback: null,
  filters: ['Group', 'Searches', 'User'],
  graph: {
    type: 'bar',
    noGroupByQuery,
    query: 'groupBy=searchTerm&groupBy=period',
    groups: ['company', 'searchTerm', 'email'],
    groupByBase: 'period',
  },
  shortcuts: {
    'Top Search Terms': { query: noGroupByQuery, graphType: 'bar' },
    'Searches Over Time': { query: noGroupByQuery, graphType: 'line' },
  },
  table: {
    query: '',
    defaultSort: {
      column: Column.CreatedAt,
      direction: 'desc',
    },
    columns: [
      columnHelper.accessor(Column.CreatedAt, {
        enableHiding: false,
        header: 'Time',
        cell: ({ getValue }) => TimeCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.SearchTerm, {
        enableHiding: false,
        header: columnHeaders[Column.SearchTerm],
      }),
      columnHelper.accessor(Column.Email, {
        header: columnHeaders[Column.Email],
        cell: ({ getValue }) => TextCell({ value: getValue() || '', copyable: true }),
      }),
      columnHelper.accessor(Column.Company, {
        header: columnHeaders[Column.Company],
        minSize: 120,
        cell: ({ getValue }) => CompanyCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Link, {
        header: columnHeaders[Column.Link],
        cell: ({ getValue, row }) => {
          const { version } = row.original;
          return LinkToHubCell({ page: getValue(), version });
        },
      }),
    ],
    prefsName: TablePrefsKey.MetricsSearch,
  },
};
