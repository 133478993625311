import type { ReactNode, ReactElement } from 'react';

import React, { useCallback } from 'react';

import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import type { MetricsSDKSetupLog } from '@core/types/metrics';

import CodeSnippet from '@ui/CodeSnippet';
import CopyToClipboard from '@ui/CopyToClipboard';
import Icon from '@ui/Icon';
import Spinner from '@ui/Spinner';

import classes from './style.module.scss';

interface Instruction {
  code: string;
  includesAPIKey?: boolean;
  language?: string;
  prompt?: ReactNode;
}

const snippetOptions = {
  dark: true,
  highlightMode: true,
  editable: false,
};

interface Props {
  apiKeyDropdown: ReactElement;
  instructions: Instruction[];
  isQuickstart?: boolean;
  log?: MetricsSDKSetupLog | null;
}

function BaseInstructions({ apiKeyDropdown, instructions, isQuickstart, log }: Props) {
  const bem = useClassy(classes, 'Instructions');
  const { track } = useAmplitude();

  const handleCopy = useCallback(
    language => {
      track(AMPLITUDE_EVENT.METRICS_SDK_CODE_COPY, {
        language,
        type: isQuickstart ? AMPLITUDE_EVENT_PROPERTY.QUICKSTART : AMPLITUDE_EVENT_PROPERTY.SETUP,
      });
    },
    [isQuickstart, track],
  );

  const handleCodeSelection = useDebounced(language => {
    track(AMPLITUDE_EVENT.METRICS_SDK_CODE_SELECT, {
      language,
      type: isQuickstart ? AMPLITUDE_EVENT_PROPERTY.QUICKSTART : AMPLITUDE_EVENT_PROPERTY.SETUP,
    });
  }, 3000);

  return (
    <div className={bem('&')}>
      <div className={bem('-setup-card-grid')}>
        {instructions.map((instruction, i: number) => {
          const { prompt, code, includesAPIKey = false, language = '' } = instruction;

          return (
            <React.Fragment key={i}>
              <div className={bem('-setup-card-grid-item')}>
                {!!prompt && <div className={bem('-installation')}>{prompt}</div>}
              </div>

              <div className={bem('-setup-card-grid-item')}>
                {!!code && (
                  <CodeSnippet
                    className={bem('-code', includesAPIKey && bem('-code-withApiKey'))}
                    code={code}
                    header={
                      <CopyToClipboard className={bem('-code-copy')} onCopy={() => handleCopy(language)} text={code} />
                    }
                    language={language}
                    onClick={() => handleCodeSelection(language)}
                    options={snippetOptions}
                    {...(!!includesAPIKey && { footer: apiKeyDropdown })}
                  />
                )}
              </div>
            </React.Fragment>
          );
        })}

        <div className={bem('-setup-card-grid-item')}>
          Try It! Make an API call and see your data populated in ReadMe.
        </div>
        <div className={bem('-setup-card-grid-item', '-listener', '-code')}>
          {log ? (
            <>
              <Icon color="green" name="check" />
              <span>Request received!</span>
            </>
          ) : (
            <>
              <Spinner size="sm" strokeWidth={2} />
              <span>Listening for your call…</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BaseInstructions;
