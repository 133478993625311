import { useContext } from 'react';

import type { ProjectContextValue, ConfigContextValue } from '@core/context';
import { ProjectContext, ConfigContext } from '@core/context';
import { useProjectStore } from '@core/store';

import type { VariableDefaults } from '@routes/Dash/Project/Setup/types';

export default function useVariableDefaults(): VariableDefaults {
  const { project: projectFromContext } = useContext(ProjectContext) as ProjectContextValue;
  const [variableDefaultsFromStore = []] = useProjectStore(store => [store.data.variable_defaults]);

  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const variableDefaults = isHub ? variableDefaultsFromStore : projectFromContext.variableDefaults;

  // Reduce our variables into a set keyed by their type.
  return variableDefaults.reduce<VariableDefaults>(
    (prev, next) => {
      if (next) {
        const key = next?.source || 'custom';
        prev[key].push({ ...next, source: next.source || '' });
      }

      return prev;
    },
    { server: [], security: [], custom: [] },
  );
}
