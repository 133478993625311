import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';
import type { DateRangeKey } from '@core/types/metrics';

import Flex from '@ui/Flex';
import LineGraph from '@ui/LineGraph';
import { dataMultiDemo, dataSingleDemo, demoDataOptions } from '@ui/LineGraph/mock';
import Spinner from '@ui/Spinner';

import styles from './style.module.scss';

interface MetricsFallbackProps {
  /** Whether fallback is being rendered in Dashboard overview */
  isDash: boolean;
  isLoading: boolean;
  title: string;
}

const MetricsFallback = ({ title: renderFallbackTitle, isDash, isLoading }: MetricsFallbackProps) => {
  const bem = useClassy(styles, 'MetricsFallback');

  const [selectedDateRangeKey, dateRanges, metricsPageConfig] = useMetricsStore(s => [
    s.selectedDateRangeKey,
    s.dateRanges,
    s.metricsPageConfig,
  ]);

  const dateRange = useMemo(() => {
    if (!dateRanges) return null;
    return dateRanges[selectedDateRangeKey as keyof typeof dateRanges];
  }, [dateRanges, selectedDateRangeKey]);

  const demoChartData = useMemo(() => {
    if (!dateRange) return null;

    const chartTitle = metricsPageConfig.title || renderFallbackTitle || '';

    const rangeLength = isDash ? 7 : dateRange.rangeLength;
    const resolution = isDash ? 'day' : dateRange.resolution;

    return chartTitle === 'API Calls'
      ? dataSingleDemo(rangeLength as number, resolution as DateRangeKey)
      : dataMultiDemo(rangeLength as number, resolution as DateRangeKey);
  }, [dateRange, isDash, renderFallbackTitle, metricsPageConfig.title]);

  const opts = demoDataOptions(isDash);

  return (
    <Flex align="center" className={bem('&')} justify="center" style={{ height: '100%' }}>
      {isLoading || !demoChartData ? (
        <Spinner size="lg" />
      ) : (
        <LineGraph
          className="MetricsGraph-chart"
          data={demoChartData}
          options={opts}
          style={{ position: 'relative', width: '100%' }}
        />
      )}
    </Flex>
  );
};

export default MetricsFallback;
