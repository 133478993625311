import kebabcase from 'lodash/kebabCase';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Link } from '@Dash/Link';

import useClassy from '@core/hooks/useClassy';
import { useMetricsStore } from '@core/store';

import Button from '@ui/Button';
import ButtonGroup from '@ui/ButtonGroup';
import Flex from '@ui/Flex';

import styles from './style.module.scss';

interface Props {
  onShortcutClick: (shortcut: string) => void;
}

const MetricsPageShortcuts = ({ onShortcutClick }: Props) => {
  const bem = useClassy(styles, 'MetricsPageShortcuts');
  const { url } = useRouteMatch();
  const [metricsPageConfig] = useMetricsStore(s => [s.metricsPageConfig]);

  return (
    <Flex align="baseline" className={bem('&')} gap="sm" justify="start">
      <small key="MetricsPage-shortcuts-label" className={bem('-label')}>
        Shortcuts
      </small>
      <ButtonGroup key="MetricsPage-shortcuts-links" highlightSelection={false}>
        {Object.keys(metricsPageConfig?.shortcuts || {}).map(shortcut => {
          return (
            <Button
              key={`MetricsPage-shortcuts-link_${shortcut}`}
              className={bem('-link')}
              exact="true"
              // @ts-ignore
              is={Link as React.ElementType}
              kind="secondary"
              onClick={() => onShortcutClick(shortcut)}
              outline
              replace
              size="sm"
              to={`${url}#${kebabcase(shortcut)}`}
            >
              {shortcut}
            </Button>
          );
        })}
      </ButtonGroup>
    </Flex>
  );
};

export default MetricsPageShortcuts;
