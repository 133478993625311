import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useMetricsStore } from '@core/store';

import Flex from '@ui/Flex';

import GraphType from './GraphType';
import Group from './Group';
import Method from './Method';
import Path from './Path';
import Reset from './Reset';
import Settings from './Settings';
import Status from './Status';
import Vote from './Vote';

const filters = { Vote, Group, Path, Method, Status };

export default function MetricsFilters({ isSuperhub }) {
  const [resetQuery, metricsPageConfig] = useMetricsStore(s => [s.resetQuery, s.metricsPageConfig]);

  const resetTableQuery = useCallback(() => {
    resetQuery('tableQuery');
  }, [resetQuery]);

  const isPageQuality = metricsPageConfig.endpoint === 'pageQuality';

  // The "None" option for Page Quality is grouping by Vote so this just overrides the label
  const noneOverride = isPageQuality ? 'Vote' : '';

  return (
    <Flex align="center" gap="sm" justify="start">
      {(metricsPageConfig?.filters || [])
        .filter(k => k !== 'default')
        .map(key => {
          if (!(key in filters)) return null;
          const Filter = filters[key];
          return (
            <Filter
              key={`filter-${key}`}
              isSuperhub={isSuperhub}
              noneOverride={noneOverride}
              onChange={resetTableQuery}
            />
          );
        })}
      <GraphType isSuperhub={isSuperhub} />

      {!!isPageQuality && <Settings />}

      <Reset />
    </Flex>
  );
}

MetricsFilters.propTypes = {
  isSuperhub: PropTypes.bool,
};
