import React, { useEffect, useMemo, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './index.module.scss';

interface Props {
  children?: React.ReactNode;
  /**
   * Set to `auto` by default which spans multiple columns only when there are 3
   * or more children. Overridable with an explicit number of columns.
   */
  columns?: number | 'auto';
  /**
   * The amount of space between each child FormGroup.
   */
  formGroupGap?: number | string;
  /**
   * Set to `true` to force the children to fill the parent width.
   */
  fullWidth?: boolean;
  /**
   * Reduces the row and column gap along with the minimum column size for sets
   * of inputs that are visually grouped close together.
   */
  isCondensed?: boolean;
}

/**
 * Generic layout component specific to the Superhub settings forms that renders
 * each child into a responsive 2 column grid layout. On smaller screens they'll
 * wrap into a single column layout.
 */
function FormRow({ children, columns = 'auto', isCondensed = false, fullWidth = false, formGroupGap = '30px' }: Props) {
  const elementRef = useRef<HTMLDivElement>(null);
  const bem = useClassy(styles, 'FormRow');
  const [childCount, setChildCount] = useState(0);

  useEffect(() => {
    const count = elementRef.current?.childNodes?.length;
    if (count) setChildCount(count);
  }, []);

  const style = useMemo(
    () =>
      ({
        '--FormRow-columns': columns === 'auto' ? 2 : columns,
        '--FormRow-child-count': childCount,
        '--FormGroup-gap': formGroupGap,
      }) as React.CSSProperties,
    [childCount, columns, formGroupGap],
  );

  // The default behavior is to only flow into two columns if there are 3 or more children.
  // Explicitly setting the columns prop will override this behavior
  const forceColumnFlow = columns === 'auto' && childCount < 3;

  return (
    <div
      ref={elementRef}
      className={bem(
        '&',
        isCondensed && '&_condensed',
        fullWidth && '_full-width',
        forceColumnFlow && '&_force-column-flow',
      )}
      style={style}
    >
      {children}
    </div>
  );
}

export default FormRow;
