import type { ComponentProps } from 'react';

import React from 'react';

import { useReferenceStore } from '@core/store';

import DotNETInstructions from '@routes/MyDevelopers/Setup/ApiLogs/Instructions/dotnet';
import NodeInstructions from '@routes/MyDevelopers/Setup/ApiLogs/Instructions/node';
import PHPInstructions from '@routes/MyDevelopers/Setup/ApiLogs/Instructions/php';
import PythonInstructions from '@routes/MyDevelopers/Setup/ApiLogs/Instructions/python';
import RubyInstructions from '@routes/MyDevelopers/Setup/ApiLogs/Instructions/ruby';

type PythonInstructionsProps = ComponentProps<typeof PythonInstructions>;
interface ApiLogsInstructionsProps extends PythonInstructionsProps {}

export default function ApiLogsInstructions({
  apiKeyDropdown,
  isQuickstart = false,
  languagePackage,
  log,
  selectedAPIKey,
  setLanguagePackage,
}: ApiLogsInstructionsProps) {
  const language = useReferenceStore(s => s.language.language as NonNullable<typeof s.language.language> | 'dotnet');

  // Instructions get same base props except for Python
  const baseProps = { apiKeyDropdown, isQuickstart, log, selectedAPIKey };

  switch (language) {
    case 'node':
      return <NodeInstructions {...baseProps} />;
    case 'php':
      return <PHPInstructions {...baseProps} />;
    case 'python':
      return (
        <PythonInstructions {...baseProps} languagePackage={languagePackage} setLanguagePackage={setLanguagePackage} />
      );
    case 'ruby':
      return <RubyInstructions {...baseProps} />;
    case 'dotnet':
      return <DotNETInstructions {...baseProps} />;
    default:
      return null;
  }
}
