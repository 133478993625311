import type { CustomBlockMgmtAPIData, CustomBlockMgmtItem, CustomBlockMgmtState } from '../types';

import produce from 'immer';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, Prompt, useHistory } from 'react-router-dom';

import { dashNavigate } from '@Dash/history';

import { BaseUrlContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import usePrevious from '@core/hooks/usePrevious';
import useReadmeApiNext from '@core/hooks/useReadmeApi';
import { useSuperHubStore } from '@core/store';

import ErrorState from '@ui/ErrorState';
import type Modal from '@ui/Modal';
import PartyOwlbert from '@ui/PartyOwlbert';
import Spinner from '@ui/Spinner';

import CustomBlockForm from '../../Form';
import { useCustomBlockMgmtContext } from '../Context';
import SidebarNav from '../SidebarNav';

import classes from './style.module.scss';

/**
 * When we use the `useReadmeApiInfinite` hook to fetch Custom Blocks,
 * we get back an array of pages in `state.data`. This function will flatten that
 * array into a single array of items.
 */
const transformDataToItems = (data: CustomBlockMgmtState['data']) =>
  data?.flatMap(page => page?.data || ([] as CustomBlockMgmtItem[])) || [];

interface ContentProps {
  apiPath: string;
  onLoadNextPage: () => void;
  state: CustomBlockMgmtState;
}

function Content({ apiPath, state: { data, mutate, isLoading }, onLoadNextPage }: ContentProps) {
  const bem = useClassy(classes, 'Content');
  const { tag: routeTag } = useParams<{ tag: string }>();
  const prevRouteTag = usePrevious(routeTag);
  const baseUrl = useContext(BaseUrlContext);
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);
  const history = useHistory();
  const { filter, routeSegment, type } = useCustomBlockMgmtContext();
  const [currentItem, setCurrentItem] = useState<CustomBlockMgmtItem | null>(null);

  const [items, setItems] = useState<CustomBlockMgmtItem[]>(transformDataToItems(data));
  const [willNavigate, setWillNavigate] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  // Handles the case where a user navigates directly to a block's route and the
  // block is off the first page of paginated data. Check on first data load if the
  // tag in the route is in the items list. If it is not, we will fetch the block
  // from the API and add it to the items list.
  const hasSearch = filter.search.length !== 0;
  const initialRouteTag = useRef<string>();
  const [isTagInInitialPageData, setIsTagInInitialPageData] = useState<boolean | undefined>();
  const firstResponseRef = useRef(false);

  useEffect(() => {
    if (isLoading || firstResponseRef.current) return;
    firstResponseRef.current = true;
    initialRouteTag.current = routeTag;

    setIsTagInInitialPageData(() => {
      if (routeTag === 'new' || hasSearch) return true;
      return data?.[0].data.findIndex(item => item.tag === routeTag) !== -1;
    });
  }, [data, hasSearch, isLoading, routeTag]);

  const {
    data: offPageItem,
    isLoading: isOffPageItemLoading,
    error: offPageItemError,
  } = useReadmeApiNext<CustomBlockMgmtAPIData>(
    `${apiPath}/custom_blocks/${initialRouteTag?.current}`,
    {},
    isTagInInitialPageData === false,
  );

  const setCurrentItemFromTag = useCallback(
    tag => {
      const item = items.find(i => i.tag === tag);
      if (item) {
        setCurrentItem(item);
      }
    },
    [items],
  );

  // Find the tag of the item matching the route tag in the items and
  // set it as the current item.
  useEffect(() => {
    if (routeTag === currentItem?.tag) return;
    setCurrentItemFromTag(routeTag);
  }, [currentItem, routeTag, setCurrentItemFromTag]);

  // Don't show an active item in the sidebar if something went wrong fetching
  // an off page block from the API
  useEffect(() => {
    if (!isTagInInitialPageData && offPageItemError) setCurrentItem(null);
  }, [isTagInInitialPageData, offPageItemError]);

  // When the API data changes
  useEffect(() => {
    // The data come back from the api as an array of pages, so flatten that array
    // into a single array of items to display in the sidebar nav.
    let _items = transformDataToItems(data);

    // If an off page block was fetched from the API, prepend it to the items list
    if (!isTagInInitialPageData && offPageItem && !hasSearch) {
      _items = _items.filter(item => item.tag !== offPageItem.data.tag);
      _items = [offPageItem.data, ..._items];
    }

    // Prepend a placeholder item in the sidebar nav when inside "new" route
    if (routeTag === 'new' && !willNavigate && !hasSearch) {
      _items = [{ tag: 'new' }, ..._items];
    }

    setItems(_items);
  }, [data, hasSearch, isTagInInitialPageData, offPageItem, routeTag, willNavigate]);

  useEffect(() => {
    setWillNavigate(false);
  }, [routeTag]);

  // When the route changes
  useEffect(() => {
    // Add a placeholder item in the sidebar nav when navigating to the "new" route
    if (routeTag === 'new' && !willNavigate) {
      setItems(prev => {
        if (prev[0]?.tag === 'new') return prev;
        return [{ tag: 'new' }, ...prev];
      });
    }

    // Remove a placeholder item in the sidebar nav when navigating from the "new" route
    if (routeTag !== 'new' && prevRouteTag === 'new') {
      // Wrapping the state update in a requestAnimationFrame to defer execution
      // until the call stack is complete, avoiding a race condition
      // with the "@Dash/Link". Without this, the placeholder item will
      // be removed from the list before the "@Dash/Link" can navigate
      // to the new route
      requestAnimationFrame(() => {
        setItems(prev => {
          if (prev[0]?.tag !== 'new') return prev;
          return [...prev.slice(1)];
        });
      });
    }
  }, [items, prevRouteTag, routeTag, willNavigate]);

  // When the form is saved, we update the items, revalidate the swr cache,
  // and navigate to the new item's route
  const handleFormSaveSuccess = useCallback(
    ({ data: updatedItem }) => {
      // Set the willNavigate flag to true to prevent the useEffect
      // that adds the placeholder item from running when we mutate
      // the data before leaving the '/new' route
      setWillNavigate(true);

      // Re-set the isBlockInInitialPageData flag as true to prevent
      // a present off page block from getting preprended to the items
      // list when the state data mutates
      setIsTagInInitialPageData(true);

      const newData = produce(data, draft => {
        if (!draft) return draft;

        if (routeTag === 'new') {
          draft[0].data.unshift(updatedItem);
          return draft;
        }

        const pageIdx = draft.findIndex(page => page.data?.findIndex(item => item.tag === routeTag) !== -1);
        const itemIdx = draft[pageIdx].data?.findIndex(item => item.tag === routeTag);
        draft[pageIdx].data[itemIdx] = updatedItem;
        return draft;
      });

      mutate(newData, {
        revalidate: true,
      });

      setIsFormDirty(false);

      if (isSuperHub) {
        history.push(`/content/${routeSegment}/${updatedItem.tag}`);
      } else {
        dashNavigate(`${baseUrl}/${routeSegment}/${updatedItem.tag}`);
      }
    },
    [baseUrl, data, history, isSuperHub, mutate, routeSegment, routeTag],
  );

  const handleFormDeleteSuccess = useCallback(() => {
    setWillNavigate(true);
    const newData = produce(data, draft => {
      if (!draft) return draft;

      if (routeTag === 'new') {
        draft[0].data.shift();
        return draft;
      }

      const pageIdx = draft.findIndex(page => page.data?.findIndex(item => item.tag === routeTag) !== -1);
      const itemIdx = draft[pageIdx].data?.findIndex(item => item.tag === routeTag);
      draft[pageIdx].data.splice(itemIdx, 1);
      return draft;
    });
    mutate(newData, {
      revalidate: true,
    });
    setCurrentItem(newData ? newData[0].data[0] : null);
    dashNavigate(`${baseUrl}/${routeSegment}`);
  }, [baseUrl, data, mutate, routeSegment, routeTag]);

  const handleFormCancel = useCallback(() => {
    if (routeTag === 'new') {
      // Redirect to the first item in the list if cancelling a create form.
      dashNavigate(`${baseUrl}/${routeSegment}/${items[1].tag}`);
    }
  }, [baseUrl, items, routeSegment, routeTag]);

  const handleFormChange = useCallback(({ isDirty }) => {
    setIsFormDirty(isDirty);
  }, []);

  const hasNextPage = useMemo(() => {
    if (!data) return false;
    const endPage = data[data.length - 1];
    return endPage.paging?.next !== null;
  }, [data]);

  const party = useRef<Modal>(null);

  const makeParty = () => {
    // janky way to check for RC blocks but items gets updated too quickly
    if (data?.[0].data.length === 0) party.current?.toggle(true);
  };

  return !isTagInInitialPageData && isOffPageItemLoading ? (
    <div className={bem('_loading')}>
      <Spinner size="lg" />
    </div>
  ) : (
    <div className={bem('-layout')}>
      <SidebarNav
        currentItem={currentItem}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        items={items}
        loadNextPage={onLoadNextPage}
      />
      {currentItem === null ? (
        <ErrorState title={type === 'content' ? 'Reusable Content not found.' : 'Components not found.'} />
      ) : (
        <div className={bem('-form-wrapper')} data-testid="reusable-content-form">
          <CustomBlockForm
            key={currentItem?.source || 'new'}
            item={currentItem.tag === 'new' ? { type } : currentItem}
            onCancel={handleFormCancel}
            onChange={handleFormChange}
            onDeleteSuccess={handleFormDeleteSuccess}
            onSaveSuccess={e => {
              handleFormSaveSuccess(e);
              makeParty();
            }}
            willDismissOnCancel={routeTag === 'new'}
          />
          <Prompt message={'Are you sure you want to leave? Changes you made may not be saved.'} when={isFormDirty} />
        </div>
      )}
      <PartyOwlbert
        content={`First ${type === 'content' ? 'Reusable Content Block' : 'Custom Component'}`}
        disappear={2}
        partyRef={party}
      />
    </div>
  );
}

export default Content;
