import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuHeader, MenuItem, MenuDivider } from '@ui/Menu';
import Toggle from '@ui/Toggle';

import Segments from '../Segments';

import styles from './index.module.scss';

const BottomBar = () => {
  const bem = useClassy(styles, 'BottomBar');
  const { type } = useParams<{ type: MyDevelopersSubrouteType }>();

  const navigateToSetupRoute = useCallback((hashRoute: string) => {
    window.location.hash = hashRoute;
  }, []);

  const isDemo = type === MyDevelopersSubrouteType.Demo;
  const togglePath = useMemo(() => {
    return !isDemo ? '/my-developers/demo' : '/my-developers';
  }, [isDemo]);

  return (
    <div className={bem('&')}>
      <div className={bem('-container')}>
        <Segments isHub />

        <div className={bem('-controls')}>
          <Dropdown align="top" justify="end" sticky>
            <Button ghost kind="secondary" size="sm">
              <Icon name="settings" />
            </Button>
            <Menu>
              <MenuHeader>Configuration</MenuHeader>
              <MenuItem icon={<Icon name="key" />} onClick={() => navigateToSetupRoute('/content/personalized-docs')}>
                Personalized Docs
              </MenuItem>
              <MenuItem icon={<Icon name="users" />} onClick={() => navigateToSetupRoute('/my-developers/setup')}>
                My Developers
              </MenuItem>
              <MenuDivider />
              <MenuItem>
                <Toggle
                  checked={isDemo}
                  label="View Demo Data"
                  onChange={() => navigateToSetupRoute(togglePath)}
                  type="toggle"
                />
              </MenuItem>
            </Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
