import base64url from 'base64url';
import { useCallback, useContext } from 'react';

import { dashNavigate } from '@Dash/history';

import { BaseUrlContext, ConfigContext } from '@core/context';
import type { ConfigContextValue } from '@core/context';
import { MyDevelopersSubrouteType } from '@core/enums/metrics';

interface BuildUrlOptions {
  /** The identifier in URL (i.e. the type's slug) */
  identifier?: string;
  /** Optional request ID to append to the URL, used in MetricsSearch result links */
  requestId?: string;
  /** The subroute type */
  type?: MyDevelopersSubrouteType.Key | MyDevelopersSubrouteType.Segment | MyDevelopersSubrouteType.User;
}

/**
 * Helper hook to help with navigation of MyDevelopers subroutes
 * by obfuscating away Hub vs. Dash differences
 */
export default function useMyDevelopers() {
  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const baseUrl = useContext(BaseUrlContext);

  const isHub = name === 'Hub';
  const isDash = name === 'Dash';

  /**
   * Handles navigation based on App type (Dash or Hub)
   */
  const navigate = useCallback(
    url => {
      if (isDash) {
        dashNavigate(url);
        return;
      }

      if (isHub) {
        window.location.hash = url;
      }
    },
    [isDash, isHub],
  );

  /**
   * Builds URL based on the current App (Dash or Hub) and provided type and identifier
   */
  const buildUrl = useCallback(
    ({ type, identifier, requestId }: BuildUrlOptions = {}) => {
      const basePath = isHub ? '/my-developers' : `${baseUrl}/metrics/developers`;

      if (!type || !identifier) {
        return basePath;
      }

      const routes = {
        [MyDevelopersSubrouteType.Segment]: `${basePath}/segment/${identifier}`,
        [MyDevelopersSubrouteType.User]: `${basePath}/user/${identifier}`,
        [MyDevelopersSubrouteType.Key]: (() => {
          const encodedIdentifier = base64url.encode(identifier);
          const keyPath = `${basePath}/key/${encodedIdentifier}`;
          return requestId ? `${keyPath}?requestId=${requestId}` : keyPath;
        })(),
      };

      return routes[type] || basePath;
    },
    [baseUrl, isHub],
  );

  return {
    buildUrl,
    navigate,
  };
}
