import type { ProjectSchema } from '@readme/backend/models/project/types';

import React, { useContext } from 'react';

import { ProjectContext } from '@core/context';
import useToggleThumbs from '@core/hooks/useToggleThumbs';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Menu, { MenuItem } from '@ui/Menu';
import Spinner from '@ui/Spinner';
import Toggle from '@ui/Toggle';

const Settings = () => {
  const { project } = useContext(ProjectContext);
  const { metrics } = project as ProjectSchema;
  const { toggle, isLoading } = useToggleThumbs();

  return (
    <Dropdown align="bottom" justify="start" sticky>
      <Button dropdown kind="secondary" outline size="sm">
        <i aria-label="Settings" className="icon-settings" />
      </Button>
      <Menu>
        <MenuItem focusable={false}>
          <Flex align="center" justify="between">
            <span>Allow users to vote</span>
            {isLoading ? (
              <Flex justify="center" style={{ width: '30px' }}>
                <Spinner size="sm" />
              </Flex>
            ) : (
              <Toggle checked={metrics.thumbsEnabled} onChange={toggle} type="toggle" />
            )}
          </Flex>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default Settings;
