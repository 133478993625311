import type { StandardConfig } from './config';
import type { ValidAPIObjectValue } from '../core/cartographer/mapper/types';
import type { RMRequestContextData } from '@fastify/request-context';
import type { Sections } from '@readmeio/gitto/client';

import { z } from 'zod';

import { Cartographer } from '../core/cartographer';

/*
 * Empty response bodies are defined here: https://github.com/fastify/fastify-swagger#empty-body-responses
 * and in reality turn into something like this
 *
 *     "responses": {
 *        "204": {
 *          "description": "No content"
 *        }
 *      }
 */
export const EmptyResponseBody = z.void();

// The `any` typing on this sucks but `@fastify/multipart` rewrites our file in `req.body`
// before Zod does any validation.
export const FileUploadBody = z.any();

export const nonEmptyString = z
  .string()
  .transform(string => string?.trim())
  .pipe(z.string().min(1));

/**
 * This helper type enforces consistency of context data and config across all readme cartographers.
 */
export class ReadMeCartographer<
  DocumentType,
  RepresentationType extends ValidAPIObjectValue | z.AnyZodObject,
  Context extends Record<string, any> = Partial<RMRequestContextData>,
> extends Cartographer<DocumentType, RepresentationType, Context, StandardConfig> {}

/**
 * coerces string to an array
 */
export const StringToArrayCoercion = (enums: Record<string, string>) =>
  z
    .preprocess(
      param => {
        if (typeof param === 'string') {
          return param.split(',');
          // if array make sure every value in array is string
        } else if (Array.isArray(param) && param.every(value => typeof value === 'string')) {
          return param;
        }
        return [];
      },
      z.array(z.nativeEnum(enums)),
    )
    .optional();

// Don't want to include custom_blocks in the union type
type ExcludeSections = Exclude<keyof typeof Sections, 'custom_blocks'>;
type SectionValues = (typeof Sections)[ExcludeSections] | 'changelog' | 'discuss';
export function getSectionURI(type: SectionValues) {
  switch (type) {
    case 'docs':
      return 'guides';

    case 'reference':
    case 'custom_pages':
    case 'recipes':
    case 'changelog':
    case 'discuss':
    default:
      return type;
  }
}
