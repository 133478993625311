import React from 'react';

import useClassy from '@core/hooks/useClassy';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';

import classes from './index.module.scss';
import TooltipWrapper from './TooltipWrapper';

type ProjectNavigationButtonProps = Pick<ButtonProps, 'dropdown' | 'onClick'> & {
  children: React.ReactNode;
  className?: string;
  /**
   * Whether to show a tooltip on hover, displaying the aria-label as the tooltip content.
   * This is useful for icon-only buttons that have no visible text.
   */
  showTooltip?: boolean;
};

/**
 * Used to render a consistent Button variant in the project navigation.
 */
function ProjectNavigationButton(
  { children, dropdown, onClick, showTooltip = false, ...props }: ProjectNavigationButtonProps,
  ref,
) {
  const bem = useClassy(classes, 'ProjectNavigationButton');

  return (
    <TooltipWrapper className={bem('&-tooltip')} content={props['aria-label']} showTooltip={showTooltip}>
      <Button
        ref={ref}
        circular
        className={bem('&')}
        dropdown={dropdown}
        kind="secondary"
        onClick={onClick}
        size="sm"
        text
      >
        {children}
      </Button>
    </TooltipWrapper>
  );
}

export default React.forwardRef(ProjectNavigationButton);
