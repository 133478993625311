import type { PlanFeatureKey } from '@readme/iso';

import { getFeaturePlan } from '@readme/iso';
import React from 'react';

import useProjectPlan from '@core/hooks/useProjectPlan';

import { useSuperHubContext } from '@routes/SuperHub/Layout/Context';
import PlanAccessBadge from '@routes/SuperHub/Layout/PlanAccess/Badge';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';

interface AppearanceNavItem {
  icon: string;
  label: string;
  planFeature: PlanFeatureKey | undefined;
  routeSection: string;
}

const appearanceRoutes = [
  {
    icon: 'brush',
    label: 'Theme',
    routeSection: 'theme-editor',
    planFeature: undefined,
  },
  {
    icon: 'compass',
    label: 'Navigation',
    routeSection: 'navigation',
    planFeature: 'usability',
  },
  {
    icon: 'layout-footer',
    label: 'Footer',
    routeSection: 'footer',
    planFeature: 'whitelabel',
  },
] as const satisfies AppearanceNavItem[];

const layoutRoutes = [
  {
    icon: 'megaphone',
    label: 'Changelog',
    routeSection: 'changelog',
    planFeature: 'changelogs',
  },
  {
    icon: 'code',
    label: 'API Reference',
    routeSection: 'api-reference',
    planFeature: 'reference',
  },
] as const satisfies AppearanceNavItem[];

const customCodeRoutes = [
  {
    icon: 'file-css',
    label: 'CSS',
    routeSection: 'custom-css',
    planFeature: 'stylesheet',
  },
  {
    icon: 'file-js',
    label: 'Javascript',
    routeSection: 'custom-js',
    planFeature: 'javascript',
  },
  {
    icon: 'file-html',
    label: 'HTML',
    routeSection: 'custom-html',
    planFeature: 'extra_html',
  },
] as const satisfies AppearanceNavItem[];

export const appearanceNavigationItems = [...appearanceRoutes, ...layoutRoutes, ...customCodeRoutes] as const;

const menuSections = [
  { section: '', items: appearanceRoutes },
  { section: 'Layout', items: layoutRoutes },
  { section: 'Custom Code', items: customCodeRoutes },
] as const;

interface AppearanceNavigationMenuProps {
  activeRouteSection?: (typeof appearanceNavigationItems)[number]['routeSection'];
  className?: string;
}

/**
 * Navigation menu for the appearance settings section.
 */
export default function AppearanceNavigationMenu({ activeRouteSection, className }: AppearanceNavigationMenuProps) {
  const { browserRouterHistory } = useSuperHubContext();
  const { planFeatures, showTrialIndicator } = useProjectPlan();

  return (
    <Menu className={className} data-color-mode="dark" theme="dark">
      {menuSections.map(({ section, items }, index) => (
        <React.Fragment key={section}>
          {!!section && <MenuHeader>{section}</MenuHeader>}
          {items.map(({ icon, label, routeSection, planFeature }) => {
            const featureAccessPermission = planFeature ? planFeatures[planFeature] : false;
            const featureAccessType = showTrialIndicator(planFeature)
              ? 'trial'
              : featureAccessPermission
                ? 'open'
                : 'locked';
            const upgradePlan = planFeature ? getFeaturePlan(planFeature) : null;

            return (
              <MenuItem
                key={routeSection}
                active={activeRouteSection === routeSection}
                onClick={() => {
                  // Use browserRouterHistory to navigate in order to trigger an unsaved changes prompt if the
                  // editor form is dirty.
                  browserRouterHistory.push({
                    hash: `#/appearance/${routeSection}`,
                  });
                }}
              >
                <Flex align="center">
                  <span>
                    <Icon name={icon} />
                    {label}
                  </span>
                  {!!planFeature && !!upgradePlan && (
                    <PlanAccessBadge
                      access={featureAccessType}
                      align="bottom"
                      iconOnly
                      justify="center"
                      plan={upgradePlan}
                    />
                  )}
                </Flex>
              </MenuItem>
            );
          })}
          {index < Object.keys(menuSections).length - 1 && <MenuDivider />}
        </React.Fragment>
      ))}
    </Menu>
  );
}
