import type { ProjectSchema } from '@readme/backend/models/project/types';

import * as Sentry from '@sentry/browser';
import sha512 from 'crypto-js/sha512';
import { useContext, useEffect, useMemo, useState } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import useSaveProject from '@core/hooks/useSaveProject';
import { useProjectStore } from '@core/store';
import type { MetricsSDKSetupLog } from '@core/types/metrics';

function useMetricsWebSocket(token: string, log: (log: MetricsSDKSetupLog) => void, isQuickstart?: boolean) {
  const { project } = useContext(ProjectContext);
  const { modules, onboardingCompleted, subdomain } = project as ProjectSchema;
  const { metrics, name } = useContext(ConfigContext) as ConfigContextValue;
  const { saveProject: saveProjectV1 } = useSaveProject();
  const [saveProjectV2] = useProjectStore(store => [store.save]);

  const { track } = useAmplitude();

  const [responseReceived, setResponseReceived] = useState(false);

  const query = useMemo(() => {
    if (!token) return '';
    const authHeader = sha512(token).toString();
    return new URLSearchParams(`token=${authHeader}&subdomain=${subdomain}`);
  }, [subdomain, token]);

  useEffect(() => {
    if (!token) return () => {};

    let socket: WebSocket;

    function setupSocket() {
      try {
        socket = new WebSocket(new URL(`?${query}`, metrics?.wsUrl));

        socket.addEventListener('message', async ({ data }) => {
          log(JSON.parse(data));

          // Mark onboarding logs step as complete
          const newOnboardingCompleted = { logs: true, metricsSDK: true };
          if (name === 'Dash') {
            // in dash, perform a complete update via v1
            await saveProjectV1({ onboardingCompleted: { ...onboardingCompleted, ...newOnboardingCompleted } });
          } else {
            // in superhub, perform a partial update via v2
            await saveProjectV2({ onboarding_completed: newOnboardingCompleted });
          }

          track(AMPLITUDE_EVENT.METRICS_SDK_SUCCESS, {
            type: isQuickstart ? AMPLITUDE_EVENT_PROPERTY.QUICKSTART : AMPLITUDE_EVENT_PROPERTY.SETUP,
          });

          setResponseReceived(true);

          socket.close();
        });

        socket.addEventListener('error', event => {
          Sentry.captureException(event);
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }

    if (token && !responseReceived) setupSocket();

    // Close socket on unmount
    return () => {
      socket?.close();
    };
  }, [
    isQuickstart,
    log,
    metrics,
    modules,
    name,
    onboardingCompleted,
    query,
    responseReceived,
    saveProjectV1,
    saveProjectV2,
    subdomain,
    token,
    track,
  ]);
}

export default useMetricsWebSocket;
