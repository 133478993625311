import React, { useState, useEffect, useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import APIMethod from '@ui/API/Method';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import styles from './style.module.scss';

type HTTPMethod = 'DELETE' | 'GET' | 'POST' | 'PUT';

interface APILogEntry {
  credentials: string;
  email: string;
  id: string;
  method: HTTPMethod;
  path: string;
  status: number;
}

interface StatusCount {
  credentials?: string;
  email?: string;
  id: string;
  isSuccess: boolean;
  method?: HTTPMethod;
  path?: string;
  status?: number;
  timestamp?: Date;
}

interface UserStats {
  company: string;
  companyDomain: string;
  errorRate: number;
  name: string;
  profileImage?: string;
  timeToSuccess: string;
  topEndpoints: {
    method: APILogEntry['method'];
    path: string;
    percentage: number;
  }[];
  topErrors: {
    code: number;
    message: string;
    percentage: number;
  }[];
  totalCalls: number;
}

// Define consistent email mappings
const EMAIL_MAPPINGS = [
  { name: 'bella', domain: 'nightowl.com' },
  { name: 'justina', domain: 'whooshoot.com' },
  { name: 'greg', domain: 'beakon.io' },
  { name: 'adam', domain: 'hootmagic.so' },
  { name: 'ashley', domain: 'owlsome.com' },
  { name: 'ryan', domain: 'beakon.io' },
  { name: 'asha', domain: 'nightowl.com' },
  { name: 'gabe', domain: 'whooshoot.com' },
  { name: 'jaclyn', domain: 'owlsome.com' },
];

const generateRandomLog = (): APILogEntry => {
  const rand = Math.random();
  const status =
    rand < 0.8 ? [200, 201][Math.floor(Math.random() * 2)] : [400, 401, 403, 404, 500][Math.floor(Math.random() * 5)];

  const emailMapping = EMAIL_MAPPINGS[Math.floor(Math.random() * EMAIL_MAPPINGS.length)];

  return {
    id: Math.random().toString(36).substring(7),
    status,
    method: ['GET', 'POST', 'PUT'][Math.floor(Math.random() * 3)] as HTTPMethod,
    path: ['api/v1/users', 'api/v1/docs', 'api/v1/projects', 'api/v1/metrics'][Math.floor(Math.random() * 4)],
    email: `${emailMapping.name}@${emailMapping.domain}`,
    credentials: `••••${Math.random().toString(36).substring(2, 6)}`,
  };
};

const TOTAL_BARS = 85;

const generateInitialData = () => {
  const initialCalls = Math.floor(100 + Math.random() * 900); // Random number between 100-1000
  const initialErrorRate = 0.05 + Math.random() * 0.15; // Random error rate between 5-20%
  const initialErrors = Math.floor(initialCalls * initialErrorRate);
  const initialSuccesses = initialCalls - initialErrors;

  // Generate initial status history
  const history = new Array(TOTAL_BARS).fill(null).map(() => {
    const isSuccess = Math.random() > initialErrorRate;
    const log = generateRandomLog();
    return {
      ...log,
      id: Math.random().toString(),
      isSuccess,
      status: isSuccess ? 200 : 400,
      timestamp: new Date(),
    };
  });

  return {
    history,
    totalCalls: initialCalls,
    totalErrors: initialErrors,
    totalSuccesses: initialSuccesses,
  };
};

// Helper function to calculate endpoint percentages
const calculateEndpointStats = (statusHistory: StatusCount[], email: string) => {
  // Filter logs for this user
  const userLogs = statusHistory.filter(log => log.email === email);
  const totalUserCalls = userLogs.length;

  if (totalUserCalls === 0) return [];

  // Count occurrences of each endpoint
  const endpointCounts = userLogs.reduce(
    (acc, log) => {
      const key = `${log.method}:${log.path}`;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    },
    {} as Record<string, number>,
  );

  // Convert to percentage and format
  const endpointStats = Object.entries(endpointCounts)
    .map(([key, count]) => {
      const [method, path] = key.split(':');
      return {
        method: method as APILogEntry['method'],
        path,
        percentage: Math.round((count / totalUserCalls) * 100),
      };
    })
    .sort((a, b) => b.percentage - a.percentage)
    .slice(0, 3); // Get top 3

  return endpointStats;
};

const getUserStats = (email: string, statusHistory: StatusCount[]): UserStats => {
  const [name, domain] = email.split('@');
  const [companyName] = domain.split('.');

  // Calculate actual endpoint stats
  const topEndpoints = calculateEndpointStats(statusHistory, email);

  // If no data available, provide fallback random percentages
  const fallbackEndpoints = [
    {
      method: 'GET' as HTTPMethod,
      path: '/api/v1/users',
      percentage: Math.floor(Math.random() * 20) + 10,
    },
    {
      method: 'POST' as HTTPMethod,
      path: '/api/v1/docs',
      percentage: Math.floor(Math.random() * 10) + 5,
    },
    {
      method: 'PUT' as HTTPMethod,
      path: '/api/v1/projects',
      percentage: Math.floor(Math.random() * 5) + 3,
    },
  ];

  return {
    name: name.charAt(0).toUpperCase() + name.slice(1),
    company: companyName.charAt(0).toUpperCase() + companyName.slice(1),
    companyDomain: domain,
    totalCalls: Math.floor(1000 + Math.random() * 9000),
    errorRate: Number((Math.random() * 5).toFixed(1)),
    profileImage: undefined,
    timeToSuccess: `${Math.floor(5 + Math.random() * 25)} min`,
    topEndpoints: topEndpoints.length > 0 ? topEndpoints : fallbackEndpoints,
    topErrors: [
      {
        code: 401,
        message: 'Unauthorized',
        percentage: 24,
      },
      {
        code: 404,
        message: 'Not Found',
        percentage: 14,
      },
      {
        code: 403,
        message: 'Forbidden',
        percentage: 4,
      },
    ],
  };
};

const getRandomColor = () => {
  const colors = [
    'rgba(var(--blue-rgb), 0.5)', // $blue
    'rgba(var(--green-rgb), 0.5)', // $green
    'rgba(var(--purple-rgb), 0.5)', // $purple
    'rgba(var(--red-rgb), 0.5)', // $red
    'rgba(var(--yellow-rgb), 0.5)', // $yellow
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

export default function MyDevelopersHero() {
  const bem = useClassy(styles, 'MyDevelopersHero');

  const [logs, setLogs] = useState<APILogEntry[]>(() => new Array(5).fill(null).map(generateRandomLog));
  const [animatingLogIds, setAnimatingLogIds] = useState<Set<string>>(new Set());
  const [activeBars, setActiveBars] = useState<Set<number>>(
    () => new Set(Array.from({ length: TOTAL_BARS }, (_, i) => i)),
  );
  const [barHeights, setBarHeights] = useState<number[]>(() =>
    new Array(TOTAL_BARS).fill(0).map(() => Math.floor(30 + Math.random() * 70)),
  );

  // Initialize all state with generated data
  const initialData = useMemo(() => generateInitialData(), []);
  const [statusHistory, setStatusHistory] = useState<StatusCount[]>(initialData.history);
  const [totalCalls, setTotalCalls] = useState(initialData.totalCalls);

  // Calculate error rate
  const errorRate = useMemo(() => {
    if (totalCalls === 0) return '0.0';
    return ((initialData.totalErrors / totalCalls) * 100).toFixed(1);
  }, [totalCalls, initialData.totalErrors]);

  const [isPaused, setIsPaused] = useState(false);
  const [isTabVisible, setIsTabVisible] = useState(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  // Add visibility change handler
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Separate effect for handling the interval
  useEffect(() => {
    if (isPaused || !isTabVisible) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      return undefined;
    }

    intervalRef.current = setInterval(() => {
      const newLog = generateRandomLog();

      setLogs(prevLogs => [...prevLogs, newLog].slice(-5));

      setTimeout(() => {
        setLogs(currentLogs => {
          const middleLog = currentLogs[2];
          if (middleLog.status >= 400) {
            setTotalCalls(prev => prev + 1);
          } else {
            setTotalCalls(prev => prev + 1);
          }
          setStatusHistory(prev => [
            ...prev.slice(1),
            {
              id: middleLog.id,
              isSuccess: middleLog.status < 400,
              method: middleLog.method,
              path: middleLog.path,
              status: middleLog.status,
              timestamp: new Date(),
              email: middleLog.email,
              credentials: middleLog.credentials,
            },
          ]);

          return currentLogs;
        });
      }, 0);

      setAnimatingLogIds(prev => {
        const next = new Set(prev);
        next.add(newLog.id);
        return next;
      });

      setBarHeights(prev => [...prev.slice(1), Math.floor(30 + Math.random() * 70)]);

      setActiveBars(prev => {
        const next = new Set<number>();
        [...prev].forEach(index => {
          if (index > 0) next.add(index - 1);
        });
        next.add(TOTAL_BARS - 1);
        return next;
      });
    }, 2000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [isPaused, isTabVisible]);

  const handleAnimationEnd = (logId: string) => {
    setAnimatingLogIds(prev => {
      const next = new Set(prev);
      next.delete(logId);
      return next;
    });
  };

  return (
    <>
      <div className={bem('-chart-container')}>
        {statusHistory.map((status, index) => {
          const isActive = activeBars.has(index);

          return (
            <Tooltip
              key={`${status.id}-${index}`}
              content={
                isActive && status.status ? (
                  <div className={bem('-tooltip-content')}>
                    <div className={bem('-tooltip-row')}>
                      <span className={bem('-tooltip-label')}>Status:</span>
                      <HTTPStatus status={status.status} />
                    </div>
                    <div className={bem('-tooltip-row')}>
                      <span className={bem('-tooltip-label')}>Method:</span>
                      {!!status.method && <APIMethod fixedWidth type={status.method} />}
                    </div>
                    <div className={bem('-tooltip-row')}>
                      <span className={bem('-tooltip-label')}>Path:</span>
                      <span className={bem('-tooltip-value')}>{status.path}</span>
                    </div>
                    <div className={bem('-tooltip-row')}>
                      <span className={bem('-tooltip-label')}>Time:</span>
                      <span className={bem('-tooltip-value')}>{status.timestamp?.toLocaleTimeString()}</span>
                    </div>
                    {!!status.email && (
                      <div className={bem('-tooltip-row')}>
                        <span className={bem('-tooltip-label')}>Email:</span>
                        <span className={bem('-tooltip-value')}>{status.email}</span>
                      </div>
                    )}
                    {!!status.credentials && (
                      <div className={bem('-tooltip-row')}>
                        <span className={bem('-tooltip-label')}>Credentials:</span>
                        <span className={bem('-tooltip-value')}>{status.credentials}</span>
                      </div>
                    )}
                  </div>
                ) : null
              }
              delay={[200, 0]}
              offset={[0, 20]}
              placement="top"
            >
              <div
                className={bem('-chart-bar', [
                  isActive && 'active',
                  isActive && status.isSuccess && styles.success,
                  isActive && !status.isSuccess && styles.error,
                ])}
                style={{
                  height: isActive ? `${barHeights[index]}%` : '2px',
                }}
              />
            </Tooltip>
          );
        })}
      </div>

      <div className={bem('-counters')}>
        <div className={bem('-counter')}>
          <div className={bem('-counter-label')}>Total API Calls: </div>
          <div className={bem('-counter-value')}>{totalCalls.toLocaleString()}</div>
        </div>
        <div className={bem('-counter')}>
          <div className={bem('-counter-label')}>Error Rate: </div>
          <div className={bem('-counter-value')}>{errorRate}%</div>
        </div>
      </div>

      <div
        className={bem('-logs-container', isPaused && '-paused')}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        {logs.map((log, index) => {
          const isMainLog = index === 2; // The centered log
          const userStats = isMainLog ? getUserStats(log.email, statusHistory) : null;

          return (
            <Tooltip
              key={log.id}
              content={
                isMainLog ? (
                  <div className={bem('-tooltip-user')}>
                    <div className={bem('-tooltip-user-header')}>
                      <div
                        className={bem('-tooltip-user-avatar')}
                        style={{
                          backgroundColor: getRandomColor(),
                        }}
                      >
                        <Icon name="user" size={24} />
                      </div>
                      <div className={bem('-tooltip-user-info')}>
                        <div className={bem('-tooltip-user-name')}>{userStats?.name}</div>
                        <div className={bem('-tooltip-user-active')}>Active 2 min ago</div>
                      </div>
                      <Button
                        className={bem('-tooltip-email-button')}
                        fullWidth
                        kind="contrast"
                        onClick={e => {
                          e.stopPropagation();
                          window.open(`mailto:${log.email}`);
                        }}
                        outline
                        size="sm"
                      >
                        <Icon name="mail" /> Send Email
                      </Button>
                    </div>

                    <div className={bem('-tooltip-section')}>
                      <div className={bem('-tooltip-section-title')}>Overview</div>
                      <div className={bem('-tooltip-metrics')}>
                        <div className={bem('-tooltip-metric')}>
                          <div className={bem('-tooltip-metric-label')}>
                            Time to 200 <Icon name="info" />
                          </div>
                          <div className={bem('-tooltip-metric-value')}>{userStats?.timeToSuccess}</div>
                        </div>
                        <div className={bem('-tooltip-metric')}>
                          <div className={bem('-tooltip-metric-label')}>
                            Error Rate <Icon name="info" />
                          </div>
                          <div className={bem('-tooltip-metric-value')}>{userStats?.errorRate}%</div>
                        </div>
                      </div>
                    </div>

                    <div className={bem('-tooltip-section')}>
                      <div className={bem('-tooltip-section-title')}>Top Endpoints</div>
                      <div className={bem('-tooltip-endpoints')}>
                        {userStats?.topEndpoints.map((endpoint, endpointIndex) => (
                          <div key={endpointIndex} className={bem('-tooltip-endpoint')}>
                            <APIMethod type={endpoint.method} /> {endpoint.path}{' '}
                            <span className={bem('-tooltip-endpoint-percent')}>{endpoint.percentage}%</span>
                          </div>
                        ))}
                      </div>
                      <Button className={bem('-tooltip-all')} kind="success" link size="sm">
                        All Endpoints
                      </Button>
                    </div>

                    <div className={bem('-tooltip-section')}>
                      <div className={bem('-tooltip-section-title')}>Top Errors</div>
                      <div className={bem('-tooltip-errors')}>
                        {userStats?.topErrors.map((error, errorIndex) => (
                          <div key={errorIndex} className={bem('-tooltip-error')}>
                            <HTTPStatus
                              className={bem('-tooltip-error-status')}
                              includeMessage={false}
                              status={error.code}
                            />{' '}
                            - {error.message}
                            <span className={bem('-tooltip-error-percent')}>{error.percentage}%</span>
                          </div>
                        ))}
                      </div>
                      <Button className={bem('-tooltip-all')} kind="success" link size="sm">
                        All Errors
                      </Button>
                    </div>
                  </div>
                ) : null
              }
              delay={[200, 0]}
              disabled={!isMainLog}
              offset={[0, 20]}
              placement="right"
              theme="custom-tooltip"
            >
              <div
                className={bem('-api-log', animatingLogIds.has(log.id) && '-entering', isPaused && '-paused')}
                onAnimationEnd={() => handleAnimationEnd(log.id)}
              >
                <Flex align="center" className={bem('-api-log-content')} gap="xs" justify="between" layout="row">
                  <div className={bem('-status')}>
                    <HTTPStatus status={log.status} />
                  </div>
                  <div className={bem('-method')}>
                    <APIMethod fixedWidth type={log.method} />
                  </div>
                  <div className={bem('-api-path')}>{log.path}</div>
                  <div className={bem('-email')}>{log.email}</div>
                  <div className={bem('-credentials')}>{log.credentials}</div>
                </Flex>
              </div>
            </Tooltip>
          );
        })}
      </div>
    </>
  );
}
