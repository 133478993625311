import type { ProjectSchema } from '@readme/backend/models/project/types';
import type { CaptureContext } from '@sentry/types';

import * as Sentry from '@sentry/browser';
import React, { useCallback, useContext, useState } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import usePlanPermissions from '@core/hooks/usePlanPermissions';
import useRetry from '@core/hooks/useRetry';
import useTimezone from '@core/hooks/useTimezone';
import type { ProjectExportResponse } from '@core/types/metrics';

import Notification, { notify } from '@ui/Notification';

const useMetricsExport = () => {
  const {
    metrics: { exportMaxRetries },
  } = useContext(ConfigContext) as ConfigContextValue;
  const { project } = useContext(ProjectContext);

  const metricsFetch = useMetricsAPIFetcher<ProjectExportResponse>();
  const [exportJobId, setExportJobId] = useState<string | null>();
  const { plan, planOverride } = project as ProjectSchema;

  // Only customers on business or enterprise plans can export metrics
  const canExport = usePlanPermissions(planOverride || plan, 'metricsExports');
  const timezone = useTimezone() || 'UTC';

  const handleError = useCallback(
    (err?: Error) => {
      notify(<Notification kind="error">Something went wrong, please try again.</Notification>);
      // Send along job ID in any errors if we have it
      Sentry.captureException(err, exportJobId ? ({ extra: { jobId: exportJobId } } as CaptureContext) : undefined);
    },
    [exportJobId],
  );

  // Clear out the current job ID, stop polling and display any potential errors
  const clearJobAndExitPolling = useCallback(
    (error?: Error) => {
      if (error) {
        handleError(error);
      }

      setExportJobId(null);
      return true;
    },
    [handleError],
  );

  const createExportJob = useCallback(
    async (body, totalPages) => {
      // We'll show a notification at >= 33.3k pages (1M / 30 items per page) indicating max export limit
      if (totalPages >= 33333) {
        notify(<Notification>Limited Export: Large exports limited to 1,000,000 records</Notification>);
      }

      // Create export job
      try {
        const { response: result } = await metricsFetch({
          path: 'project/export',
          method: 'POST',
          body: {
            ...body,
            timezone,
          },
        });

        if (result && result?.jobId) {
          notify(<Notification>Loading export…</Notification>, {
            position: 'bottom-right',
            id: `exportToast-${result.jobId}`,
          });

          // Set export job ID so we can poll for it
          setExportJobId(result.jobId);
        } else {
          handleError(new Error('Error generating project/export job'));
        }
      } catch (err) {
        handleError(err as Error);
      }
    },
    [handleError, metricsFetch, timezone],
  );

  const pollForExportJob = useCallback(() => {
    return metricsFetch({ path: `project/export?jobId=${exportJobId}` });
  }, [exportJobId, metricsFetch]);

  const handlePollExportJob = useCallback(
    ({ response: { signedUrl, status } }, retryCount) => {
      // If we get back presigned URL, download the file and stop polling
      if (signedUrl) {
        // Update existing toast with success message
        // Note: we need to use setTimeout to avoid event loop collision with link.click below
        setTimeout(() => {
          notify(<Notification kind="success">Export completed</Notification>, {
            position: 'bottom-right',
            id: `exportToast-${exportJobId}`,
          });
        }, 300);

        const link = document.createElement('a');
        link.href = signedUrl;

        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);

        return clearJobAndExitPolling();
      } else if (status === 'failed') {
        return clearJobAndExitPolling(new Error('Export job failed'));
      } else if (retryCount === exportMaxRetries) {
        return clearJobAndExitPolling(new Error('Max retries limit hit and job has not finished'));
      }

      return false;
    },
    [clearJobAndExitPolling, exportJobId, exportMaxRetries],
  );

  // Poll for export job status every 5 seconds
  // Note: default is 12 retries (1 minute) which we can override via env variable
  useRetry({
    isReady: !!exportJobId,
    action: pollForExportJob,
    callback: handlePollExportJob,
    options: { factor: 1, retries: exportMaxRetries, minTimeout: 5000, randomize: false },
  });

  return {
    canExport,
    createExportJob,
    exportJobId,
  };
};

export default useMetricsExport;
