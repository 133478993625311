import React, { useState, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { allLanguages } from '@core/store/Reference/Language/helpers';

import APISectionHeader from '@ui/API/SectionHeader';
import Button from '@ui/Button';
import CopyToClipboard from '@ui/CopyToClipboard';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import LanguagePicker from '@ui/LanguagePicker';
import Tooltip from '@ui/Tooltip';

import styles from './style.module.scss';

export type TooltipSelector = 'auth-section' | 'code-snippet-section';

interface TooltipConfig {
  content: React.ReactNode;
  eyebrow?: string;
  icon?: string;
  position?: 'bottom' | 'left' | 'right' | 'top';
  selector: TooltipSelector;
  title?: string;
}

interface Props {
  apiKey?: string;
  /** Interval between tooltip cycles */
  cycleInterval?: number;
  /** The mock HTTP method for the endpoint */
  method: string;
  /** The mock slug of the endpoint */
  slug: string;
  /** Optional theme override */
  theme?: 'dark' | 'light';
  /** Optional tooltips configuration */
  tooltips?: TooltipConfig[];
}

const MockPlayground = ({
  method = 'POST',
  slug = 'addFoo',
  theme = 'light',
  apiKey,
  tooltips,
  cycleInterval = 3000,
}: Props) => {
  const bem = useClassy(styles, 'MockPlayground');
  const [activeTooltipIndex, setActiveTooltipIndex] = useState<number>(0);
  const [language, setLanguage] = useState('shell');
  const [defaultLanguages, setDefaultLanguages] = useState(['shell', 'node', 'ruby', 'php', 'python']);

  useMemo(() => {
    if (!tooltips?.length) return false;

    return setInterval(() => {
      setActiveTooltipIndex(current => {
        const nextIndex = current + 1;
        return nextIndex >= tooltips.length ? 0 : nextIndex;
      });
    }, cycleInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderWithTooltip = (element: React.ReactNode, selector: string) => {
    if (!tooltips?.length) return element;

    const tooltipIndex = tooltips.findIndex(t => t.selector === selector);
    if (tooltipIndex === -1) return element;

    const tooltip = tooltips[tooltipIndex];
    const isVisible = activeTooltipIndex === tooltipIndex;

    const tooltipContent = (
      <div className={bem('-tooltip-container')}>
        <Flex align="center" className={bem('-tooltip-eyebrow')} direction="row" gap="xs">
          {!!tooltip.icon && <Icon className={bem('-tooltip-eyebrow-icon')} name={tooltip.icon} />}
          <div>{tooltip.eyebrow}</div>
        </Flex>
        <div className={bem('-tooltip-title')}>{tooltip.title}</div>
        <div className={bem('-tooltip-content')}>{tooltip.content}</div>
        {!!isVisible && (
          <div className={bem('-tooltip-progress')}>
            <div className={bem('-tooltip-progress-bar')} style={{ animationDuration: `${cycleInterval}ms` }} />
          </div>
        )}
      </div>
    );

    return (
      <Tooltip
        appendTo="parent"
        className={bem('-tooltip')}
        content={tooltipContent}
        offset={[0, 15]}
        placement={tooltip.position}
        visible={isVisible}
      >
        <div className={bem('', isVisible && '-tooltip-highlight')}>{element}</div>
      </Tooltip>
    );
  };

  return (
    <Flex className={bem('&', theme === 'light' && '_light-theme')} gap="sm" layout="col">
      <section>
        <APISectionHeader heading="Language" />
        <LanguagePicker
          availableLanguages={allLanguages}
          defaultLanguages={defaultLanguages}
          language={language}
          maxLanguages={5}
          updateDefaultLanguages={setDefaultLanguages}
          updateLanguage={lang => {
            if (lang) {
              setLanguage(lang);
            }
          }}
        />
      </section>

      {renderWithTooltip(
        <section className={bem('-auth')}>
          <APISectionHeader heading="Authorization" />
          <Flex>
            <InputGroup columnLayout="auto" separators size="sm">
              <Button text>Header</Button>
              <Input placeholder="api_key" value={apiKey || ''} />
            </InputGroup>
          </Flex>
        </section>,
        'auth-section',
      )}
      {renderWithTooltip(
        <section className={bem('-code-snippet')}>
          <Flex gap={0} layout="col">
            <Flex className={bem('-code-snippet-header')} justify="between">
              <span className={bem('-code-snippet-title')}>Curl</span>
              <Button className={bem('-code-snippet-toggle')} ghost kind="contrast" size="xs" title="Toggle library">
                <Icon name="chevron-down" />
              </Button>
            </Flex>
            <Flex className={bem('-code-snippet-code')} gap="sm" layout="col">
              <span className={bem('-code-snippet-title')}>Request</span>
              <Flex className={bem('-code-snippet-code-inner', 'cm-s-material-palenight')} gap={0} layout="col">
                <Flex gap="xs">
                  <span className={bem('-code-snippet-code-line-number')}>1</span>
                  <span className="cm-builtin">curl</span> <span className="cm-attribute">--request</span> {method} \
                </Flex>
                <Flex gap="xs">
                  <span className={bem('-code-snippet-code-line-number')}>2</span>
                  <span className="cm-attribute" style={{ marginLeft: '35px' }}>
                    --url
                  </span>{' '}
                  <span>{`http://readme.io/v1/${slug}`}</span> \
                </Flex>
                {!!apiKey && (
                  <Flex gap="xs">
                    <span className={bem('-code-snippet-code-line-number')}>2</span>
                    <span className="cm-attribute" style={{ marginLeft: '35px' }}>
                      --header
                    </span>{' '}
                    <span>{`'X-API-KEY: ${apiKey}'`}</span> \
                  </Flex>
                )}
              </Flex>
            </Flex>

            <Flex align="center" className={bem('-code-snippet-footer')} gap="xs">
              <CopyToClipboard className={bem('-code-snippet-copy')} shift="end" text="" />
              <Button>Try It!</Button>
            </Flex>
          </Flex>
        </section>,
        'code-snippet-section',
      )}
      <section className={bem('-response-tryit')}>
        <Flex align="center" className={bem('-response-tryit-header')} gap="md" justify="between" tag="header">
          Response
        </Flex>
        <Flex align="center" className={bem('-response-tryit-empty')} gap="md" justify="between">
          <span>
            Click <code className={bem('-response-tryit-empty-text')}>Try It!</code> to start a request and see the
            response here!
          </span>
        </Flex>
      </section>
    </Flex>
  );
};

export default MockPlayground;
