import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import { useSidebarNavContext, useSidebarNavGroupPositionContext } from '../Context';

import styles from './index.module.scss';

type SidebarNavGroupNameContextValue = string | undefined;

const SidebarNavGroupNameContext = createContext<SidebarNavGroupNameContextValue>(undefined);

export interface SidebarNavGroupProps {
  children?: React.ReactNode;
  className?: string;
  label: string;
  name: string;
}

/**
 * Creates a paginated sub-level of child `SidebarNavSection` or more
 * `SidebarNavGroup` items in addition to a control button to navigate "Back" to
 * the previous group.
 */
export function SidebarNavGroup({ children, className, label, name }: SidebarNavGroupProps) {
  const bem = useClassy(styles, 'SidebarNavGroup');
  const { activeGroup, setActiveGroup } = useSidebarNavContext();
  const groupPositionMap = useSidebarNavGroupPositionContext();
  const parentGroup = useContext(SidebarNavGroupNameContext);

  const contentRef = useRef<HTMLElement>(null);

  // On initial mount, add this group's DOM position to the position Map so we
  // can easily access where this group's DOM position when it becomes "active".
  useEffect(() => {
    if (!contentRef.current) return;
    if (groupPositionMap.has(name)) {
      // eslint-disable-next-line no-console
      console.error(`SidebarNavGroup with name="${name}" is duplicated and will cause unexpected behavior.`);
      return;
    }

    const position = contentRef.current?.getBoundingClientRect();
    groupPositionMap.set(name, position);
  }, [groupPositionMap, name]);

  // When expanding, this group is currently becoming "active". So we update our
  // context to notify higher-up components of this change.
  const handleExpand = useCallback(() => {
    setActiveGroup(name);
  }, [name, setActiveGroup]);

  // When backing out, activate the parent group to this one.
  const handleBack = useCallback(() => {
    setActiveGroup(parentGroup);
  }, [parentGroup, setActiveGroup]);

  return (
    <SidebarNavGroupNameContext.Provider value={name}>
      <Flex align="stretch" className={bem('&', className)} gap="0" layout="col">
        <Flex align="center" className={bem('-button')} gap="sm" onClick={handleExpand} tag="button">
          <span>{label}</span>
          <Icon name="chevron-right" size="md" />
        </Flex>
        <div className={bem('-content-wrapper')}>
          <Flex
            ref={contentRef}
            align="stretch"
            className={bem('-content')}
            data-testid="SidebarNavGroup-content"
            gap="sm"
            hidden={name !== activeGroup}
            justify="start"
            layout="col"
            tag="section"
          >
            <Flex align="stretch" className={bem('-back-nav')} justify="start" layout="col">
              <Flex
                align="center"
                className={bem('-button')}
                gap="sm"
                justify="start"
                onClick={handleBack}
                tag="button"
              >
                <Icon name="chevron-left" size="md" />
                <span>Back</span>
              </Flex>
            </Flex>
            {children}
          </Flex>
        </div>
      </Flex>
    </SidebarNavGroupNameContext.Provider>
  );
}
