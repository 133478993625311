import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Tooltip from '@ui/Tooltip';

import classes from './index.module.scss';

export default function BranchStatusIndicator({ isMinimal }: { isMinimal?: boolean }) {
  const bem = useClassy(classes, 'BranchStatusIndicator');
  return isMinimal ? (
    <Tooltip content="Live and visible to your users." placement="bottom" title>
      <span aria-label="Live" className={bem('&', isMinimal && '_minimal')} />
    </Tooltip>
  ) : (
    <span className={bem('&')}>Live</span>
  );
}
