import type { PlanFeatureKey } from '@readme/iso';

import { getPlanFeature, getFeaturePlan } from '@readme/iso';
import React, { useState } from 'react';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import PurpleBox from '../PurpleBox';

import classes from './index.module.scss';

interface FeatureContent {
  description: string;
  plan: 'Business' | 'Enterprise' | 'Startup';
  planFeature: PlanFeatureKey;
}

export const featureContentMap = {
  css: {
    description: 'Write custom styles to further customize the appearance of your docs.',
    plan: 'Business',
    planFeature: 'stylesheet',
  },
  changelog: {
    description: 'Customize the appearance of your changelog section.',
    plan: 'Startup',
    planFeature: 'changelogs',
  },
  footer: {
    description: 'Customize the appearance of your footer.',
    plan: 'Business',
    planFeature: 'whitelabel',
  },
  javascript: {
    description: 'Write custom JavaScript to build in extra features or customize your project pages.',
    plan: 'Enterprise',
    planFeature: 'javascript',
  },
  html: {
    description: 'Write HTML in the header and footer to add add meta tags or link external assets.',
    plan: 'Business',
    planFeature: 'extra_html',
  },
  navigation: {
    description: 'Customize the content that lives in your top navigation.',
    plan: 'Business',
    planFeature: 'usability',
  },
} as const satisfies Record<string, FeatureContent>;

/**
 * Gates a feature's UI if the user does not have the correct plan permissions, but also prompts the user to demo
 * the feature. If you don't want to prompt the user to demo the feature, use the PayGate component instead.
 */
interface DemoGateProps {
  /**
   * Whether the children content is locked or open.
   * - `locked` - User does not have access to the feature and will be prompted to demo or upgrade.
   * - `open` - User has access to the feature and will see the children content.
   */
  access: 'locked' | 'open';
  children: React.ReactNode;
  /**
   * The feature that is gated. Used to determine the content to display in the overlay.
   */
  feature: keyof typeof featureContentMap;
}

export default function DemoGate({ children, feature, access }: DemoGateProps) {
  const bem = useClassy(classes, 'DemoGate');
  const [isInert, setIsInert] = useState(true);
  const content = featureContentMap[feature];
  const upgradePlan = getFeaturePlan(content.planFeature);
  const upgradePlanLabel = getPlanFeature(upgradePlan, 'name');

  return access === 'open' ? (
    <>{children}</>
  ) : (
    <div className={bem('&')}>
      <PurpleBox className={bem('-banner', !isInert && '-banner_condensed')}>
        {isInert ? (
          <>
            <div className={bem('-body')}>
              <h3 className={bem('-title')}>{upgradePlanLabel} Plan Feature</h3>
              <p className={bem('-description')}>{content.description}</p>
            </div>
            <Flex align="center" className={bem('-footer')} gap="xs" justify="center">
              <Button kind="contrast" onClick={() => setIsInert(false)} size="xs" text uppercase={false}>
                <Icon name="unlock" />
                Demo
              </Button>
              <Button href={PLAN_UPGRADE_URL} kind="contrast" size="xs" target="_blank" uppercase={false}>
                Upgrade
              </Button>
            </Flex>
          </>
        ) : (
          <Flex align="center">
            <h3 className={bem('-title')}>Demoing</h3>
            <Button href={PLAN_UPGRADE_URL} kind="contrast" size="xs" target="_blank" uppercase={false}>
              Upgrade to {upgradePlanLabel}
            </Button>
          </Flex>
        )}
      </PurpleBox>

      <div className={bem('-content')} inert={isInert ? '' : undefined}>
        {children}
      </div>
    </div>
  );
}
