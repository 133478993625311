import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

export default function ResponseExampleCollapsed({ style }: SharedSvgProps) {
  return (
    <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_316_23537)">
        <path d="M0 0h92v62H0z" fill="#F8F8F8" />
        <g filter="url(#filter0_d_316_23537)">
          <path d="M0 0h92v19H0z" fill="#F8F8F8" />
          <path
            d="M5.422 13V6.659h2.421c1.222 0 2.022.747 2.022 1.885v.009c0 .879-.48 1.564-1.275 1.815L10.058 13h-.927l-1.358-2.51h-1.56V13h-.791Zm.79-3.212h1.561c.813 0 1.274-.435 1.274-1.209V8.57c0-.756-.492-1.208-1.31-1.208H6.214v2.426ZM11.303 13V6.659h3.928v.712h-3.137v2.034h2.975v.703h-2.975v2.18h3.137V13h-3.928Zm7.44.15c-1.389 0-2.286-.73-2.378-1.763l-.004-.048h.79l.005.048c.057.633.717 1.033 1.63 1.033.862 0 1.49-.444 1.49-1.09v-.004c0-.528-.364-.888-1.234-1.081l-.704-.154c-1.27-.281-1.819-.866-1.819-1.784v-.005c.005-1.05.923-1.793 2.232-1.793 1.266 0 2.15.747 2.215 1.692l.005.062h-.791l-.01-.057c-.087-.559-.614-.972-1.44-.967-.791.004-1.402.378-1.402 1.041v.005c0 .505.347.848 1.208 1.037l.703.158c1.323.294 1.846.826 1.846 1.74v.005c0 1.186-.927 1.924-2.342 1.924Zm3.66-.15V6.659h2.391c1.19 0 2.035.821 2.035 2.026v.008c0 1.204-.844 2.044-2.035 2.044h-1.6V13h-.79Zm2.189-5.638h-1.398v2.672h1.398c.905 0 1.428-.497 1.428-1.332v-.009c0-.835-.523-1.331-1.428-1.331Zm6.196 5.787c-1.82 0-2.91-1.292-2.91-3.317v-.01c0-2.034 1.117-3.313 2.91-3.313 1.802 0 2.91 1.283 2.91 3.314v.009c0 2.021-1.1 3.317-2.91 3.317Zm0-.73c1.3 0 2.1-1.019 2.1-2.587v-.01c0-1.581-.803-2.583-2.1-2.583-1.292 0-2.1 1.002-2.1 2.584v.009c0 1.568.782 2.588 2.1 2.588Zm4.28.581V6.659h.77l3.44 4.935h.07V6.659h.783V13h-.77l-3.44-4.917h-.07V13h-.783Zm8.759.15c-1.389 0-2.285-.73-2.378-1.763l-.004-.048h.79l.005.048c.057.633.717 1.033 1.63 1.033.862 0 1.49-.444 1.49-1.09v-.004c0-.528-.364-.888-1.235-1.081l-.703-.154c-1.27-.281-1.819-.866-1.819-1.784v-.005c.004-1.05.923-1.793 2.233-1.793 1.265 0 2.148.747 2.214 1.692l.005.062h-.791l-.01-.057c-.087-.559-.614-.972-1.44-.967-.791.004-1.402.378-1.402 1.041v.005c0 .505.347.848 1.208 1.037l.703.158c1.323.294 1.846.826 1.846 1.74v.005c0 1.186-.927 1.924-2.342 1.924Zm3.66-.15V6.659h3.929v.712h-3.138v2.034h2.975v.703h-2.975v2.18h3.138V13h-3.929Z"
            fill="#384248"
          />
        </g>
        <rect fill="#000" fillOpacity=".1" height="6" rx="2.628" width="71" x="10.5" y="26" />
        <rect fill="#000" fillOpacity=".1" height="6" rx="2.628" width="48" x="22" y="35" />
        <g filter="url(#filter1_dii_316_23537)">
          <g filter="url(#filter2_dii_316_23537)">
            <rect fill="#12CA93" height="5.535" rx="2.767" width="5.535" x="17.66" y="47.732" />
            <rect
              height="5.074"
              rx="2.537"
              stroke="#000"
              strokeOpacity=".15"
              strokeWidth=".461"
              width="5.074"
              x="17.891"
              y="47.963"
            />
          </g>
        </g>
        <path
          d="M26.186 54v-.668l2.105-2.21c.839-.875 1.08-1.2 1.08-1.714v-.014c0-.62-.426-1.076-1.12-1.076-.712 0-1.187.466-1.187 1.16l-.004.013h-.923l-.009-.009c.005-1.16.901-1.977 2.176-1.977 1.182 0 2.052.751 2.052 1.797v.013c0 .721-.334 1.275-1.485 2.417l-1.345 1.34v.089h2.931V54h-4.271Zm7.8.154c-1.463 0-2.342-1.279-2.342-3.323v-.008c0-2.044.879-3.318 2.342-3.318 1.459 0 2.342 1.274 2.342 3.318v.008c0 2.044-.883 3.323-2.342 3.323Zm0-.818c.848 0 1.349-.953 1.349-2.505v-.008c0-1.551-.501-2.496-1.35-2.496-.852 0-1.348.945-1.348 2.496v.008c0 1.552.496 2.505 1.349 2.505Zm5.845.818c-1.464 0-2.343-1.279-2.343-3.323v-.008c0-2.044.88-3.318 2.343-3.318 1.459 0 2.342 1.274 2.342 3.318v.008c0 2.044-.883 3.323-2.342 3.323Zm0-.818c.848 0 1.349-.953 1.349-2.505v-.008c0-1.551-.501-2.496-1.35-2.496-.852 0-1.349.945-1.349 2.496v.008c0 1.552.497 2.505 1.35 2.505Z"
          fill="#384248"
        />
        <g filter="url(#filter3_dii_316_23537)">
          <g filter="url(#filter4_dii_316_23537)">
            <rect fill="#E95F6A" height="5.535" rx="2.767" width="5.535" x="48.5" y="47.732" />
            <rect
              height="5.074"
              rx="2.537"
              stroke="#000"
              strokeOpacity=".15"
              strokeWidth=".461"
              width="5.074"
              x="48.731"
              y="47.963"
            />
          </g>
        </g>
        <path
          d="M59.935 54v-1.261H56.84v-.835a86.627 86.627 0 0 1 2.64-4.245h1.403v4.245h.874v.835h-.874V54h-.95Zm-2.15-2.079h2.163V48.49h-.057a72.458 72.458 0 0 0-2.105 3.37v.062Zm7.41 2.233c-1.464 0-2.342-1.279-2.342-3.323v-.008c0-2.044.878-3.318 2.342-3.318 1.459 0 2.342 1.274 2.342 3.318v.008c0 2.044-.883 3.323-2.342 3.323Zm0-.818c.848 0 1.349-.953 1.349-2.505v-.008c0-1.551-.501-2.496-1.35-2.496-.852 0-1.348.945-1.348 2.496v.008c0 1.552.496 2.505 1.349 2.505Zm5.845.818c-1.464 0-2.343-1.279-2.343-3.323v-.008c0-2.044.88-3.318 2.343-3.318 1.459 0 2.342 1.274 2.342 3.318v.008c0 2.044-.883 3.323-2.342 3.323Zm0-.818c.848 0 1.349-.953 1.349-2.505v-.008c0-1.551-.501-2.496-1.35-2.496-.852 0-1.349.945-1.349 2.496v.008c0 1.552.497 2.505 1.35 2.505Z"
          fill="#384248"
        />
      </g>
      <defs>
        <clipPath id="clip0_316_23537">
          <path d="M0 0h92v62H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
