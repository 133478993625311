import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';
import type { RadioProps } from '@ui/Radio';
import Radio from '@ui/Radio';

import styles from './index.module.scss';

interface RadioGroupProps {
  /**
   * List of `<Radio>` elements to group together.
   */
  children: React.ReactNode;
  className?: string;

  /**
   * Render into the full width of the parent container. Useful when rendering
   * in narrower-width containers such as mobile viewports or aside panels.
   */
  fullWidth?: boolean;
}

/**
 * Renders a group of `Radio` input fields in a vertically oriented container
 * and replaces radio icons with a checkmark to indicate which input field is
 * currently checked.
 *
 * @example
 * ```tsx
 * <RadioGroup>
 *   <Radio label="Salad" value="salad" defaultChecked />
 *   <Radio label="Pizza" value="pizza" />
 *   <Radio label="Falafel" value="falafel" />
 * </RadioGroup>
 * ```
 */
export default function RadioGroup({ children, className, fullWidth }: RadioGroupProps) {
  const bem = useClassy(styles, 'RadioGroup');

  const enhancedChildren = useMemo(
    () =>
      React.Children.map(children, element => {
        if (!React.isValidElement(element)) return null;
        if (element.type !== Radio) return element;

        const radio = element as React.ReactElement<RadioProps>;
        return React.cloneElement(radio, {
          ...radio.props,
          children: <Icon name="check" size="sm" wrapperClassName={bem('&-check-icon')} />,
          className: bem('&-radio', radio.props.className),
          key: element.key,
        });
      }),
    [bem, children],
  );

  return <div className={bem('&', fullWidth && '_full-width', className)}>{enhancedChildren}</div>;
}
