import React from 'react';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Graphic from '@ui/Graphic';
import Icon from '@ui/Icon';

import PurpleBox from '../PurpleBox';

import classes from './index.module.scss';

export default function LaunchButton() {
  const bem = useClassy(classes, 'LaunchButton');
  return (
    <Dropdown className={bem('&')} justify="end" trigger="hover">
      <Button circular className={bem('-button')} size="sm" target="_blank" to={PLAN_UPGRADE_URL}>
        Launch
        <Icon name="arrow-up-right" size="sm" />
      </Button>
      <PurpleBox className={bem('-menu')}>
        <p className={bem('-menu-body')}>
          You’re currently on our <strong>free trial</strong> until you’re ready to launch.
        </p>
        <div className={bem('-menu-footer')}>
          <Graphic name="disabled" />
          <p className={bem('-menu-footer-text')}>Docs are only viewable by admins until launch.</p>
        </div>
      </PurpleBox>
    </Dropdown>
  );
}
