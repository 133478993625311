// Wrapper for the @core/utils/saveProject util
// Manages loading state and response handling for you!
import * as Sentry from '@sentry/browser';
import { useCallback, useContext, useState } from 'react';

import { ProjectContext } from '@core/context';
import handleResponse from '@core/utils/handleResponse';
import saveProject from '@core/utils/saveProject';

export default function useSaveProject() {
  const { project, setProject } = useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(false);

  const save = useCallback(
    async (update, callback = r => r) => {
      let response;

      setIsLoading(true);

      try {
        const res = await saveProject(project, update);
        response = await handleResponse(res);
        setProject(callback(response));
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        setIsLoading(false);
      }

      return response;
    },
    [project, setProject],
  );

  return {
    data: project,
    isLoading,
    /** Returns the updated project response */
    saveProject: save,
  };
}
