import type { $TSFixMe } from '@readme/iso';

import React, { useContext } from 'react';

import { ProjectContext } from '@core/context';
import { useMetricsStore } from '@core/store';

import MetricsFallback from './MetricsFallback';
import PageQualityFallback from './PageQualityFallback';

const useFallback = (fallbackName: string, title: string, isDash = false) => {
  const [customerUsage, isUsageReady, isDemo] = useMetricsStore(s => [
    s.customerUsage,
    s.isUsageReady,
    s.myDevelopers.filters.demo,
  ]);
  const {
    project: { metrics },
  } = useContext(ProjectContext) as { project: $TSFixMe };

  const { explorer, sdk } = customerUsage;
  const totalUsage = (explorer?.thirtyDay || 0) + (sdk?.thirtyDay || 0);

  const shouldRenderMetricsFallback = (!totalUsage || !isUsageReady) && !isDemo;
  const isLoading = !isUsageReady;

  const shouldRenderPageQualityFallback = !metrics?.thumbsEnabled;

  switch (fallbackName) {
    case 'page-quality':
      return { shouldRenderFallback: shouldRenderPageQualityFallback, fallback: <PageQualityFallback /> };
    case 'metrics':
      return {
        shouldRenderFallback: shouldRenderMetricsFallback,
        fallback: <MetricsFallback isDash={isDash} isLoading={isLoading} title={title} />,
      };
    default:
      return { shouldRenderFallback: false, fallback: null };
  }
};

export default useFallback;
