import { useCallback, useEffect, useState } from 'react';

import { useSuperHubStore } from '@core/store';

import { animationEasing, animationTiming, prefersReducedMotion } from './Transition';

const hubHeaderClass = 'rm-Header';
const hubFooterClass = 'rm-Banners';

/**
 * Used to hide the Hub's header and footer when the user is editing a document in the SuperHub.
 *
 * @note Because the header can have a dynamic height in SuperHub via live preview in the theme editor,
 * we need to calculate the height of the header at the time of animation, then reset it to 'auto' after the
 * animation completes. This is why we're animating the header imperatively with the Web Animation API instead of
 * using CSS animations.
 */
export default function useHubEditModeTransition() {
  const [isEditing] = useSuperHubStore(s => [s.isEditing]);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const showHeaderFooter = useCallback(async () => {
    const rmHeader = document.querySelector<HTMLElement>(`.${hubHeaderClass}`);
    if (!rmHeader || isHeaderVisible) return;

    // Toggle footer with css class
    document.querySelector(`.${hubFooterClass}`)?.classList.remove(`${hubFooterClass}_hidden`);

    rmHeader.style.overflow = 'hidden';
    rmHeader.style.display = 'block';

    // Temporarily show header to get the element's height
    rmHeader.style.height = 'auto';
    const headerHeight = rmHeader.clientHeight;
    rmHeader.style.height = '0';

    // Animate to the current header height
    const animation = rmHeader?.animate([{ height: 0 }, { height: `${headerHeight}px` }], {
      duration: animationTiming,
      easing: animationEasing,
      fill: 'forwards',
    });

    if (prefersReducedMotion) animation.finish();

    await animation.finished;
    animation.commitStyles();
    animation.cancel();

    // After animation is complete, set height to auto to allow for dynamic height changes
    rmHeader.style.height = 'auto';
    rmHeader.style.overflow = 'initial';
    setIsHeaderVisible(true);
  }, [isHeaderVisible]);

  const hideHeaderFooter = useCallback(async () => {
    const rmHeader = document.querySelector<HTMLElement>(`.${hubHeaderClass}`);
    if (!rmHeader || !isHeaderVisible) return;

    // Toggle footer with css class
    document.querySelector(`.${hubFooterClass}`)?.classList.add(`${hubFooterClass}_hidden`);

    rmHeader.style.overflow = 'hidden';

    const animation = rmHeader.animate([{ height: `${rmHeader.clientHeight}px` }, { height: 0 }], {
      duration: animationTiming,
      easing: animationEasing,
      fill: 'forwards',
    });

    if (prefersReducedMotion) animation.finish();

    await animation.finished;
    animation.commitStyles();
    animation.cancel();
    rmHeader.style.display = 'none';

    setIsHeaderVisible(false);
  }, [isHeaderVisible]);

  useEffect(() => {
    if (isEditing) {
      hideHeaderFooter();
    } else {
      showHeaderFooter();
    }
  }, [hideHeaderFooter, isEditing, showHeaderFooter]);
}
