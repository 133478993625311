import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useToggleThumbs from '@core/hooks/useToggleThumbs';

import { FeedEmptyState } from '@ui/Dash/Feed';
import Flex from '@ui/Flex';
import Spinner from '@ui/Spinner';

import styles from './style.module.scss';

const PageQualityFallback = () => {
  const bem = useClassy(styles, 'MetricsFallback');
  const { toggle, isLoading } = useToggleThumbs();

  return (
    <Flex align="center" className={bem('&')} justify="center" style={{ height: '100%' }}>
      {isLoading ? (
        <Spinner size="lg" />
      ) : (
        <FeedEmptyState
          action={toggle}
          actionLabel="Enable Votes"
          description="Enable page votes to get to see what your users think of your docs."
          heading="Disabled"
        />
      )}
    </Flex>
  );
};

export default PageQualityFallback;
