import type { ComponentProps } from 'react';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';

import styles from './style.module.scss';

export default function PanelContent({ children, ...rest }: ComponentProps<typeof Flex>) {
  const bem = useClassy(styles, 'PanelContent');

  return (
    <Flex align="stretch" className={bem('-container')} gap="sm" layout="col" {...rest}>
      {children}
    </Flex>
  );
}
