import type { SidebarNavGroupProps } from '..';

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface SidebarNavContextValue {
  /**
   * Indicates which nav group is currently active and navigated to. Updatable
   * by calling `setActiveGroup`.
   */
  activeGroup?: SidebarNavGroupProps['name'];

  /**
   * Updates `activeGroup` and navigates the sidebar to this specific group.
   * Setting `undefined` navigates back to the top-level nav group.
   */
  setActiveGroup: React.Dispatch<React.SetStateAction<SidebarNavGroupProps['name'] | undefined>>;
}

const SidebarNavContext = createContext<SidebarNavContextValue | null>(null);
SidebarNavContext.displayName = 'SidebarNavContext';

/**
 * Dynamic context that contains globally revelant state data that sidebar nav
 * subcomponents need access to. Contains state setters that triggers re-renders
 * of all components connected to this context.
 */
export function useSidebarNavContext() {
  const value = useContext(SidebarNavContext);
  if (!value) throw new Error('SidebarNavContext is not provided');
  return value;
}

type SidebarNavGroupPositionContextValue = Map<string, DOMRect>;

const SidebarNavGroupPositionContext = createContext<SidebarNavGroupPositionContextValue | null>(null);
SidebarNavGroupPositionContext.displayName = 'SidebarNavGroupPositionContext';

/**
 * Map of unique sidebar nav group names to their respective DOM client bounding
 * rect position objects. This is a static context whose Map is mutated in place
 * as each sidebar nav group is rendered. This is intentional so as to perform
 * transient updates without triggering needless re-renders.
 */
export function useSidebarNavGroupPositionContext() {
  const value = useContext(SidebarNavGroupPositionContext);
  if (!value) throw new Error('SidebarNavGroupPositionContext is not provided');
  return value;
}

export interface SidebarNavContextProviderProps {
  /**
   * Controls which nav group is currently active and navigated to. Used to
   * initialize the nav to this group and navigate groups dynamically if needed
   * making the `SidebarNav` both an uncontrolled and controlled component.
   */
  activeGroup?: SidebarNavGroupProps['name'];
  children: React.ReactNode;
}

/**
 * Provider includes all necessary contexts required by `SidebarNav`.
 */
export function SidebarNavContextProvider({ activeGroup: activeGroupProp, children }: SidebarNavContextProviderProps) {
  const [activeGroup, setActiveGroup] = useState<string>();

  // We intentionally delay initializing our active group inside an effect after
  // the initial mount because we require DOM positions to be available *before*
  // navigating to one.
  useEffect(() => {
    setActiveGroup(activeGroupProp);
  }, [activeGroupProp]);

  return (
    <SidebarNavGroupPositionContext.Provider value={useMemo(() => new Map(), [])}>
      <SidebarNavContext.Provider
        value={useMemo(
          () => ({
            activeGroup,
            setActiveGroup,
          }),
          [activeGroup],
        )}
      >
        {children}
      </SidebarNavContext.Provider>
    </SidebarNavGroupPositionContext.Provider>
  );
}
