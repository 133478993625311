import { useEffect } from 'react';

import useClassy from '@core/hooks/useClassy';

import miniStyles from './style.module.scss';

/**
 * Temporary hack to collapse and show the Dash Angular sidebar on "hover". This hook
 * should only be called once by our root React route component.
 * NOTE: Remove this hook entirely once we replace the Angular sidebar entirely.
 */
export default function useDashMiniSidebar() {
  const bem = useClassy(miniStyles, 'DashMiniSidebar');
  const modifier = bem('_mini-sidebar');

  useEffect(() => {
    document.body.classList.add(modifier);
    return () => document.body.classList.remove(modifier);
  }, [modifier]);
}
