import { useState, useEffect } from 'react';

import type { MetricsPageConfig, MetricsPageRouteParams } from '@core/store/Metrics/types';
import { toKeyString } from '@core/utils/metrics';

async function getRouteState(routeKey: string) {
  try {
    const module = await import(`@routes/Dash/Project/Metrics/routes/${routeKey}`);
    return module.default;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Failed to load module for ${routeKey}:`, error);
    return null;
  }
}

/**
 * A hook that dynamically imports the route config object for a provided slug (i.e. 'api-calls')
 *
 * This is (unfortunately) necessary to bypass some limitations with how Playground handles imports
 * of packages/react/src/routes/Dash/Project/Metrics/routes/**.ts files that include React components.
 */
function useAsyncRouteState(slug: MetricsPageRouteParams['slug']) {
  const [routeState, setRouteState] = useState<MetricsPageConfig | null>(null);

  useEffect(() => {
    if (!slug) {
      setRouteState(null);
      return;
    }

    getRouteState(toKeyString(slug))
      .then(result => {
        setRouteState(result || null);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(`Error getting route state for ${slug}:`, error);
        setRouteState(null);
      });
  }, [slug]);

  return routeState;
}

export default useAsyncRouteState;
