import type { Ref } from 'react';

import React, { forwardRef } from 'react';

import classy from '@core/utils/classy';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Spinner from '@ui/Spinner';
import Title from '@ui/Title';

import classes from './style.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  footerLinkHref?: string;
  footerLinkLabel?: string;
  footerLinkTarget?: 'blank' | 'self';
  isLoading?: boolean;
  menu?: React.ReactNode;
  title: string;
}

const Feed = (
  {
    className,
    children,
    footerLinkLabel,
    footerLinkHref,
    footerLinkTarget = 'self',
    isLoading = false,
    menu,
    title,
  }: Props,
  forwardedRef: Ref<HTMLElement>,
) => (
  <section ref={forwardedRef} className={classy(className, classes.Feed, isLoading && classes.Feed_loading)}>
    <div className={classes['Feed-main']}>
      <Flex align="center">
        <Title className={classes['Feed-title']} level={2}>
          {title}
        </Title>
        {!!menu && (
          <Dropdown className={classes['Feed-dropdown']}>
            <Button ghost kind="minimum" size="sm">
              <i aria-label={`${title} Menu`} className="icon-more-vertical" />
            </Button>
            {menu}
          </Dropdown>
        )}
      </Flex>
      {isLoading ? (
        <div className={classes['Feed-content_loading']}>
          <Spinner size="lg" />
        </div>
      ) : (
        <div className={classes['Feed-content']}>{children}</div>
      )}
    </div>
    {!!footerLinkLabel && !!footerLinkHref && (
      <div className={classes['Feed-footer']}>
        <a
          className={classes['Feed-link']}
          href={footerLinkHref}
          rel="noreferrer"
          {...(footerLinkTarget === 'blank' ? { target: '_blank' } : {})}
        >
          {footerLinkLabel}
          {footerLinkTarget === 'blank' && (
            <span className={`icon-arrow-up-right ${classes['Feed-Comment-external']}`} />
          )}
        </a>
      </div>
    )}
  </section>
);

export { default as FeedComment } from './Comment';
export { default as FeedEmptyState } from './EmptyState';
export { default as FeedUserEvent } from './UserEvent';

export default forwardRef(Feed);
