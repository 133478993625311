import { useContext } from 'react';

import type { ConfigContextValue, ProjectContextValue } from '@core/context';
import { ConfigContext, ProjectContext, VersionContext } from '@core/context';
import { useProjectStore } from '@core/store';

import useVariableDefaults from '../useVariableDefaults';

export default function useManageWebhookVariables() {
  const { project: projectFromContext } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext);
  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const subdomainFromStore = useProjectStore(store => store.data.subdomain);
  const subdomain = isHub ? subdomainFromStore : projectFromContext.subdomain;

  const variableDefaults = useVariableDefaults();

  return { version, variableDefaults, subdomain };
}
