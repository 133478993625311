import type { PaginationQueryParams } from '../../core/utils/pagination';

import { customBlockNameRegex } from '@readme/iso';
import { z } from 'zod';

import {
  CUSTOM_BLOCK_GIT_URI_REGEX,
  CUSTOM_BLOCK_URI_REGEX,
  MONGO_OBJECTID_REGEX,
  PROJECT_URI_REGEX,
  VERSION_REGEX,
} from '../../core/constants';
import { zodCollectionWrapper, zodSingleWrapper } from '../../core/mapper';
import { StringToArrayCoercion } from '../util';

export enum ValidSortParams {
  CREATED_AT = 'created_at',
  NAME = 'name',
  UPDATED_AT = 'updated_at',
}

export type PluralizedBlockType = 'components' | 'content';

const CustomBlockSortParams = StringToArrayCoercion(ValidSortParams);

export const Representation = z.object({
  css: z.string().nullable().describe('The styles for the custom block.'),
  name: z.string().regex(customBlockNameRegex).describe('The name of the custom block.'),
  source: z.string().max(250000).describe('The source of the custom block.'),
  type: z.enum(['component', 'content']).describe('The custom block type.'),
});

const Immutable = z.object({
  created_at: z.string().datetime().describe('An ISO 8601 formatted date for when the custom block was created.'),
  project: z.string().regex(MONGO_OBJECTID_REGEX),
  links: z.object({
    project: z.string().regex(PROJECT_URI_REGEX).describe('A URI to the project that this custom block belongs to.'),
  }),

  source_compiled: z
    .string()
    .nullable()
    .describe('The compiled source of the custom block. This value cannot be set via the API.'),

  tag: z.string().describe('The tag of the custom block, used in your page content to render this block.'),

  updated_at: z.string().datetime().describe('An ISO 8601 formatted date for when the custom block was updated.'),

  usage: z.object({
    page_count: z.number().describe('The number of pages that use this custom block.'),
    project_count: z.number().describe('The number of projects that use this custom block.'),
  }),

  uri: z.string().regex(CUSTOM_BLOCK_URI_REGEX),

  version: z.object({
    uri: z.string().describe('The URI of the version that this custom block belongs to.'),
    name: z.string().describe('A friendly name automatically generated from your internal version number.'),
  }),
});

const ImmutableGit = z.object({
  project: z.string().regex(MONGO_OBJECTID_REGEX),
  links: z.object({
    project: z.string().regex(PROJECT_URI_REGEX).describe('A URI to the project that this custom block belongs to.'),
  }),
  tag: z.string().describe('The tag of the custom block, used in your page content to render this block.'),
  updated_at: z.string().datetime().describe('An ISO 8601 formatted date for when the custom block was updated.'),
  usage: z.object({
    page_count: z.number().describe('The number of pages that use this custom block.'),
    project_count: z.number().describe('The number of projects that use this custom block.'),
  }),
  uri: z.string().regex(CUSTOM_BLOCK_GIT_URI_REGEX),

  version: z.object({
    uri: z.string().describe('The URI of the version that this custom block belongs to.'),
    name: z.string().describe('A friendly name automatically generated from your internal version number.'),
  }),
});

export const CustomBlockIndexParameters = z.object({
  type: z
    .enum(['component', 'content', 'all'])
    .default('all')
    .optional()
    .describe(
      "Custom block types to retrieve. The default value is 'all' which does not filter returned blocks my type.",
    ),
  inherit: z
    .enum(['parent', 'none'])
    .default('parent')
    .optional()
    .describe("If 'parent', include the parent projects blocks. If 'none', only include the current project's blocks."),
  name: z.string().optional().describe('Filter for finding specific content block name.'),
  sort: CustomBlockSortParams.default(['created_at']).describe('The sequence in which to order sort fields.'),
  sort_asc: CustomBlockSortParams.describe('Sort fields to return in ascending order.'),
  sort_desc: CustomBlockSortParams.describe('Sort fields to return in descending order.'),
});

const FullGitRepresentation = Representation.merge(ImmutableGit);
export type CustomBlockGitRepresentationType = z.infer<typeof FullGitRepresentation>;

const FullRepresentation = Representation.merge(Immutable);
export type CustomBlockRepresentationType = z.infer<typeof FullRepresentation>;

export const ReadRepresentation = zodSingleWrapper(FullRepresentation);
export const ReadGitRepresentation = zodSingleWrapper(FullGitRepresentation);

// The collection union sporadically fails. So, we are using a discriminated union to keep zod from getting confused
export const ReadCollectionRepresentation = zodCollectionWrapper(FullRepresentation).merge(
  z.object({ storage: z.literal('legacy') }),
);
export const ReadGitCollectionRepresentation = zodCollectionWrapper(FullGitRepresentation).merge(
  z.object({ storage: z.literal('git') }),
);
export type ReadType = z.infer<typeof ReadRepresentation>;
export type ReadCollectionType = z.infer<typeof ReadCollectionRepresentation>;
export type ReadCustomBlockGitType = z.infer<typeof ReadGitRepresentation>;
export type ReadCustomBlockGitCollectionType = z.infer<typeof ReadGitCollectionRepresentation>;
export type TypeQueryParam = 'all' | 'component' | 'content';

// merging the default query params and the resource specific params
export type CustomBlockQueryParams = Pick<PaginationQueryParams, 'Querystring'> & {
  Querystring: z.infer<typeof CustomBlockIndexParameters>;
};

export const CreateRepresentation = Representation.partial().required({ name: true, source: true, type: true });
export type CreateCustomBlockRepresentationType = z.infer<typeof CreateRepresentation>;
export const EditRepresentation = Representation.deepPartial();
export type EditCustomBlockRepresentationType = z.infer<typeof EditRepresentation>;

export const parameters = {
  blockTag: z.object({
    tag: z.string().describe('The tag of the custom block, used in your page content to render this block.'),
  }),

  blockType: z.object({
    type: z.enum(['components', 'content']),
  }),
};

export const CustomBlockPathParams = z
  .object({
    version: z.string().regex(VERSION_REGEX).describe('Project version number or stable.'),
    identifier: z.union([
      z.string().regex(MONGO_OBJECTID_REGEX).describe('A unique identifier for a custom block resource.'),
      z.string().describe('The tag of the custom block, used in your page content to render this block.'),
    ]),
  })
  .required();

export const CustomBlockPathParamsWithoutIdentifier = z
  .object({
    version: z.string().regex(VERSION_REGEX).describe('Project version number or stable.'),
  })
  .required();
