import React, { useMemo } from 'react';

import prettyNumber from '@core/utils/prettyNumber';

import Delta from '@ui/Delta';

interface Props {
  changed?: number | null;
  isColorCoded?: boolean;
  isInverted?: boolean;
  isNetSum?: boolean;
  pageQualityTotal?: number;
  range?: string;
  total?: number | null;
  units?: string;
}

const GraphTotals = ({
  changed,
  isColorCoded,
  isInverted,
  isNetSum,
  pageQualityTotal,
  total,
  range,
  units = 'Total',
}: Props) => {
  const totalSummary = useMemo(() => {
    if (!isNetSum) return `${prettyNumber(total || 0)} ${units}`;

    return (
      <>
        {!!pageQualityTotal && (
          <>
            {prettyNumber(pageQualityTotal)} Total {units},{' '}
          </>
        )}
        <>
          Net <Delta delta={total} isColorCoded={false} isInverted={isInverted} isPercentage={false} />
        </>
      </>
    );
  }, [isNetSum, isInverted, total, units, pageQualityTotal]);

  const showChanged = changed !== undefined && changed !== null;
  const showTotal = total !== undefined && total !== null;

  return (
    <>
      {!!showChanged && <Delta delta={changed} isColorCoded={isColorCoded} isInverted={isInverted} text={range} />}

      {!!showTotal && totalSummary}
    </>
  );
};

export default GraphTotals;
