import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useReferenceStore } from '@core/store';

import TestForm from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookTestForm';

import AwsWebhookIntegration from '../AwsWebhookIntegration';
import NgrokInstructions from '../NgrokInstructions';
import WebhookCodeSnippet from '../WebhookCodeSnippet';
import WebhookSaveForm from '../WebhookSaveForm';

import styles from './style.module.scss';

interface Props {
  highlightLineNumber?: number;
  isQuickstart?: boolean;
  onSaveWebhook?: (url: string) => void;
}

export function WebhookManualInstructions({ highlightLineNumber, isQuickstart = false, onSaveWebhook }: Props) {
  const bem = useClassy(styles, 'WebhookInstructions');

  return (
    <>
      <div className={bem('-code-snippet')}>
        <WebhookCodeSnippet hideLanguagePicker highlight={highlightLineNumber} isNewSetup isQuickstart={isQuickstart} />
      </div>

      {/* Test integration */}
      <div className={bem('-section')}>
        <p className={bem('-section-description')}>
          To test locally, you can install a tool like{' '}
          <strong>
            <em>ngrok</em>
          </strong>{' '}
          to forward requests to ReadMe. Start your local server to forward requests using ngrok.
        </p>

        <div className={bem('-ngrok-instructions')}>
          <NgrokInstructions />
        </div>

        <p className={bem('-section-description')}>
          Test your endpoint and a user’s email to verify that we’re receiving your data.
        </p>
        <TestForm inputSize="md" isButtonOutline isQuickstart={isQuickstart} />
      </div>

      {/* Deploy server */}
      <div className={bem('-section')}>
        <p className={bem('-section-description')}>Once you’ve tested, deploy your server to finish setup.</p>

        <WebhookSaveForm onSaveWebhook={onSaveWebhook} />
      </div>
    </>
  );
}

export default function WebhookInstructions(props: Props) {
  const language = useReferenceStore(s => s.language.language as NonNullable<typeof s.language.language | 'aws'>);

  if (language === 'aws') {
    return <AwsWebhookIntegration onSaveWebhook={props.onSaveWebhook} />;
  }

  return <WebhookManualInstructions {...props} />;
}
