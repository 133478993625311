import type { ComponentProps } from 'react';

import PropTypes from 'prop-types';
import React from 'react';

import BaseInstructions from './base';

const installCode = 'npm install readmeio --save';
const getAPICallCode = apiKey => `const readme = require('readmeio');

app.use((req, res, next) => {
  readme.log('${apiKey}', req, res, {
    // User's API Key
    apiKey: 'owlbert-api-key',
    // Username to show in ReadMe's dashboard
    label: 'Owlbert',
    // User's email address
    email: 'owlbert@example.com',
  });
  return next();
});
`;

interface NodeInstructionsProps extends Omit<ComponentProps<typeof BaseInstructions>, 'instructions'> {
  selectedAPIKey: string;
}

function NodeInstructions({ apiKeyDropdown, isQuickstart, log, selectedAPIKey }: NodeInstructionsProps) {
  const apiCallCode = getAPICallCode(selectedAPIKey);
  const installPrompt = (
    <span>
      Install the{' '}
      <a href="https://www.npmjs.com/package/readmeio" rel="noreferrer" target="_blank">
        readmeio
      </a>{' '}
      package from{' '}
      <a href="https://www.npmjs.com" rel="noreferrer" target="_blank">
        npm
      </a>
      .
    </span>
  );

  const apiCallPrompt = (
    <span>Add the server middleware and populate API request attributes, such as a user’s email and API key.</span>
  );

  const instructions = [
    { code: installCode, prompt: installPrompt, language: 'node' },
    { code: apiCallCode, prompt: apiCallPrompt, language: 'node', includesAPIKey: true },
  ];

  return (
    <BaseInstructions
      apiKeyDropdown={apiKeyDropdown}
      instructions={instructions}
      isQuickstart={isQuickstart}
      log={log}
    />
  );
}

NodeInstructions.propTypes = {
  apiKeyDropdown: PropTypes.element.isRequired,
  isQuickstart: PropTypes.bool,
  log: PropTypes.object,
  selectedAPIKey: PropTypes.string,
};

export default NodeInstructions;
