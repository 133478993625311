import React from 'react';

import useClassy from '@core/hooks/useClassy';

import ActionSlot from '@routes/SuperHub/Layout/ActionSlot';

import styles from './index.module.scss';

interface Props {
  children?: React.ReactNode;

  /**
   * Whether or not to apply default styling, or an unstyled <section> element.
   * Default is `true`.
   */
  isStyled?: boolean;

  /**
   * Whether or not to render the action slot at the bottom of the page. Default
   * is `true`. Most project setting forms portals it's submit/cancel action
   * buttons into this slot.
   */
  renderActionSlot?: boolean;
}

/**
 * `Page` and `PageContent` components are used together as a layout container
 * for routes in the Superhub settings panel.
 * @example
 * ```tsx
 * <Page>
 *   <PageContent>
 *     Form content
 *   </PageContent>
 * </Page>
 * ```
 */
function Page({ children, isStyled = true, renderActionSlot = true }: Props) {
  const bem = useClassy(styles, 'Page');
  return (
    <section className={isStyled ? bem('&') : ''}>
      <div className={bem(renderActionSlot && '-fill-height-container')}>{children}</div>
      {!!renderActionSlot && <ActionSlot className={bem('-actions')} />}
    </section>
  );
}

export { default as PageContent } from './PageContent';
export default Page;
