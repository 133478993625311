import type { ComponentProps } from 'react';

import Oas from 'oas';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useClassy from '@core/hooks/useClassy';

import APISectionHeader from '@ui/API/SectionHeader';
import APIServer from '@ui/API/Server';
import Box from '@ui/Box';
import Button from '@ui/Button';
import CodeSnippet from '@ui/CodeSnippet';
import InfoBert from '@ui/Dash/InfoBert';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import InputGroup from '@ui/InputGroup';
import Menu from '@ui/Menu';
import MenuItem from '@ui/Menu/Item';

import classes from './style.module.scss';

interface BoxPreviewProps extends ComponentProps<typeof Box> {
  caption: React.ReactNode;
  captionJustify?: ComponentProps<typeof Flex>['justify'];
  children: React.ReactNode;
  className?: string;
  interactive: boolean;
}

const BoxPreview = ({
  caption = 'PREVIEW',
  captionJustify = 'start',
  children,
  className,
  interactive = true,
  ...props
}: BoxPreviewProps) => {
  const bem = useClassy(classes, 'BoxPreview');
  return (
    <div className={bem('&', className, 'BoxPreview')}>
      <Box className={bem('-figure', !interactive && '-figure_disabled')} kind="flat" Tag="figure" {...props}>
        <Flex align="center" className={bem('-caption')} gap="xs" justify={captionJustify} tag="figcaption">
          {caption}
        </Flex>

        <div className={bem('-imgWrapper')} inert={interactive ? undefined : ''}>
          {children}
        </div>
      </Box>
    </div>
  );
};

export default BoxPreview;

const EnterpriseDescription = ({ header }) => {
  const bem = useClassy(classes, 'BoxPreview');

  return (
    <InfoBert className={bem('-enterprise-description')}>
      <div>
        <span className={bem('-caption-title')}>{header}</span>
        <span>Variables populated from the OAS files in each project</span>
      </div>
    </InfoBert>
  );
};

EnterpriseDescription.propTypes = {
  header: PropTypes.string,
};

export const ServerVariablesPreview = ({ isParentProject }) => {
  // @ts-expect-error
  const oas = new Oas({
    servers: [
      {
        url: 'https://api.example.com:{port}/{path}',
        variables: {
          path: { default: 'v3', description: 'API Version' },
          port: { default: '443', enum: ['3000', '443', '5000'] },
        },
      },
    ],
  });
  const operation = oas.operation('/endpoint', 'post');
  const [servers, setServers] = useState({
    selected: 0,
    variables: oas.defaultVariables(0),
  });
  const varOpts = {
    open: true,
    onBlur: () => false,
    trigger: 'manual',
  };

  const caption = !isParentProject ? (
    <span>Server Variables can populate controls in your API reference</span>
  ) : (
    <EnterpriseDescription header="Server Variables 🌐" />
  );

  return (
    <BoxPreview
      caption={caption}
      className="_serverVariables"
      interactive={false}
      // @ts-expect-error
      style={{ '--BoxPreview-padding-bottom': '200px' }}
    >
      <APIServer
        oas={oas}
        operation={operation}
        readOnly
        servers={servers}
        setServers={setServers}
        updateServers={() => false}
        // @ts-expect-error
        varOpts={varOpts}
      />
    </BoxPreview>
  );
};

ServerVariablesPreview.propTypes = {
  isParentProject: PropTypes.bool,
};

export const SecuritySchemePreview = ({ isParentProject }) => {
  const apiKey = '3c87cf96-8e92-4eb1';
  const code = `curl --request POST
     --url http://petstore.swagger.io/v2/pet
     --header 'Authorization: Bearer ${apiKey}'
     --header 'Content-Type: application/json'
`;

  const caption = !isParentProject ? (
    <span>Security Scheme Variables can populate API keys in your docs</span>
  ) : (
    <EnterpriseDescription header="Security Scheme Variables 🔐" />
  );

  return (
    <BoxPreview caption={caption} className="_securityScheme" interactive={false}>
      <Flex align="stretch" gap="sm" layout="col">
        <Flex align="stretch" gap="0" layout="col">
          <APISectionHeader heading="CREDENTIALS" />
          <InputGroup columnLayout="auto 1fr" separators size="sm">
            <Button kind="minimum" text>
              <Icon name="key" />
            </Button>
            <Input className={classes['BoxPreview_securityScheme-input']} value={apiKey} />
          </InputGroup>
        </Flex>
        <Box className={classes['BoxPreview_securityScheme-box']} kind="card" theme="dark">
          <CodeSnippet code={code} language="curl" options={{ dark: true }} />
          <Flex className={classes['BoxPreview_securityScheme-box-footer']} justify="end">
            <Button size="sm">Try It!</Button>
          </Flex>
        </Box>
      </Flex>
    </BoxPreview>
  );
};

SecuritySchemePreview.propTypes = {
  isParentProject: PropTypes.bool,
};

export const CustomVariablesPreview = () => (
  <BoxPreview caption="Custom Variables can appear in the editor" className="_customVariables" interactive={false}>
    <Flex justify="center">
      <div>
        <span className={classes['BoxPreview_customVariables-cursor']}>{'<<'}</span>
        <Menu>
          {/* @ts-expect-error */}
          <MenuItem selected={true}>
            <Flex>
              <span>name</span>
              <span>Variable</span>
            </Flex>
          </MenuItem>
          <MenuItem>
            <Flex>
              <span>apiKey</span>
              <span>Variable</span>
            </Flex>
          </MenuItem>
          <MenuItem>
            <Flex>
              <span>defaultVersion</span>
              <span>Variable</span>
            </Flex>
          </MenuItem>
          <MenuItem>
            <Flex>
              <span>Parliament</span>
              <span>Glossary Term</span>
            </Flex>
          </MenuItem>
          <MenuItem>
            <Flex>
              <span>Owlbert</span>
              <span>Glossary Term</span>
            </Flex>
          </MenuItem>
        </Menu>
      </div>
    </Flex>
  </BoxPreview>
);
