import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';

import classes from './style.module.scss';

const ContentContainer = ({
  children,
  className = '',
  isCentered = false,
  size = 'md',
  TagName = 'main',
  ...props
}) => (
  <TagName
    className={classy(
      classes.ContentContainer,
      classes[`ContentContainer_${size}`],
      isCentered && classes.ContentContainer_centered,
      className,
    )}
    {...props}
  >
    {children}
  </TagName>
);

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isCentered: PropTypes.bool,
  size: PropTypes.oneOf(['md', 'lg', 'xl']),
  TagName: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
};

export default ContentContainer;
