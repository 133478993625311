import type { ProjectSettingsFormValues } from '../Context';
import type { Path } from 'react-hook-form';

import React from 'react';

import { useProjectStore } from '@core/store';

import Radio from '@ui/Radio';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import { useProjectSettingsFormContext } from '../Context';
import {
  Fieldset,
  FormRow,
  Page,
  PageContent,
  ParamDefaultHideSVG,
  ParamDefaultShowSVG,
  PreviewRow,
  RequestHistoryPreviewSVG,
  ResponseExampleCollapsedSVG,
  ResponseExampleExpandedSVG,
  ResponseSchemaCollapsedSVG,
  ResponseSchemaExpandedSVG,
  useLiveUpdateProjectStore,
} from '../shared';

const fields: Path<ProjectSettingsFormValues>[] = [
  'reference.api_sdk_snippets',
  'reference.defaults',
  'reference.json_editor',
  'reference.request_history',
  'reference.response_examples',
  'reference.response_schemas',
];

/**
 * Form content for the project's `Appearance > API Reference` settings page
 * that lets users configure interactive parts in the API Reference section.
 */
export default function ApiReference() {
  const projectPlan = useProjectStore(s => s.initialData.plan.type);
  const { control } = useProjectSettingsFormContext();

  useLiveUpdateProjectStore({ fields, control });

  return (
    <Page>
      <PageContent isCondensed>
        <Fieldset legend="Reference" size="sm">
          <FormRow columns={1}>
            <RHFGroup control={control} name="reference.request_history">
              {({ field }) => (
                <>
                  <Toggle
                    {...field}
                    checked={field.value === 'enabled'}
                    onChange={e => field.onChange(e.target.checked ? 'enabled' : 'disabled')}
                    size="sm"
                  >
                    <span>
                      <strong>Enable</strong> request history logs
                    </span>
                  </Toggle>
                  <RequestHistoryPreviewSVG style={{ marginTop: 'var(--sm)', height: '145px', width: '220px' }} />
                </>
              )}
            </RHFGroup>

            <RHFGroup
              control={control}
              helpMessage="Determines whether populate parameter inputs are prefilled with default values."
              label="Defaults"
              name="reference.defaults"
            >
              {({ field }) => (
                <PreviewRow>
                  <Radio {...field} checked={field.value === 'always_use'} isCard label="Always Use" value="always_use">
                    <ParamDefaultShowSVG />
                  </Radio>
                  <Radio
                    {...field}
                    checked={field.value === 'use_only_if_required'}
                    isCard
                    label="Only If Required"
                    value="use_only_if_required"
                  >
                    <ParamDefaultHideSVG />
                  </Radio>
                </PreviewRow>
              )}
            </RHFGroup>

            <RHFGroup
              control={control}
              helpMessage="Whether response body schemas should be shown."
              label="Response Schemas"
              name="reference.response_schemas"
            >
              {({ field }) => (
                <PreviewRow>
                  <Radio {...field} checked={field.value === 'collapsed'} isCard label="Collapsed" value="collapsed">
                    <ResponseSchemaCollapsedSVG />
                  </Radio>
                  <Radio {...field} checked={field.value === 'expanded'} isCard label="Expanded" value="expanded">
                    <ResponseSchemaExpandedSVG />
                  </Radio>
                </PreviewRow>
              )}
            </RHFGroup>

            <RHFGroup
              control={control}
              helpMessage="Determines whether the first successful response body should be shown."
              label="Response Examples"
              name="reference.response_examples"
            >
              {({ field }) => (
                <PreviewRow>
                  <Radio {...field} checked={field.value === 'collapsed'} isCard label="Collapsed" value="collapsed">
                    <ResponseExampleCollapsedSVG />
                  </Radio>
                  <Radio
                    {...field}
                    checked={field.value === 'expanded'}
                    isCard
                    label="Expand First 200"
                    value="expanded"
                  >
                    <ResponseExampleExpandedSVG />
                  </Radio>
                </PreviewRow>
              )}
            </RHFGroup>
          </FormRow>
        </Fieldset>

        <Fieldset legend="API Explorer" size="sm">
          <FormRow columns={1}>
            {projectPlan === 'enterprise' && (
              <RHFGroup control={control} name="reference.json_editor">
                {({ field }) => (
                  <Toggle
                    {...field}
                    checked={field.value === 'enabled'}
                    onChange={e => field.onChange(e.target.checked ? 'enabled' : 'disabled')}
                    size="sm"
                  >
                    <span>
                      <strong>Enable</strong> request body JSON editing
                    </span>
                  </Toggle>
                )}
              </RHFGroup>
            )}

            <RHFGroup control={control} name="reference.api_sdk_snippets">
              {({ field }) => (
                <Toggle
                  {...field}
                  checked={field.value === 'enabled'}
                  onChange={e => field.onChange(e.target.checked ? 'enabled' : 'disabled')}
                  size="sm"
                >
                  <span>
                    <strong>Enable</strong> SDK-generated request code snippets
                  </span>
                </Toggle>
              )}
            </RHFGroup>
          </FormRow>
        </Fieldset>
      </PageContent>
    </Page>
  );
}
