import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import { sortLanguages } from '@core/store/Reference/Language/helpers';

import Flex from '@ui/Flex';
import LanguagePicker from '@ui/LanguagePicker';
import type { TitleLevel } from '@ui/Title';
import Title from '@ui/Title';

import styles from './style.module.scss';

interface SetupLanguagePickerProps {
  /** Optional action slot to render below title */
  actionSlot?: React.ReactNode;
  /**
   * The set of available languages to display in the picker.
   *
   * Note: We provide available languages directly to LanguagePicker rather than via the ReferenceStore,
   * so the language subset available here doesn't affect other instances of LanguagePicker that
   * rely on the singleton store (https://github.com/readmeio/readme/pull/13065/files#r1805495062).
   *
   * Passed languages will be sorted alphabetically via helper
   *
   * Because of that, we don't get some built in functionality via the ReferenceStore like sorting
   * the languages to a consistent order. We do that manually here instead.
   */
  availableLanguages: string[];
  /** Optional description to show below title */
  description?: string;
  /** Callback when a language is selected */
  onChangeLanguage: (language: string) => void;
  /** Title to show for step */
  title: React.ReactNode;
  /** Graphic omponent to render above Title */
  titleGraphic?: React.ReactNode;
  /** Level to display `<Title>` as */
  titleLevel?: TitleLevel;
}

export default function SetupLanguagePicker({
  actionSlot,
  availableLanguages,
  description,
  onChangeLanguage,
  title,
  titleGraphic,
  titleLevel = 2,
}: SetupLanguagePickerProps) {
  const bem = useClassy(styles, 'SetupLanguagePicker');

  const sortedLanguages = useMemo(() => sortLanguages(availableLanguages), [availableLanguages]);

  return (
    <Flex align="center" className={bem('&', '-container')} gap="sm" justify="center" layout="col">
      {!!titleGraphic && <div className={bem('-graphic')}>{titleGraphic}</div>}

      <Title as={1} className={bem('-title')} level={titleLevel}>
        {title}
      </Title>

      {!!actionSlot && <div className={bem('-action-slot')}>{actionSlot}</div>}

      {!!description && <p className={bem('-description')}>{description}</p>}

      <div className={bem('-separator')} />

      <Flex align="center" className={bem('-fieldset')} gap="sm" justify="center" layout="col" tag="fieldset">
        <legend className={bem('-legend')}>Select a language to get started</legend>
        <LanguagePicker
          availableLanguages={sortedLanguages}
          className={bem('-languagePicker')}
          defaultLanguages={sortedLanguages}
          kind="tabs-minimal"
          onChangeLanguage={onChangeLanguage}
        />
      </Flex>
    </Flex>
  );
}
