import type { ProjectSettingsFormValues } from '../Context';
import type { Path } from 'react-hook-form';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import DemoGate from '@routes/SuperHub/Layout/PlanAccess/DemoGate';

import Radio from '@ui/Radio';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import { useProjectSettingsFormContext } from '../Context';
import { useFormGateContext } from '../Context/FormGateContext';
import {
  Fieldset,
  FormRow,
  PageContent,
  PreviewRow,
  useLiveUpdateProjectStore,
  ChangelogLayoutCollapsed,
  ChangelogLayoutContinuous,
  Page,
} from '../shared';

import classes from './index.module.scss';

const fields: Path<ProjectSettingsFormValues>[] = [
  'appearance.changelog.layout',
  'appearance.changelog.show_exact_date',
  'appearance.changelog.show_author',
];

export default function Changelog() {
  const uid = useUniqueId('Changelog');
  const bem = useClassy(classes, 'Changelog');
  const { control } = useProjectSettingsFormContext();
  const formGate = useFormGateContext();

  useLiveUpdateProjectStore({ fields, control });

  return (
    <Page>
      <PageContent isCondensed>
        <DemoGate access={formGate === 'demo' ? 'locked' : 'open'} feature="changelog">
          <Fieldset size="sm">
            <FormRow columns={1}>
              <RHFGroup
                control={control}
                helpMessage="The layout of the list of changelog posts. Continuous will show full posts in a single list, while Collapsed will show a list of truncated posts that each link to a single post view."
                label="Layout"
                name="appearance.changelog.layout"
                size="sm"
              >
                {({ field }) => (
                  <PreviewRow>
                    <Radio
                      {...field}
                      checked={field.value === 'continuous'}
                      isCard
                      label="Continuous"
                      value="continuous"
                    >
                      <ChangelogLayoutContinuous />
                    </Radio>
                    <Radio {...field} checked={field.value === 'collapsed'} isCard label="Collapsed" value="collapsed">
                      <ChangelogLayoutCollapsed />
                    </Radio>
                  </PreviewRow>
                )}
              </RHFGroup>

              <RHFGroup control={control} label="Date Display" name="appearance.changelog.show_exact_date" size="sm">
                {({ field }) => (
                  <PreviewRow>
                    <Radio
                      {...field}
                      checked={field.value}
                      isCard
                      label="Exact"
                      onChange={() => field.onChange(true)}
                      value="" // This needs a string, not a boolean, but the actual value is not registered with RHF
                    >
                      <div className={bem('-date')}>May 1st, 2014</div>
                    </Radio>
                    <Radio
                      {...field}
                      checked={!field.value}
                      isCard
                      label="Relative"
                      onChange={() => field.onChange(false)}
                      value="" // This needs a string, not a boolean, but the actual value is not registered with RHF
                    >
                      <div className={bem('-date')}>5 days ago</div>
                    </Radio>
                  </PreviewRow>
                )}
              </RHFGroup>

              <RHFGroup control={control} id={uid('author')} name="appearance.changelog.show_author" size="sm">
                {({ field: { value, onChange } }) => (
                  <Toggle
                    checked={value}
                    label="Show Author"
                    onChange={event => {
                      onChange(event.target.checked);
                    }}
                    size="sm"
                  />
                )}
              </RHFGroup>
            </FormRow>
          </Fieldset>
        </DemoGate>
      </PageContent>
    </Page>
  );
}
