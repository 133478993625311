import React, { useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import { useProjectStore, useReferenceStore } from '@core/store';

import ApiLogsInstructions from '@routes/MyDevelopers/Setup/ApiLogs/Instructions';
import { PythonPackages } from '@routes/MyDevelopers/Setup/ApiLogs/Instructions/constants';
import useMetricsOnboarding from '@routes/MyDevelopers/Setup/ApiLogs/useMetricsOnboarding';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import { LanguageIcon } from '@ui/LanguagePicker';
import Title from '@ui/Title';

import styles from './InstallSDKStep.module.scss';

interface InstallSDKStepProps {
  onBack: () => void;
}

export default function InstallSDKStep({ onBack }: InstallSDKStepProps) {
  const bem = useClassy(styles, 'InstallSDKStep');

  const { apiKeyDropdown, selectedAPIKey, log } = useMetricsOnboarding();
  const selectedLanguage = useReferenceStore(store => store.language.language!);
  const [languagePackage, setLanguagePackage] = useState(PythonPackages.Django);

  const [onboardingCompleted] = useProjectStore(store => [store.data.onboarding_completed]);
  const isFinishDisabled = !onboardingCompleted.logs;

  return (
    <Flex className={bem('-container')} gap={0} layout="col">
      <Button ghost kind="secondary" onClick={onBack} size="xs">
        <Icon name="arrow-left" />
        Back
      </Button>

      <div className={bem('-separator')} />

      <Title as={1} className={bem('-title')} level={4}>
        My Developers SDK
        <LanguageIcon language={selectedLanguage} />
      </Title>

      <div className={bem('-instructionsContainer')}>
        <ApiLogsInstructions
          apiKeyDropdown={apiKeyDropdown}
          languagePackage={languagePackage}
          log={log}
          selectedAPIKey={selectedAPIKey}
          setLanguagePackage={setLanguagePackage}
        />
      </div>

      <div className={bem('-separator')} />

      <Button
        circular
        disabled={isFinishDisabled}
        kind="primary"
        size="sm"
        style={{ backgroundColor: 'var(--green10)' }}
        // to get disabled styling and focus behavior, we need to render a `button` tag and not an `a`
        to={isFinishDisabled ? undefined : '/my-developers'}
      >
        Finish <Icon name="arrow-right" />
      </Button>
    </Flex>
  );
}
