import type { UslugOptions } from 'uslug';

import uslug from 'uslug';

// These slugs are all blocked by our Cloudflare firewall
const DISALLOWED_SLUGS = ['jquery-file-upload', 'logupload', 'wp-admin', 'wp-content'];

/**
 * Generate a unique slug.
 *
 */
export function uniqueSlug(
  base: string,
  existingSlugs: string[],
  options: UslugOptions = {},
  incrementSeparator: string = '-',
) {
  let originalSlug = uslug(base, options);
  let testSlug = originalSlug;
  let slugI = 0;

  // For a page name like "."
  if (!originalSlug) originalSlug = testSlug = 'page';

  const existingSlugSet = new Set(existingSlugs);
  while (existingSlugSet.has(testSlug)) {
    slugI += 1;
    testSlug = `${originalSlug}${incrementSeparator}${slugI}`;
    if (slugI === Number.MAX_SAFE_INTEGER) break;
  }

  // Pushing the new slug onto the existing set so we
  // can re-use the existingSlugs array when creating
  // other pages within the same request
  existingSlugs.push(testSlug);

  return testSlug;
}

/**
 * Check if slug is valid.
 *
 */
export function validSlug(slug: any) {
  if (!slug) return true;
  if (DISALLOWED_SLUGS.includes(slug)) return false;
  return slug === uslug(slug);
}
