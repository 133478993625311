import React, { useContext, useMemo } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './style.module.scss';

interface Props {
  parentSubdomain: string | undefined;
}

export default function WebhookEnterpriseConfig({ parentSubdomain }: Props) {
  const bem = useClassy(styles, 'WebhookEnterpriseConfig');

  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const { isGroupAdminUser } = useUserPermissions();
  const enterpriseConfigUrl = useMemo(() => {
    const dashUrl = `/group/${parentSubdomain}/personalized-docs`;

    return isHub ? `/dash?to=${dashUrl}` : dashUrl;
  }, [isHub, parentSubdomain]);

  return (
    <div className={bem('-section')}>
      <p className={bem('-section-description')}>
        Webhooks must be configured by a Group Admin at the <strong>Enterprise</strong> level.
      </p>
      <Flex gap="xs" justify="start">
        <Button
          disabled={!isGroupAdminUser}
          href={isGroupAdminUser ? enterpriseConfigUrl : undefined}
          outline
          size="xs"
          target="_blank"
        >
          Configure
          <Icon name="arrow-up-right" />
        </Button>

        {!isGroupAdminUser && (
          <Flex gap="xs">
            <Icon className={bem('-card-permission-icon')} name="info" size="md" />
            <span className={bem('-card-permission-text')}>
              Your <strong>Group Admins</strong> can help you setup webhooks
            </span>
          </Flex>
        )}
      </Flex>
    </div>
  );
}
