import type { SharedSvgProps } from '.';

import React from 'react';

import styles from './index.module.scss';

export default function ResponseSchemaExpanded({ style }: SharedSvgProps) {
  return (
    <svg className={styles.Preview} fill="none" style={style} viewBox="0 0 92 62" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path d="M0 0h92v62H0z" fill="#fff" id="reuse-0" />
      </defs>
      <g clipPath="url(#clip0_314_23424)">
        <use xlinkHref="#reuse-0" />
        <g clipPath="url(#clip1_314_23424)">
          <g filter="url(#filter0_i_314_23424)">
            <path d="M5 5h82v23H5z" fill="#F8F8F8" />
            <g filter="url(#filter1_dii_314_23424)">
              <g filter="url(#filter2_dii_314_23424)">
                <rect fill="#12CA93" height="5.535" rx="2.767" width="5.535" x="9" y="10.732" />
                <rect
                  height="5.074"
                  rx="2.537"
                  stroke="#000"
                  strokeOpacity=".15"
                  strokeWidth=".461"
                  width="5.074"
                  x="9.231"
                  y="10.963"
                />
              </g>
            </g>
            <path
              d="M17.45 16v-.594l1.87-1.965c.746-.777.961-1.066.961-1.523v-.012c0-.55-.379-.957-.996-.957-.633 0-1.055.414-1.055 1.031l-.003.012h-.82l-.009-.008c.004-1.03.801-1.757 1.934-1.757 1.05 0 1.824.668 1.824 1.597v.012c0 .64-.297 1.133-1.32 2.148l-1.195 1.192v.078h2.605V16H17.45Zm6.933.137c-1.301 0-2.082-1.137-2.082-2.953v-.008c0-1.817.781-2.95 2.082-2.95 1.297 0 2.082 1.133 2.082 2.95v.008c0 1.816-.785 2.953-2.082 2.953Zm0-.727c.754 0 1.199-.848 1.199-2.226v-.008c0-1.38-.445-2.219-1.2-2.219-.757 0-1.198.84-1.198 2.219v.008c0 1.379.441 2.226 1.199 2.226Zm5.195.727c-1.3 0-2.082-1.137-2.082-2.953v-.008c0-1.817.781-2.95 2.082-2.95 1.297 0 2.082 1.133 2.082 2.95v.008c0 1.816-.785 2.953-2.082 2.953Zm0-.727c.754 0 1.2-.848 1.2-2.226v-.008c0-1.38-.446-2.219-1.2-2.219-.758 0-1.2.84-1.2 2.219v.008c0 1.379.442 2.226 1.2 2.226Z"
              fill="#384248"
            />
            <rect fill="#000" fillOpacity=".1" height="4" rx="2" width="40" x="9" y="20" />
            <path
              clipRule="evenodd"
              d="M74.02 15.36c.12-.12.315-.12.435 0l1.627 1.628 1.628-1.628a.308.308 0 1 1 .435.435L76.3 17.64a.308.308 0 0 1-.435 0l-1.845-1.845a.308.308 0 0 1 0-.435Z"
              fill="#637288"
              fillRule="evenodd"
            />
          </g>
          <g filter="url(#filter3_i_314_23424)">
            <path d="M5 28h82v55.081H5z" fill="#F8F8F8" />
            <path
              d="M10.795 41v-6.341h2.5c1.302 0 2.11.756 2.11 1.933v.01c0 .852-.457 1.546-1.23 1.805L15.599 41H14.46l-1.292-2.439H11.78V41h-.985Zm.985-3.239h1.42c.755 0 1.195-.413 1.195-1.134v-.008c0-.703-.466-1.134-1.226-1.134h-1.39v2.276ZM16.807 41v-6.341h4.026v.848h-3.041v1.854h2.878v.818H17.79v1.973h3.041V41h-4.025Zm7.594.154c-1.433 0-2.351-.725-2.44-1.793l-.003-.057h.966l.005.048c.052.576.663.945 1.511.945.809 0 1.385-.404 1.385-.993v-.005c0-.487-.343-.804-1.182-.984l-.695-.15c-1.287-.272-1.841-.87-1.841-1.806v-.004c.004-1.094.954-1.85 2.298-1.85 1.345 0 2.224.76 2.294 1.744l.005.062h-.954l-.009-.057c-.083-.519-.58-.897-1.349-.892-.743 0-1.292.347-1.292.945v.004c0 .47.334.778 1.156.954l.69.154c1.331.285 1.876.821 1.876 1.762v.004c0 1.213-.945 1.969-2.421 1.969ZM28.119 41v-6.341h2.47c1.243 0 2.104.835 2.104 2.078v.01c0 1.238-.861 2.087-2.105 2.087h-1.485V41h-.984Zm2.223-5.51h-1.239v2.517h1.24c.852 0 1.348-.461 1.348-1.256v-.01c0-.79-.496-1.252-1.349-1.252Zm6.324 5.664c-1.846 0-2.962-1.288-2.962-3.323v-.008c0-2.04 1.143-3.318 2.962-3.318 1.824 0 2.958 1.283 2.958 3.318v.008c0 2.03-1.126 3.323-2.958 3.323Zm0-.87c1.204 0 1.951-.967 1.951-2.453v-.008c0-1.499-.751-2.448-1.951-2.448-1.2 0-1.956.95-1.956 2.448v.008c0 1.486.734 2.453 1.956 2.453ZM40.96 41v-6.341h.887l3.26 4.614h.067v-4.614h.958V41h-.888l-3.256-4.605h-.07V41h-.959Zm8.912.154c-1.433 0-2.352-.725-2.44-1.793l-.004-.057h.967l.004.048c.053.576.664.945 1.512.945.809 0 1.384-.404 1.384-.993v-.005c0-.487-.342-.804-1.182-.984l-.694-.15c-1.288-.272-1.841-.87-1.841-1.806v-.004c.004-1.094.953-1.85 2.298-1.85 1.345 0 2.224.76 2.294 1.744l.004.062h-.953l-.01-.057c-.083-.519-.58-.897-1.348-.892-.743 0-1.292.347-1.292.945v.004c0 .47.334.778 1.156.954l.69.154c1.331.285 1.876.821 1.876 1.762v.004c0 1.213-.945 1.969-2.421 1.969ZM53.589 41v-6.341h4.026v.848h-3.041v1.854h2.878v.818h-2.878v1.973h3.04V41H53.59Z"
              fill="#384248"
            />
            <g clipPath="url(#clip2_314_23424)">
              <mask fill="#fff" id="path-12-inside-1_314_23424">
                <path d="M10 44h72v10.081H10V44Z" />
              </mask>
              <path d="M82 53.581H10v1h72v-1Z" fill="#000" fillOpacity=".1" mask="url(#path-12-inside-1_314_23424)" />
              <rect
                fill="#000"
                fillOpacity=".1"
                height="4"
                rx="2"
                transform="rotate(-.26 13 47.081)"
                width="18"
                x="13"
                y="47.081"
              />
              <rect fill="#000" fillOpacity=".1" height="4" rx="2" width="20" x="13" y="57.081" />
              <rect fill="#000" fillOpacity=".1" height="3" rx="1.5" width="20" x="36" y="57.581" />
            </g>
            <rect
              height="33.581"
              rx="1.75"
              stroke="#000"
              strokeOpacity=".1"
              strokeWidth=".5"
              width="71.5"
              x="10.25"
              y="44.25"
            />
          </g>
        </g>
        <rect
          height="78.542"
          rx="4.231"
          stroke="#000"
          strokeOpacity=".1"
          strokeWidth=".461"
          width="82.461"
          x="4.769"
          y="4.769"
        />
      </g>
      <defs>
        <clipPath id="clip0_314_23424">
          <use xlinkHref="#reuse-0" />
        </clipPath>
        <clipPath id="clip1_314_23424">
          <rect fill="#fff" height="78.081" rx="4" width="82" x="5" y="5" />
        </clipPath>
        <clipPath id="clip2_314_23424">
          <rect fill="#fff" height="34.081" rx="2" width="72" x="10" y="44" />
        </clipPath>
      </defs>
    </svg>
  );
}
