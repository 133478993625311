import React, { useState } from 'react';

import classy from '@core/utils/classy';

import Avatar from '@ui/Avatar';
import Flex from '@ui/Flex';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

interface Props {
  align?: 'baseline' | 'center' | 'end' | 'start' | 'stretch';
  children: React.ReactNode;
  className?: string;
  hasTooltip?: boolean;
  logo?: string;
  user: {
    email?: string;
    name: string;
  };
}

const UserEvent = ({ className, children, logo, user, align = 'center', hasTooltip = true }: Props) => {
  const [isLogoAvatar, setIsLogoAvatar] = useState(Boolean(logo));
  const avatar = (
    <Avatar
      circular={!isLogoAvatar}
      className={classy(className, classes['Feed-UserEvent-avatar'])}
      imgSrc={logo}
      onImgError={() => setIsLogoAvatar(false)}
      size="sm"
      {...(isLogoAvatar && { hexColor: 'transparent' })}
    >
      {user.name}
    </Avatar>
  );

  return (
    <Flex align={align} className={classes['Feed-UserEvent']} gap="sm" justify="start" layout="row">
      {hasTooltip ? (
        <Tooltip
          content={
            <div className={classes['Feed-UserEvent-tooltip']}>
              <strong>{user.name}</strong>
              {user?.email || ''}
            </div>
          }
        >
          {avatar}
        </Tooltip>
      ) : (
        avatar
      )}
      {children}
    </Flex>
  );
};

export default UserEvent;
