import { createColumnHelper } from '@tanstack/react-table';

import { Column } from '@core/enums/metrics';
import { TablePrefsKey } from '@core/enums/tablePrefs';
import type { DocMetricsData } from '@core/types/metrics';
import { columnHeaders } from '@core/utils/metrics';

import { TextCell, CompanyCell, TimeCell } from '@ui/ReactTable/components/Cells';

import { LinkToHubCell } from '../Table/Cells';

const columnHelper = createColumnHelper<DocMetricsData>();

export default {
  title: 'Page Views',
  endpoint: 'docViews',
  exportSource: 'pageViews',
  filters: ['Group', 'Path', 'User'],
  graph: {
    type: 'line',
    query: 'groupBy=period',
    groups: ['company', 'path', 'email', 'type'],
    groupByBase: 'period',
  },
  shortcuts: {
    'Top Pages': { query: 'groupBy=path&groupBy=period', graphType: 'bar' },
    'Views Over Time': { groupBy: 'period', graphType: 'line' },
  },
  table: {
    query: '',
    defaultSort: {
      column: Column.CreatedAt,
      direction: 'desc',
    },
    columns: [
      columnHelper.accessor(Column.CreatedAt, {
        enableHiding: false,
        header: columnHeaders[Column.CreatedAt],
        cell: ({ getValue }) => TimeCell({ value: getValue() }),
      }),
      columnHelper.accessor(Column.Page, {
        enableHiding: false,
        header: columnHeaders[Column.Page],
        cell: cell => {
          const { getValue } = cell;
          const { version } = cell.row.original;
          return LinkToHubCell({ page: getValue(), version });
        },
        minSize: 200,
      }),
      columnHelper.accessor(Column.Language, {
        header: columnHeaders[Column.Language],
        maxSize: 80,
      }),
      columnHelper.accessor(Column.Version, {
        header: columnHeaders[Column.Version],
        maxSize: 80,
      }),
      columnHelper.accessor(Column.Email, {
        header: columnHeaders[Column.Email],
        cell: ({ getValue }) => TextCell({ value: getValue() || '', copyable: true }),
      }),
      columnHelper.accessor(Column.Company, {
        header: columnHeaders[Column.Company],
        cell: ({ getValue }) => CompanyCell({ value: getValue() }),
        minSize: 120,
      }),
    ],
    prefsName: TablePrefsKey.MetricsPageViews,
  },
  fallback: null,
};
