import { createMemoryHistory } from 'history';
import { useHistory } from 'react-router-dom';

import useEnvInfo from '@core/hooks/useEnvInfo';

/** Expose the React Router history obj and
 *  base URL for use in the Angular router.
 *  @see /public/js/dash.config.js#L11
 */
export const useExposeHistory = baseUrl => {
  const { isClient } = useEnvInfo();
  const hist = useHistory();
  if (isClient) {
    window.rxHistory = hist;
    window.rxBaseUrl = baseUrl;
  }
};

/** Instantiate an in-memory history object
 *  to be passed to the dash router.
 */
export const history = createMemoryHistory();

export const dashNavigate = (to = '') => {
  if ('ngSyncState' in window) {
    const params = new URLSearchParams(window.location.search);
    window.history.pushState({}, '', to);
    window.ngSyncState(Object.fromEntries(params));
    if (to.match(/#.*/)) history.push(to);
  }
};

export default history;
