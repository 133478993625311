import type { PlanFeatureKey } from '@readme/iso';

import { getFeaturePlan } from '@readme/iso';
import React from 'react';

import useProjectPlan from '@core/hooks/useProjectPlan';
import { useSuperHubStore } from '@core/store';

import PlanAccessBadge from '@routes/SuperHub/Layout/PlanAccess/Badge';

import { SidebarNavLink } from '../Form/Project/shared';

export interface ConfigureNavItemProps {
  href?: string;
  icon: string;
  isBeta?: boolean;
  label: string;
  planFeature?: PlanFeatureKey;
  to?: string;
}

export default function ConfigureNavItem({ label, href, icon, isBeta, to, planFeature }: ConfigureNavItemProps) {
  const isBidiSync = useSuperHubStore(s => s.isBidiSync);
  const { planFeatures, showTrialIndicator } = useProjectPlan();

  if (to === '/configure/git-connection' && !isBidiSync) {
    return null;
  }

  const showBadge = !!planFeature;

  return (
    <SidebarNavLink
      badge={
        showBadge ? (
          <PlanAccessBadge
            access={showTrialIndicator(planFeature) ? 'trial' : planFeatures[planFeature] ? 'open' : 'locked'}
            align="bottom"
            iconOnly
            justify="center"
            plan={getFeaturePlan(planFeature)}
          />
        ) : undefined
      }
      icon={icon}
      isBeta={isBeta}
      label={label}
      {...(href && { href, target: '_blank' })}
      {...(to && { to })}
    />
  );
}
