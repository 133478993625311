import type { ProjectSettingsFormValues } from './Context';
import type { Path } from 'react-hook-form';

import React, { useEffect } from 'react';

import { useProjectStore } from '@core/store';

import { useProjectSettingsFormContext } from './Context';

/**
 * Connects the Configure RHF to our project store by keeping form default
 * values always in sync with the latest project store data. Any errors in the
 * store are propagated into the form as well.
 */
export default function ConnectFormToProjectStore({ children }: { children?: React.ReactNode }) {
  const initialFormValues = useProjectStore(s => s.initialData);
  const saveError = useProjectStore(s => s.saveError);
  const { reset, setError } = useProjectSettingsFormContext();

  useEffect(() => {
    // Manually set errors from the API response to the form state.
    saveError?.errors?.forEach(({ key, message }) => {
      const name = key as unknown as Path<ProjectSettingsFormValues>;
      setError(name, { type: 'manual', message });
    });
  }, [saveError, setError]);

  useEffect(() => {
    // These form values come from our project store which is continually kept
    // in sync via API. Calling "reset" with some special options allows us keep
    // our form in sync with the latest project data, while preserving updated
    // fields that have been modified by the user.
    reset(initialFormValues, {
      keepDirtyValues: true,
      keepErrors: true,
    });
  }, [initialFormValues, reset]);

  return <>{children}</>;
}
