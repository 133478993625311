import { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@readme/iso';
import React, { useCallback, useEffect, useState } from 'react';

import useAmplitude from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import { useReferenceStore } from '@core/store';

import useIsWebhookSetupComplete from '@routes/MyDevelopers/Setup/PersonalizedDocs/useIsWebhookSetupComplete';
import useVariableDefaults from '@routes/MyDevelopers/Setup/PersonalizedDocs/useVariableDefaults';
import WebhookInstructions from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookInstructions';
import WebhooksSetupContext from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhooksSetupContext';
import WebhookVariablesSections from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookVariablesSections';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import { LanguageIcon } from '@ui/LanguagePicker';
import Notification, { notify } from '@ui/Notification';
import Title from '@ui/Title';

import styles from './WebhookStep.module.scss';

interface SetupWebhookStepProps {
  onBack: () => void;
}

export default function SetupWebhookStep({ onBack }: SetupWebhookStepProps) {
  const bem = useClassy(styles, 'SetupWebhookStep');

  const selectedLanguage = useReferenceStore(store => store.language.language!);

  const variables = useVariableDefaults();

  const { track } = useAmplitude();

  useEffect(() => {
    track(AMPLITUDE_EVENT.PD_WEBHOOK_VIEW);
  }, [track]);

  const [highlightLineNumber, setHighlightLineNumber] = useState<number>();
  const isWebhookSetupComplete = useIsWebhookSetupComplete();

  const onSaveWebhook = useCallback(() => {
    notify(<Notification kind="success">Webhook URL saved successfully!</Notification>);

    // Send success event to Amplitude
    track(AMPLITUDE_EVENT.PD_WEBHOOK_SUCCESS, {
      type: AMPLITUDE_EVENT_PROPERTY.SETUP,
    });
  }, [track]);

  return (
    <WebhooksSetupContext>
      <Flex className={bem('-container')} gap={15} justify="center" layout="col">
        <Button ghost kind="secondary" onClick={onBack} size="xs">
          <Icon name="arrow-left" />
          Back
        </Button>

        <div className={bem('-separator')} />

        <div className={bem('-instructions')}>
          <div className={bem('-header')}>
            <Title as={1} className={bem('-title')} level={4}>
              Personalized Docs Webhooks
              <LanguageIcon language={selectedLanguage} />
            </Title>

            {selectedLanguage === 'node' && (
              <p className={bem('-description')}>
                Install the{' '}
                <a href="https://www.npmjs.com/package/readmeio" rel="noreferrer" target="_blank">
                  readmeio
                </a>{' '}
                package from{' '}
                <a href="https://www.npmjs.com" rel="noreferrer" target="_blank">
                  npm
                </a>
                . Add the middleware to your server to create the new endpoint.
              </p>
            )}
          </div>

          <WebhookVariablesSections onVariableHover={setHighlightLineNumber} variables={variables} />

          <WebhookInstructions highlightLineNumber={highlightLineNumber} onSaveWebhook={onSaveWebhook} />
        </div>

        <div className={bem('-separator')} />

        <Button
          circular
          disabled={!isWebhookSetupComplete}
          kind="primary"
          size="sm"
          style={{ backgroundColor: 'var(--green10)', alignSelf: 'flex-end' }}
          {...(isWebhookSetupComplete
            ? {
                to: '/content/personalized-docs',
              }
            : {
                // to get disabled styling and focus behavior, we need to render a `button` tag and not an `a` by omitting `to`
                disabled: true,
              })}
        >
          Finish <Icon name="arrow-right" />
        </Button>
      </Flex>
    </WebhooksSetupContext>
  );
}
