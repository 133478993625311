import React, { useState } from 'react';

import useClassy from '@core/hooks/useClassy';

import Collapsible from '@ui/Collapsible';
import Icon from '@ui/Icon';

import styles from './index.module.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  /**
   * Allows the `<legend>` element to toggle the fieldset content
   * to be expanded or collapsed
   */
  isCollapsible?: boolean;
  /**
   * Text or jsx that is rendered in a `<legend>` element
   */
  legend?: React.ReactNode;

  /**
   * Size of the fieldset. Default is `md`.
   */
  size?: 'lg' | 'md' | 'sm';

  /**
   * Start collapsible fieldset in an opened state (they are initially closed by default)
   */
  startOpened?: boolean;
}

function Fieldset({ className, legend, children, isCollapsible = false, startOpened = false, size = 'md' }: Props) {
  const [opened, setOpened] = useState(startOpened);
  const bem = useClassy(styles, 'Fieldset');

  return (
    <fieldset
      className={bem('&', !legend && '_no-legend', size && `_${size}`, isCollapsible && '_collapsible', className)}
    >
      {!!legend && (
        <legend className={bem('-legend')}>
          {isCollapsible ? (
            <>
              <button className={bem('-legend-button')} onClick={() => setOpened(!opened)}>
                {legend}
                <Icon className={bem('-legend-icon', opened && '-legend-icon_open')} name="chevron-down" />
              </button>
            </>
          ) : (
            legend
          )}
        </legend>
      )}
      {isCollapsible ? <Collapsible opened={opened}>{children}</Collapsible> : children}
    </fieldset>
  );
}

export default Fieldset;
