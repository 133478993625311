import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import classy from '@core/utils/classy';

import classes from './style.module.scss';

const Banner = ({
  children,
  className,
  dismissible = false,
  icon = 'icon-alert-circle',
  isFixed = true,
  kind = 'secondary',
  onDismiss = () => {},
  size = 'sm',
  ...attr
}) => {
  const [dismissed, setDismissed] = useState(false);
  const dismiss = useCallback(() => {
    if (!dismissible) return;
    if (typeof onDismiss === 'function') onDismiss();
    setDismissed(true);
  }, [dismissible, onDismiss]);
  const onKeyPress = useCallback(
    event => {
      if (!dismissible) return;
      if (event.key === 'Enter') dismiss();
    },
    [dismiss, dismissible],
  );

  return dismissed ? null : (
    <div
      {...attr}
      className={classy(
        className,
        classes.Banner,
        classes[`Banner_${kind}`],
        classes[`Banner_${size}`],
        isFixed && classes.Banner_fixed,
        dismissible && classes.Banner_dismissible,
      )}
    >
      {!!icon && <i className={icon} />}
      {children}
      {!!dismissible && (
        <i
          className={classy(classes['Banner-Dismiss'], 'icon-x-circle')}
          onClick={dismiss}
          onKeyPress={onKeyPress}
          role="button"
          tabIndex="0"
        />
      )}
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node.isRequired,
  dismissible: PropTypes.bool,
  icon: PropTypes.string,
  isFixed: PropTypes.bool,
  kind: PropTypes.oneOf(['primary', 'secondary', 'alert', 'warn']),
  onDismiss: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
};

export default Banner;
