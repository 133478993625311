/* eslint-disable camelcase */
import { useContext, useEffect, useMemo } from 'react';

import { ProjectContext, UserContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';

const useIntercom = (opts = {}) => {
  const { isEnabled = true, useProjectIntercomId = false, showNewMessage = '', trackEvent, ...rest } = opts;
  const { project } = useContext(ProjectContext);
  const { intercom: projectIntercomId, intercom_secure_emailonly } = project;
  const { isDev } = useEnvInfo();
  const {
    // Dash admin properties
    id: adminId,
    name: adminName,
    email: adminEmail,
    intercom_hash: adminHash,
    // Hub user properties
    _id,
    user = {},
    intercom: { intercom_hash } = {},
  } = useContext(UserContext);

  const settings = useMemo(
    () => ({
      // Use our test project when in dev, otherwise use the configured intercom id
      // -- either the end user's project (on the hub) or readme's internal intercom (on the dash)
      app_id: isDev ? 'wxdunptb' : useProjectIntercomId ? projectIntercomId : 'm6855w1q',
      email: adminEmail || user?.email,
      name: adminName || user?.adminName,
      user_hash: adminHash || intercom_hash,
      ...(intercom_secure_emailonly ? {} : { user_id: adminId || _id }),
      ...rest,
    }),
    [
      isDev,
      projectIntercomId,
      adminEmail,
      user?.email,
      user?.adminName,
      adminName,
      adminHash,
      intercom_hash,
      intercom_secure_emailonly,
      adminId,
      _id,
      rest,
      useProjectIntercomId,
    ],
  );

  useEffect(() => {
    if (!isEnabled || !settings.app_id || typeof window.Intercom === 'function') return;

    window.intercomSettings = settings;
    const script = document.createElement('script');
    script.onload = () => {
      if (typeof window.Intercom === 'function') {
        window.Intercom('reattach_activator');
        window.Intercom('update', settings);
      }
    };
    script.type = 'module';
    script.defer = true;
    script.src = `https://widget.intercom.io/widget/${settings.app_id}`;
    document.head.prepend(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.app_id]);

  return () => {
    if (typeof window.Intercom === 'function') {
      window.Intercom('boot', { ...settings, hide_default_launcher: false });

      // Track event can be used to send custom events to intercom
      // https://developers.intercom.com/installing-intercom/docs/intercom-javascript#intercomtrackevent
      if (trackEvent) {
        window.Intercom('trackEvent', trackEvent.name, trackEvent.metadata);
      }

      // Either open Intercom messenger w/ pre-populated message or just show the Intercom widget
      if (showNewMessage) {
        window.Intercom('showNewMessage', showNewMessage);
      } else {
        window.Intercom('show');
      }
    }
  };
};

export default useIntercom;
