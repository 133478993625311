import type { FieldValues, Control, Path } from 'react-hook-form';

import set from 'lodash/set';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { useProjectStore } from '@core/store';

export interface UseLiveUpdateProjectStoreOptions<FormValues extends FieldValues> {
  /**
   * Control object from useForm
   */
  control: Control<FormValues>;
  /**
   * Whitelist of fields that will mutate a context value in realtime as the field's value changes
   */
  fields: Path<FormValues>[];
}

/**
 * When provided field values change, mutate their associated context value so a user can "preview" the changes in realtime.
 */
export function useLiveUpdateProjectStore<FormValues extends FieldValues>({
  fields,
  control,
}: UseLiveUpdateProjectStoreOptions<FormValues>) {
  const [reset, update] = useProjectStore(s => [s.reset, s.update]);

  // Update context if values of form fields change
  const watchFieldValues = useWatch({
    control,
    name: fields,
  });

  useEffect(() => {
    // `useWatch` returns an array of values [value1, value2] that maps to the array of fields names you provided it ex: ['propA.propB', 'propA.propC'].
    // so we need to build a single mutation object with all the values out of both arrays.
    const mutation = {};
    fields.forEach((property, idx) => {
      const value = watchFieldValues[idx];
      if (value === undefined) return;
      set(mutation, property, value);
    });
    update(mutation);
  }, [fields, update, watchFieldValues]);

  // Reset context to default values when form unmounts with unsaved changes
  useEffect(
    () => () => {
      reset();
    },
    [reset],
  );

  return watchFieldValues;
}
