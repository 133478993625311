import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Invokes the provided handler function whenever the React Router detects a
 * route change in its history. This is typically useful when you need perform
 * some cleanup effects (or some other action) whenever routing to a different
 * part of the app.
 *
 * Handler won't be invoked repetitively with the same route path, but will only
 * be called at most once for each route change.
 *
 * @example
 * useOnRouteChange(pathname => {
 *   if (formIsDirty) {
 *     resetForm();
 *   }
 * })
 */
export default function useOnRouteChange(onChange: (pathname: string) => void) {
  const location = useLocation();
  const currentPath = useRef(location.pathname);

  useEffect(() => {
    if (currentPath.current !== location.pathname) {
      currentPath.current = location.pathname;
      onChange(location.pathname);
    }
  }, [location.pathname, onChange]);
}
