import { WebhookAction } from '@readme/iso';
import { useEffect, useState, useCallback, useContext } from 'react';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext } from '@core/context';
import useDebounced from '@core/hooks/useDebounced';
import useSaveProject from '@core/hooks/useSaveProject';
import { useProjectStore } from '@core/store';

interface Webhook {
  action: WebhookAction;
  timeout?: number;
  url: string;
}
interface Packet {
  webhooks: Webhook[] | [];
}

function useSaveWebhook(initialWebhookURL: string, onSave?: (url: string) => void) {
  const [webhookUrl, setWebhookUrl] = useState(initialWebhookURL);
  const [isWebhookUrlDirty, setIsWebhookUrlDirty] = useState(false);
  const [isWebhookUrlValid, setIsWebhookUrlValid] = useState(false);

  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const { saveProject: saveProjectV1 } = useSaveProject();
  const [saveProjectV2] = useProjectStore(store => [store.save]);

  const [savedWebhook, setSavedWebhook] = useState<{ url: string }>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialWebhookURL) setIsWebhookUrlValid(true);
  }, [initialWebhookURL]);

  useEffect(() => {
    if (savedWebhook) {
      setWebhookUrl(savedWebhook.url);
      setIsWebhookUrlDirty(false);
    }
  }, [savedWebhook, setWebhookUrl]);

  const validateAndUpdate = useCallback(url => {
    setIsWebhookUrlDirty(true);
    try {
      const { href } = url === '' ? { href: '' } : new URL(url);
      setWebhookUrl(href);
      setIsWebhookUrlValid(true);
    } catch {
      setIsWebhookUrlValid(false);
    }
  }, []);

  const handleUpdate = useDebounced<(url: string) => void>(validateAndUpdate, 300);

  const handleSave = useCallback(async () => {
    setIsLoading(true);

    try {
      const webhook = { action: WebhookAction.LOGIN, url: webhookUrl };
      // If URL is an empty string (i.e a user has removed a previously set webhook URL)
      // Save an empty array to unset any webhooks.
      const packet: Packet = { webhooks: webhookUrl ? [webhook] : [] };

      if (isHub) {
        await saveProjectV2(packet);
      } else {
        await saveProjectV1(packet);
      }

      onSave?.(webhook.url);
      setSavedWebhook(webhook);
    } finally {
      setIsLoading(false);
    }
  }, [webhookUrl, isHub, onSave, saveProjectV2, saveProjectV1]);

  return { webhookUrl, isWebhookUrlDirty, isWebhookUrlValid, isLoading, handleUpdate, handleSave };
}

export default useSaveWebhook;
