import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';

import ContentContainer from '@ui/Dash/ContentContainer';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Title from '@ui/Title';

import classes from './style.module.scss';

const DashHeader = ({
  children,
  className,
  description,
  icon,
  iconSize = 'lg',
  isTransparent = false,
  newIcon,
  size = 'md',
  title,
  titleSize,
}) => {
  return (
    <header className={classy(classes.DashHeader, isTransparent && classes.DashHeader_transparent, className)}>
      <ContentContainer size={size} TagName="div">
        <Flex align="center" className={classes['DashHeader-Inner']}>
          <Flex align="start" gap="0" layout="col">
            <Title className={classes['DashHeader-Title']} level={titleSize}>
              {newIcon ? (
                <Icon className={classy(classes['DashHeader-Title-Icon'])} name={newIcon} size={iconSize} />
              ) : (
                !!icon && <i className={classy(icon, classes['DashHeader-Title-Icon'])} />
              )}
              {title}
            </Title>
            {!!description && <p className={classes['DashHeader-Description']}>{description}</p>}
          </Flex>

          <div className={classes['DashHeader-Children']}>{children}</div>
        </Flex>
      </ContentContainer>
    </header>
  );
};

DashHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.oneOf(['sm', 'md', 'lg']),
  isTransparent: PropTypes.bool,
  newIcon: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg', 'xl']),
  title: PropTypes.node,
  titleSize: PropTypes.number,
};

export default React.memo(DashHeader);
